import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Alert as MuiAlert,
  // Checkbox,
  // FormControlLabel,
  Button,
  TextField as MuiTextField,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { spacing } from "@mui/system";
import { Eye, EyeOff } from "react-feather";
import useAuth from "../../hooks/useAuth";
import CustomLabel from "../../theme/CustomLabel";

const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);

function SignIn() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const { signIn } = useAuth();

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <Formik
      initialValues={{
        user: "",
        password: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        user: Yup.string().max(50).required("El usuario es requerido"),
        password: Yup.string().max(50).required("Se requiere contraseña"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          const user = await signIn(values.user, values.password);
          if (values.password === "1234") {
            navigate("/cambiarContraseña");
            return;
          }
          if (user.favHome) {
            navigate(user.favHome);
          } else {
            navigate("dashboard/home");
          }
        } catch (error) {
          const message = error.message || "Algo salió mal";

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert mt={2} mb={3} severity="warning">
              {errors.submit}
            </Alert>
          )}
          <TextField
            type="text"
            name="user"
            label={[<CustomLabel>Usuario</CustomLabel>]}
            value={values.user}
            error={Boolean(touched.user && errors.user)}
            fullWidth
            helperText={touched.user && errors.user}
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
          />
          <TextField
            name="password"
            label={[<CustomLabel>Contraseña</CustomLabel>]}
            value={values.password}
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            onBlur={handleBlur}
            type={showPassword ? "text" : "password"}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    {showPassword ? <Eye /> : <EyeOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            my={2}
          />
          {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Recordarme"
          /> */}
          <Button
            my={2}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            sx={{ mt: 2 }}
          >
            Iniciar sesión
          </Button>
        </form>
      )}
    </Formik>
  );
}

export default SignIn;
