import { useState } from "react";
import {
  TextField,
  Button,
  Card,
  Box,
  Container,
  CircularProgress,
} from "@mui/material";
import { HelperText } from "../HelperText.jsx";
import { Form, FormikProvider, useFormik } from "formik";
import { useSnackbar } from "notistack";
import LoadingButton from "@mui/lab/LoadingButton";
import { DataGrid, esES } from "@mui/x-data-grid";
import * as Yup from "yup";
import DownloadIcon from "@mui/icons-material/Download.js";
import hosteo from "../../../utils/hosteo.js";
import axios from "../../../utils/axios.js";
import * as XLSX from "../../../../node_modules/xlsx/xlsx.js";
import { BenefNumYDeclaPDF } from "./pdf/BenefNumYDeclaPDF.jsx";
import { BuscadorTabla } from "../../BuscadorTabla.jsx";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import CustomLabel from "../../../theme/CustomLabel.js";
import { convertirCuit } from "../../../utils/formatNumber.js";
import useAuth from "../../../hooks/useAuth.js";

const columns = [
  {
    field: "benef",
    headerName: "Beneficiario",
    flex: 1,
    minWidth: 150,
    headerAlign: "right",
    align: "right",
    editable: false,
  },
  {
    field: "cuil",
    headerName: "CUIL",
    flex: 1,
    minWidth: 120,
    editable: false,
    type: "number",
    valueFormatter: (params) => convertirCuit(params.value),
  },
  {
    field: "nombre",
    headerName: "Nombre",
    flex: 1,
    minWidth: 120,
    editable: false,
  },
];

export default function BenefNumYDeclaCard() {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [datos, setDatos] = useState([]);
  const [entre, setEntre] = useState({});
  const [filtrado, setFiltrado] = useState([]);
  const { user } = useAuth();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      desdeNBenef: "",
      hastaNBenef: "",
    },
    validationSchema: Yup.object().shape({
      desdeNBenef: Yup.string().required(
        "El N° de beneficiario es obligatorio"
      ),
      hastaNBenef: Yup.string().required(
        "El N° de beneficiario es obligatorio"
      ),
    }),
    onSubmit: async (values) => {
      setDatos([]);
      try {
        setIsLoading(true);
        const response = await axios.post(
          `${hosteo}/api/empadronamiento/beneficiarios/getBenefEntreDDJJ`,
          {
            periodoInicial: values.desdeNBenef,
            periodoFinal: values.hastaNBenef,
          }
        );
        setEntre({ desde: values.desdeNBenef, hasta: values.hastaNBenef });
        setDatos(
          response.data.map((element, index) => ({ ...element, id: index + 1 }))
        );
        setFiltrado(
          response.data.map((element, index) => ({ ...element, id: index + 1 }))
        );
      } catch (error) {
        enqueueSnackbar(error.message || "Algo salió mal", {
          variant: "error",
        });
      } finally {
        setIsLoading(false);
      }
    },
  });
  const { handleSubmit, getFieldProps, touched, errors } = formik;

  const getXLSX = () => {
    let nombre = "Benef entre num y decla";

    const workbook = XLSX.utils.book_new();

    // Crea una nueva hoja de cálculo con datos
    const dia = new Date();
    const options = {
      timeZone: "America/Buenos_Aires",
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };

    // Encabezado original
    const encabezado = [
      [`Reporte de ${nombre}`],
      [
        `Generado a las ${dia
          .toLocaleTimeString("es-AR", options)
          .slice(0, 5)}hs del ${dia.toLocaleDateString()}`,
      ],
      [""],
    ];

    // Títulos de las columnas
    const columnTitles = ["Beneficiario", "Cuil", "Nombre"];

    // Unir encabezado original con nuevos títulos de columna
    const datosConTitulos = [...encabezado, columnTitles];

    // Agregar datos a la hoja de cálculo
    datos.forEach((item) => {
      const rowData = [item.benef, item.cuil, item.nombre];
      datosConTitulos.push(rowData);
    });

    const worksheet = XLSX.utils.aoa_to_sheet(datosConTitulos);
    XLSX.utils.book_append_sheet(workbook, worksheet, nombre);
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${nombre}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const generateAndDownloadPDF = async () => {
    const blob = await pdf(
      <BenefNumYDeclaPDF datosPDF={datos} entre={entre} user={user} />
    ).toBlob();
    saveAs(
      blob,
      `BenefEntreNumYdeclaracion - ${new Date().toLocaleDateString()}`
    );
  };

  const handleKeyDown = (event, nextInputId) => {
    if (event.key === "Enter") {
      if (nextInputId === 2) {
        console.log("Listo");
      } else {
        document.getElementById(nextInputId + 1).focus();
      }
    }
  };
  return (
    <>
      <Card>
        <Box sx={{ mt: 2 }}>
          <HelperText
            message={"Trae los afiliados con el rango de numero beneficiario"}
          />
        </Box>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Container maxWidth={"xl"}>
              <Box
                sx={{
                  p: 3,
                  display: "flex",
                  gap: 3,
                  justifyContent: "center",
                  "@media (max-width: 600px)": {
                    flexDirection: "column",
                    alignItems: "center",
                  },
                }}
              >
                <TextField
                  disabled={isLoading}
                  sx={{ width: "220px" }}
                  key="desdeNBenef1"
                  label={<CustomLabel>Desde N° Benef</CustomLabel>}
                  type="number"
                  {...getFieldProps("desdeNBenef")}
                  error={Boolean(touched.desdeNBenef && errors.desdeNBenef)}
                  helperText={touched.desdeNBenef && errors.desdeNBenef}
                  id={1}
                  onKeyDown={(e) => handleKeyDown(e, 1)}
                />
                <TextField
                  disabled={isLoading}
                  sx={{ width: "220px" }}
                  key="hastaNBenef1"
                  label={<CustomLabel>Hasta N° Benef</CustomLabel>}
                  type="number"
                  {...getFieldProps("hastaNBenef")}
                  error={Boolean(touched.hastaNBenef && errors.hastaNBenef)}
                  helperText={touched.hastaNBenef && errors.hastaNBenef}
                  id={2}
                  onKeyDown={(e) => handleKeyDown(e, 2)}
                />
                <Box sx={{ height: "52px", display: "flex", gap: 3 }}>
                  <LoadingButton
                    sx={{ height: "100%" }}
                    loading={isLoading}
                    type="submit"
                    variant="contained"
                  >
                    Buscar
                  </LoadingButton>
                </Box>
              </Box>
              {isLoading && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    my: 10,
                  }}
                >
                  <CircularProgress size={75} />
                </Box>
              )}
              {datos.length > 0 && (
                <>
                  <Box
                    sx={{ display: "flex", justifyContent: "center", gap: 2 }}
                  >
                    <Button
                      sx={{ height: "100%" }}
                      variant="contained"
                      startIcon={<DownloadIcon />}
                      color="error"
                      onClick={generateAndDownloadPDF}
                    >
                      PDF
                    </Button>
                    <Button
                      sx={{ height: "100%" }}
                      variant="contained"
                      startIcon={<DownloadIcon />}
                      color="success"
                      onClick={getXLSX}
                    >
                      EXCEL
                    </Button>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      mt: 3,
                    }}
                  >
                    <BuscadorTabla datos={datos} setter={setFiltrado} />
                  </Box>
                  <Box
                    sx={{
                      p: 3,
                    }}
                  >
                    <DataGrid
                      sx={{ padding: 1 }}
                      autoHeight
                      disableRowSelectionOnClick
                      localeText={
                        esES.components.MuiDataGrid.defaultProps.localeText
                      }
                      rows={filtrado || []}
                      columns={columns}
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 25,
                          },
                        },
                      }}
                      pageSizeOptions={[5, 10, 25]}
                    />
                  </Box>
                </>
              )}
            </Container>
          </Form>
        </FormikProvider>
      </Card>
    </>
  );
}
