import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// slices
import counterReducer from "./slices/counter";
import gestionRecuperoReducer from "./slices/gestionRecupero";
import ingresoPrestacionalReducer from "./slices/ingresoPrestacional";

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

const gestionRecuperoConfig = {
  key: "user",
  storage,
  keyPrefix: "redux-",
  whitelist: [
    "deudaGeneralDiscriminada",
    "ultimaActualizacion",
    "sortModel",
    "filterModel",
  ],
};

const ingresoPrestacionalConfig = {
  key: "name",
  storage,
  keyPrefix: "redux-",
  whitelist: [
    "beneficiario",
    "fechaAdmision",
    "urgencia",
    "observacion",
    "lista",
  ],
};

const rootReducer = combineReducers({
  counter: counterReducer,
  gestionRecupero: persistReducer(
    gestionRecuperoConfig,
    gestionRecuperoReducer
  ),
  ingresoPrestacional: persistReducer(
    ingresoPrestacionalConfig,
    ingresoPrestacionalReducer
  ),
});

export { rootReducer, rootPersistConfig };
