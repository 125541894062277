import {
  Page,
  View,
  Text,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";

// ----------------------------------------------------------------------

Font.register({
  family: "Poppins",
  fonts: [
    {
      src: `${process.env.PUBLIC_URL}/static/fonts/Poppins/Poppins-Regular.ttf`,
    },
    { src: `${process.env.PUBLIC_URL}/static/fonts/Poppins/Poppins-Bold.ttf` },
  ],
});

const styles = StyleSheet.create({
  col4: { width: "25%" },
  col8: { width: "75%" },
  col6: { width: "50%", marginRight: 4 },
  mb2: { marginBottom: 2 },
  mb8: { marginBottom: 8 },
  mb40: { marginBottom: 40 },
  overline: {
    fontSize: 8,
    marginBottom: 8,
    fontWeight: "bold",
    letterSpacing: 1.2,
    textTransform: "uppercase",
  },
  h3: { fontSize: 16, fontWeight: "bold" },
  h4: { fontSize: 13, fontWeight: "bold" },
  body1: { fontSize: 10 },
  subtitle1: { fontSize: 9, fontWeight: "bold" },
  subtitle2: { fontSize: 9, fontWeight: "bold", textAlign: "right" },
  alignRight: { textAlign: "right" },
  alignCenter: { textAlign: "center" },
  page: {
    padding: "40px 24px 50px 24px",
    marginBottom: "20px",
    fontSize: 9,
    lineHeight: 1.6,
    backgroundColor: "#fff",
    textTransform: "none",
    fontFamily: "Poppins",
  },
  footer: {
    position: "absolute",
    bottom: 24,
    right: 24,
  },
  gridContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },

  noBorder: { paddingTop: 8, paddingBottom: 0, borderBottomWidth: 0 },
  tableCell_1: { width: "40%", textAlign: "left" },
  tableCell_2: { width: "20%", textAlign: "right" },
  tableCell_1Total: { width: "40%", textAlign: "right", fontWeight: "bold" },
  tableCell_2Total: { width: "20%", textAlign: "right", fontWeight: "bold" },
  textDisclaimer: { marginTop: 2, textAlign: "center" },
  detailContainer: { marginBottom: 2, display: "flex", flexDirection: "row" },
  netoContainer: {
    marginTop: 13,
    marginBottom: 16,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  table: {
    display: "table",
    width: "auto",
    marginBottom: 10,
    alignItems: "center",
    justifyContent: "center",
  },
  tableHeader: { backgroundColor: "#f2f2f2" },
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderColor: "#DFE3E8",
  },
  table2: {
    width: "75%",
  },
  tableCell: {
    padding: 2,
    fontSize: 8,
    width: "auto",
  },
  tableCellHeader: {
    fontWeight: "bold",
  },
});

// ----------------------------------------------------------------------

export const MovBenefPDF = ({ datosPDF, beneficiario, user }) => {
  const dia = new Date();
  const options = {
    timeZone: "America/Buenos_Aires",
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };

  return (
    <Document>
      <Page size="A4" style={styles.page} wrap>
        <View style={[styles.gridContainer, { marginBottom: 8 }]}>
          <Image
            source={`${process.env.PUBLIC_URL}/static/img/brands/logo.png`}
            style={{ width: 150, height: 30 }}
          />
          <View
            style={{ flex: 1, alignItems: "center", justifyContent: "center" }}
          >
            <Text style={styles.h4}>Movimiento de beneficiarios</Text>
          </View>
          <View style={{ alignItems: "flex-end", flexDirection: "column" }}>
            <Text>
              {dia.toLocaleDateString()},{" "}
              {dia.toLocaleTimeString("es-AR", options).slice(0, 5)}
            </Text>
            <Text>
              {user.name} {user.lastname}
            </Text>
          </View>
        </View>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 10,
          }}
        >
          <Text
            style={{ fontSize: 10, fontWeight: "bold", textAlign: "center" }}
          >
            Titular {beneficiario[0].nombre} - {beneficiario[0].cuil}
          </Text>
        </View>
        <>
          <View style={styles.table}>
            <View style={styles.table2}>
              <View style={styles.tableHeader}>
                <View style={styles.tableRow}>
                  <Text
                    style={[
                      styles.tableCell,
                      styles.tableCellHeader,
                      { width: "25%", textAlign: "right" },
                    ]}
                  >
                    Fecha
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      styles.tableCellHeader,
                      { width: "35%" },
                    ]}
                  >
                    Evento
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      styles.tableCellHeader,
                      { width: "40%", textAlign: "right" },
                    ]}
                  >
                    Motivo
                  </Text>
                </View>
              </View>
              {datosPDF.map((item) => (
                <View style={styles.tableRow} key={item.detalle}>
                  <Text
                    style={[
                      styles.tableCell,
                      { width: "25%", textAlign: "right" },
                    ]}
                  >
                    {item.fcambio}
                  </Text>
                  <Text style={[styles.tableCell, { width: "35%" }]}>
                    {item.tcambio}
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      { width: "40%", textAlign: "right" },
                    ]}
                  >
                    {item.detalle}
                  </Text>
                </View>
              ))}
            </View>
          </View>
        </>

        <View style={[styles.footer, { paddingTop: "10px" }]} fixed>
          <Text
            render={({ pageNumber, totalPages }) =>
              `Página ${pageNumber} de ${totalPages}`
            }
          />
        </View>
      </Page>
    </Document>
  );
};
