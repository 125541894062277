import {
  Grid,
  Typography,
  Divider as MuiDivider,
  Breadcrumbs as MuiBreadcrumbs,
  Link as MuiLink,
} from "@mui/material";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { NavLink } from "react-router-dom";
import CardReportes from "../../../components/Empadronamiento/CardsReportes/CardReportes";
import { Helmet } from "react-helmet-async";
import CommentsDisabledOutlinedIcon from "@mui/icons-material/CommentsDisabledOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import RequestQuoteOutlinedIcon from "@mui/icons-material/RequestQuoteOutlined";
import CloseFullscreenOutlinedIcon from "@mui/icons-material/CloseFullscreenOutlined";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

export const Apartados = [
  // {
  //   nombre: "Estado de empresas",
  //   href: "reporte/Estado de empresas",
  // },
  {
    titulo: "Con presentación de DDJJ",
    link: "empresasQuePresentaronDdjj",
    icono: <ArticleOutlinedIcon sx={{ color: "#4782da", fontSize: "70px" }} />,
  },
  {
    titulo: "Sin presentación de DDJJ",
    link: "empresasQueNoPresentaronDdjj",
    icono: (
      <CommentsDisabledOutlinedIcon
        sx={{ color: "#4782da", fontSize: "70px" }}
      />
    ),
  },
  {
    titulo: "Con presentación de pagos",
    link: "empresasQuePresentaronPagos",
    icono: (
      <RequestQuoteOutlinedIcon sx={{ color: "#4782da", fontSize: "70px" }} />
    ),
  },
  {
    titulo: "Comparativa entre declarado y pagado",
    link: "comparativaEntreDeclaradoYPagado",
    icono: (
      <CloseFullscreenOutlinedIcon
        sx={{ color: "#4782da", fontSize: "70px" }}
      />
    ),
  },
];

const Cabecera = () => {
  return (
    <Grid justifyContent="space-between" container spacing={6}>
      <Grid item>
        <Typography variant="h3" gutterBottom>
          Reportes de empresas
        </Typography>
        <Breadcrumbs aria-label="Breadcrumb" mt={2}>
          <MuiLink component={NavLink} to="/dashboard/home">
            Home
          </MuiLink>
          <MuiLink component={NavLink} to="/dashboard/empadronamiento">
            Empadronamiento
          </MuiLink>
          <Typography>Reportes de empresas</Typography>
        </Breadcrumbs>
      </Grid>
    </Grid>
  );
};

export default function ReportesEmpresas() {
  // const { reporteID } = useParams();

  return (
    <>
      <Helmet title="Empadronamiento" />
      <Cabecera />
      <Divider my={2} />
      <Grid container spacing={3} sx={{ mt: 2 }}>
        {Apartados.map((element, index) => (
          <Grid item xs={12} sm={3} key={index}>
            <CardReportes
              link={element.link}
              title={element.titulo}
              icon={element.icono}
              description={element.description}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
}
