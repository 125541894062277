import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import * as Yup from "yup";
import { Formik } from "formik";
import CustomLabel from "../../theme/CustomLabel";

import {
  Alert as MuiAlert,
  Button,
  TextField as MuiTextField,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { spacing } from "@mui/system";
import { Eye, EyeOff } from "react-feather";
import useAuth from "../../hooks/useAuth";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

function ResetPassword() {
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const navigate = useNavigate();
  const { resetPassword } = useAuth();

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleShowPasswordConfirm = () => {
    setShowPasswordConfirm((show) => !show);
  };

  return (
    <Formik
      initialValues={{
        password: "",
        confirmNewPassword: "",
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string()
          .min(6, "Debe ser almenos de 6 caracteres")
          .max(50, "No puede ser de más de 50 caracteres")
          .required("La contraseña es obligatoria"),
        confirmNewPassword: Yup.string().oneOf(
          [Yup.ref("password"), null],
          "Las contraseñas deben coincidir"
        ),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          resetPassword(values.password, values.confirmNewPassword);

          navigate("/");
        } catch (error) {
          const message = error.message || "Algo salió mal";

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert mt={2} mb={1} severity="warning">
              {errors.submit}
            </Alert>
          )}
          <TextField
            type={showPassword ? "text" : "password"}
            name="password"
            label={[<CustomLabel>Contraseña</CustomLabel>]}
            value={values.password}
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            onBlur={handleBlur}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    {showPassword ? <Eye /> : <EyeOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            my={3}
          />
          <TextField
            type={showPasswordConfirm ? "text" : "password"}
            name="confirmNewPassword"
            label={[<CustomLabel>Confirmar contraseña</CustomLabel>]}
            value={values.confirmNewPassword}
            error={Boolean(
              touched.confirmNewPassword && errors.confirmNewPassword
            )}
            fullWidth
            helperText={touched.confirmNewPassword && errors.confirmNewPassword}
            onBlur={handleBlur}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPasswordConfirm} edge="end">
                    {showPasswordConfirm ? <Eye /> : <EyeOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            my={3}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Resetear la contraseña
          </Button>
        </form>
      )}
    </Formik>
  );
}

export default ResetPassword;
