import { useState, useEffect } from "react";
import { Card, Box, Grid, CircularProgress } from "@mui/material";
import { HelperText } from "../HelperText.jsx";
import { useSnackbar } from "notistack";
import hosteo from "../../../utils/hosteo.js";
import axios from "../../../utils/axios.js";
import ParenExtranjerosGraph from "./totalTitulares/ParenExtranjerosGraph.jsx";
import TituExtranjerosGraph from "./totalTitulares/TituExtranjerosGraph.jsx";
import { H3, H4 } from "../../Typography.js";

export default function TotalTituYFamiCard() {
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getTotalBeneficiarios = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${hosteo}/api/empadronamiento/beneficiarios/getBenefTotales`
      );
      setData(response.data);
    } catch (error) {
      enqueueSnackbar(error.message || "No tienes conexion a internet", {
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getTotalBeneficiarios();
  }, []);

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <HelperText
          message={
            "Cantidad total a este momento, de beneficiarios. Abarcando tanto titulares como familiares."
          }
        />
      </Box>
      {isLoading ? (
        <Grid container justifyContent="center">
          <CircularProgress size={75} sx={{ mt: 10 }} />
        </Grid>
      ) : (
        <>
          <Grid
            container
            spacing={1}
            justifyContent={"center"}
            display={"flex"}
            sx={{ mt: 4 }}
          >
            <Card sx={{ padding: 10 }}>
              <Box sx={{ textAlign: "center" }}>
                <H3 fontSize={24}>
                  Cantidad de afiliados titulares y familiares
                </H3>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <Box sx={{ display: "flex", justifyContent: "space-around" }}>
                    <H4 fontSize={16}>
                      Titulares: {data && data[0].titulares}
                    </H4>
                    <H4 fontSize={16}>
                      Titulares extranjeros:{" "}
                      {data && data[2].titularesExtranjeros}
                    </H4>
                  </Box>
                  <Box sx={{ display: "flex", justifyContent: "space-around" }}>
                    <H4 fontSize={16}>
                      Familiares: {data && data[1]?.familiares}
                    </H4>
                    <H4 fontSize={16}>
                      Familiares extranjeros:{" "}
                      {data && data[3]?.parientesExtranjeros}
                    </H4>
                  </Box>
                </Box>
              </Box>
            </Card>
          </Grid>

          <Card sx={{ padding: 10, mt: 10 }}>
            <TituExtranjerosGraph datos={data?.titularesExtranjerosxZona} />
          </Card>
          <Card sx={{ padding: 10, my: 10 }}>
            <ParenExtranjerosGraph datos={data?.parientesExtranjerosxZona} />
          </Card>
        </>
      )}
    </>
  );
}
