import * as Yup from "yup";
import { useState } from "react";
import { Form, FormikProvider, useFormik } from "formik";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import axios from "../../utils/axios";
import hosteo from "../../utils/hosteo";
import CustomLabel from "../../theme/CustomLabel";
// ------------------------------------

const validationSchemaCreate = Yup.object().shape({
  dcuil: Yup.number().required("El CUIL es requerido"),
  acuil: Yup.number()
    .required("El CUIL es requerido")
    .test(
      "len",
      "El CUIL debe tener exactamente 11 dígitos",
      (val) => val.toString().length === 11
    ),
});

export default function MegacambioModal({ open, close }) {
  const [loading, setLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const formik = useFormik({
    validationSchema: validationSchemaCreate,
    enableReinitialize: true,
    initialValues: {
      dcuil: "",
      acuil: "",
    },
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const response = await axios.post(
          `${hosteo}/api/empadronamiento/megacambioCuil`,
          {
            ...values,
          }
        );
        enqueueSnackbar(
          response.data.message || "Cuil modificado exitosamente",
          {
            variant: "success",
          }
        );
        setLoading(false);
        close();
        formik.resetForm();
      } catch (error) {
        setLoading(false);
        enqueueSnackbar(error.message || "No tienes conexion a internet", {
          variant: "error",
        });
      }
    },
  });
  const { getFieldProps, handleSubmit, setFieldValue, values } = formik;

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth={"sm"}
      keepMounted
      onClose={close}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Typography variant="h5" sx={{ p: 2, textAlign: "center" }}>
            Megacambio de CUIL
          </Typography>

          <DialogContent
            sx={{ display: "flex", flexDirection: "column", gap: 3 }}
          >
            <TextField
              {...getFieldProps("dcuil")}
              type="number"
              error={Boolean(formik.touched.dcuil && formik.errors.dcuil)}
              helperText={formik.touched.dcuil && formik.errors.dcuil}
              label={<CustomLabel>De cuil</CustomLabel>}
            />
            <TextField
              {...getFieldProps("acuil")}
              type="number"
              error={Boolean(formik.touched.acuil && formik.errors.acuil)}
              helperText={formik.touched.acuil && formik.errors.acuil}
              label={<CustomLabel>A cuil</CustomLabel>}
            />
          </DialogContent>
          <DialogActions>
            <Button color="inherit" onClick={close}>
              Cerrar
            </Button>
            <LoadingButton loading={loading} variant="contained" type="submit">
              Guardar
            </LoadingButton>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  );
}
