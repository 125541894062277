import Cabecera from "../../../../components/Cabecera";
import ConsultaFamiliaresCard from "../../../../components/Empadronamiento/CardsReportes/ConsultaFamiliares";

const items = [
  { linkTo: "/dashboard/home", nombre: "Home" },
  { linkTo: "/dashboard/empadronamiento", nombre: "Empadronamiento" },
  {
    linkTo: "/dashboard/empadronamiento/beneficiarios/reportebenf",
    nombre: "Reportes de beneficiacios",
  },
];

export default function TransfYReduc() {
  return (
    <>
      <Cabecera
        title="Consulta por familiares"
        items={items}
        divider={false}
        helmet="Empadronamiento"
      />
      <ConsultaFamiliaresCard />
    </>
  );
}
