import React from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Button as MuiButton, Typography } from "@mui/material";
import { spacing } from "@mui/system";
import { WhatsApp as WhatsAppIcon } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const Button = styled(MuiButton)(spacing);

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(6)};
  text-align: center;
  background: transparent;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

const NotPrivil = () => {
  const { pathname } = useLocation();
  const { user } = useAuth();
  const msj = encodeURIComponent(
    `Hola!\nQuería que me habiliten el privilegio necesario para ingresar al aplicativo web de OSPIV\nUsuario : ${user.email}\nLink : ${pathname}`
  );
  return (
    <Wrapper>
      <Helmet title="Privileges Error" />
      <Typography
        mt={"25vh"}
        component="h1"
        variant="h1"
        align="center"
        gutterBottom
      >
        403 Acceso denegado
      </Typography>
      <Typography component="h2" variant="h5" align="center" gutterBottom>
        No posee los Privilegios necesarios
      </Typography>
      <Typography component="h2" variant="body1" align="center" gutterBottom>
        Comuniquese con soporte o con un directivo para que se le habilite el
        privilegio necesario.
      </Typography>

      <Button
        component={Link}
        to="/dashboard/home"
        variant="contained"
        color="secondary"
        m={2}
      >
        Volver al Home
      </Button>
      <Button
        startIcon={<WhatsAppIcon />}
        component="a"
        //href="https://wa.me/+5491159812362?text=Hola!%20Queria%20que%20me%20habiliten%20el%20privilegio%20necesario%20para%20ingresar%20al%20aplicativo%20web%20de%20OSPIV,%20les%20envi%C3%B3%20los%20datos%20de%20usuario%20y%20a%20que%20pagina%20necesito%20acceder"
        onClick={() =>
          window.open(`https://wa.me/+5491159812362?text=${msj}`, "_blank")
        }
        variant="contained"
        color="secondary"
        m={2}
      >
        Soporte
      </Button>
    </Wrapper>
  );
};

export default NotPrivil;
