import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Search as SearchIcon } from "react-feather";
import { Chip, Divider, Grid, Input, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import navItems from "../sidebar/dashboardItems";
import searchOptions from "./SearchOptions";
import { Link } from "react-router-dom";
import useTheme from "../../hooks/useTheme";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  ".css-132ogrr-MuiPaper-root-MuiDialog-paper": {
    borderRadius: "24px",
    padding: "0.8rem 0.2rem",
    width: "600px",
  },
  ".css-1q71xqe-MuiPaper-root-MuiDialog-paper": {
    borderRadius: "24px",
    padding: "0.8rem 0.2rem",
    width: "600px",
  },
  ".css-hz1bth-MuiDialog-container": { height: "80%" },
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  "&::-webkit-scrollbar": {
    borderRadius: 24,
    height: "20px",
    width: 5,
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "transparent",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "grey",
    borderRadius: 24,
    width: 5,
  },
}));

export default function DialogBuscador({ open, handleClose }) {
  const [filteredItems, setFilteredItems] = useState([]);
  const { theme, setTheme } = useTheme();

  const clickearLinkBuscador = () => {
    handleClose();
    setFilteredItems(searchMatchingItems(searchOptions, ""));
  };
  const searchMatchingItems = (items, searchText) => {
    return items.reduce((acc, item) => {
      //acumulador
      const tempAcc = [];
      const itemInPage = searchItem(item, searchText);

      if (itemInPage.length > 0) {
        tempAcc.push(...itemInPage);
      } //creo q es por acá. Tengo que ver el item.children

      if (tempAcc.length > 0) {
        acc.push(...tempAcc);
      }

      return acc;
    }, []);
  };

  /* const searchPage = (page, searchText) => {
    return (
      page.title.toLowerCase().includes(searchText) ||
      (page.actions &&
        page.actions.some(
          (action) =>
            action.title.toLowerCase().includes(searchText) ||
            action.description.toLowerCase().includes(searchText) ||
            action.keywords.some((keyword) =>
              keyword.toLowerCase().includes(searchText)
            )
        ))
    );
  }; */

  const searchItem = (item, searchText) => {
    const searchInItem = (text) => text.toLowerCase().includes(searchText);

    /* const searchInActions = (actions) => {
      if (!actions) {
        return false;
      }
      const porSioPorNo = actions.some(
        (action) =>
          searchInItem(action.title) ||
          searchInItem(action.description) ||
          action.keywords.some((keyword) => searchInItem(keyword))
      );

      return porSioPorNo;
    }; */

    const searchInPages = (page) => {
      const searchResults = [];

      page.forEach((element) => {
        if (element.children) {
          element.children.forEach((sonElement) => {
            const prueba = sonElement.actions.filter(
              (action) =>
                searchInItem(action.title) ||
                searchInItem(action.description) ||
                action.keywords.some((keyword) => searchInItem(keyword))
            );

            if (prueba.length > 0) {
              searchResults.push(
                prueba.map((obj) => {
                  return {
                    ...obj,
                    href: sonElement.href,
                    parentTitle: item.title,
                  };
                })
              );
            }
          });
        } else {
          const prueba = element.actions.filter(
            (action) =>
              searchInItem(action.title) ||
              searchInItem(action.description) ||
              action.keywords.some((keyword) => searchInItem(keyword))
          );
          if (prueba.length > 0) {
            searchResults.push(
              prueba.map((obj) => {
                return {
                  ...obj,
                  href: element.href,
                  parentTitle: item.title,
                };
              })
            );
          }
        }
      });
      return searchResults;
    };
    return item.pages && searchInPages(item.pages);
  };

  // Función para filtrar las acciones que contienen el texto "busca"
  /* const filterActionsWithKeyword = (data, keyword) => {
    const filteredData = data.map((item) => ({
      title: item.title,
      pages: item.pages.map((page) => ({
        ...page,
        actions: (page.actions || []).filter(
          (action) =>
            action.title.toLowerCase().includes(keyword) ||
            action.description.toLowerCase().includes(keyword) ||
            action.keywords.some((kw) => kw.toLowerCase().includes(keyword))
        ),
      })),
    }));

    return filteredData.filter((item) =>
      item.pages.some((page) => page.actions.length > 0)
    );
  }; */

  const handleSearch = (e) => {
    const searchText = e.target.value.toLowerCase();
    const matchingItems = searchMatchingItems(searchOptions, searchText);
    setFilteredItems(matchingItems);
  };

  // console.log("rutas", routes[1].children);
  return (
    <StyledDialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Grid container>
          <Grid md={0.7}>
            <SearchIcon />
          </Grid>
          <Grid md={11.3}>
            <Input
              placeholder={"Buscador"}
              autoFocus
              sx={{ width: "100%" }}
              onChange={handleSearch}
            />
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <StyledDialogContent
        sx={{
          overflow: "auto",
          maxHeight: "300px",
          height: "300px",
        }}
      >
        {filteredItems?.map((item, index) => (
          <div key={index}>
            {/* item.pages.map((page, pageIndex) => (
              <div key={pageIndex}>
                {page.actions &&
                  page?.actions?.map((actions, actionsIndex) => (
                    <div key={`child-${index}-${actionsIndex}`}>
                      <Link
                        to={page.href}
                        style={{
                          color: theme === "DARK" ? "white" : "black",
                          textDecoration: "none",
                        }}
                        onClick={clickearLinkBuscador}
                      >
                        <DialogTitle
                          id={`alert-dialog-title-child-${index}-${actionsIndex}`}
                          sx={{ paddingY: 2 }}
                        >
                          <Grid container>
                            <Grid md={6}>{actions.title}</Grid>
                            <Grid md={6} textAlign={"end"}>
                              <Chip label={page.title} />
                            </Grid>
                            <Grid>
                              <Typography variant="caption">
                                {actions.description}
                              </Typography>
                            </Grid>
                          </Grid>
                        </DialogTitle>
                      </Link>
                      <Divider />
                    </div>
                  ))}
              </div>
            )) */}
            {item?.map((page, pageIndex) => (
              <div key={pageIndex}>
                <div key={`child-${pageIndex}`}>
                  <Link
                    to={page.href}
                    style={{
                      color: theme === "DARK" ? "white" : "black",
                      textDecoration: "none",
                    }}
                    onClick={clickearLinkBuscador}
                  >
                    <DialogTitle
                      id={`alert-dialog-title-child-${pageIndex}`}
                      sx={{ paddingY: 2 }}
                    >
                      <Grid container>
                        <Grid md={6}>{page.title}</Grid>
                        <Grid md={6} textAlign={"end"}>
                          <Chip label={page.parentTitle} />
                        </Grid>
                        <Grid>
                          <Typography variant="caption">
                            {page.description}
                          </Typography>
                        </Grid>
                      </Grid>
                    </DialogTitle>
                  </Link>
                  <Divider />
                </div>
              </div>
            ))}
            <Divider />
          </div>
        ))}
      </StyledDialogContent>
    </StyledDialog>
  );
}
