import { useState, useEffect } from "react";
import useAuth from "../../../hooks/useAuth.js";
import {
  TextField,
  Button,
  Card,
  Box,
  Autocomplete,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  CircularProgress,
} from "@mui/material";
import { HelperText } from "../HelperText.jsx";
import { Form, FormikProvider, useFormik } from "formik";
import { useSnackbar } from "notistack";
import LoadingButton from "@mui/lab/LoadingButton";
import { DataGrid, esES } from "@mui/x-data-grid";
import hosteo from "../../../utils/hosteo.js";
import axios from "../../../utils/axios.js";
import * as XLSX from "../../../../node_modules/xlsx/xlsx.js";
import DownloadIcon from "@mui/icons-material/Download";
import CustomLabel from "../../../theme/CustomLabel.js";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import { ResumenTitularesPDF } from "./pdf/ResumenTitularesPDF.jsx";
import { BuscadorTabla } from "../../BuscadorTabla.jsx";

export default function ResumenTitularesCard() {
  const { enqueueSnackbar } = useSnackbar();
  const [tipoBusqueda, setTipoBusqueda] = useState("1");
  const [familiares, setFamiliares] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [datos, setDatos] = useState([]);
  const [filtrado, setFiltrado] = useState([]);
  const { user } = useAuth();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      desde: "",
      zonaDesde: "",
      zonaHasta: "",
    },
    // validationSchema: Yup.object().shape({
    //   zona: Yup.string().required("La zona es obligatoria"),
    //   familiar: Yup.string().required("El familiar es obligatorio"),
    // }),
    onSubmit: async (values) => {
      setDatos([]);
      try {
        setIsLoading(true);
        const response = await axios.post(
          `${hosteo}/api/empadronamiento/beneficiarios/getResumenTitularesYfam`,
          {
            type: tipoBusqueda,
            desde: values.zonaDesde,
            hasta: values.zonaHasta,
            pariente: values.familiar,
          }
        );
        const rowsWithId = response.data
          ? response.data.map((item, index) => ({
              ...item,
              id: item.index || index + 1,
            }))
          : [];
        setDatos(rowsWithId);
        setFiltrado(rowsWithId);
      } catch (error) {
        enqueueSnackbar(error.message || "Algo salió mal", {
          variant: "error",
        });
      } finally {
        setIsLoading(false);
      }
    },
  });
  const { handleSubmit, setFieldValue, getFieldProps, touched, errors } =
    formik;

  const fetchFamiliares = async () => {
    try {
      const response = await axios.get(
        `${hosteo}/api/empadronamiento/beneficiarios/getParentesco`
      );
      setFamiliares(response.data);
    } catch (error) {
      enqueueSnackbar(error.message || "No tienes conexión a internet", {
        variant: "error",
      });
    }
  };
  useEffect(() => {
    fetchFamiliares();
  }, []);

  const columns = [
    {
      field: "zona",
      headerName: "C.Zona",
      flex: 1,
      minWidth: 150,
      editable: false,
      type: "number",
    },
    {
      field: "paren",
      headerName: "C.Paren",
      flex: 1,
      minWidth: 120,
      editable: false,
      type: "number",
    },
    {
      field: "parentesco",
      headerName: "Descripcion",
      flex: 1,
      minWidth: 120,
      editable: false,
    },
    {
      field: "cantidad",
      headerName: "Cantidad",
      flex: 1,
      minWidth: 120,
      editable: false,
      type: "number",
    },
  ];

  const columnas = [
    {
      field: "zona",
      headerName: "C.Zona",
      flex: 1,
      minWidth: 150,
      editable: false,
      type: "number",
    },
    {
      field: "nombre",
      headerName: "Nombre",
      flex: 1,
      minWidth: 120,
      editable: false,
    },
    {
      field: "benef",
      headerName: "C.Benef",
      flex: 1,
      minWidth: 120,
      editable: false,
      type: "number",
    },
    {
      field: "ndoc",
      headerName: "Numero de documento",
      flex: 1,
      minWidth: 120,
      editable: false,
      type: "number",
    },
    {
      field: "cuil",
      headerName: "Cantidad",
      flex: 1,
      minWidth: 120,
      editable: false,
      type: "number",
    },
  ];

  const generateAndDownloadExcel = () => {
    let nombre = "Resumen de Tit y Fami";

    const workbook = XLSX.utils.book_new();

    // Crea una nueva hoja de cálculo con datos
    const dia = new Date();
    const options = {
      timeZone: "America/Buenos_Aires",
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };

    // Encabezado original
    const encabezado = [
      [`Reporte de ${nombre}`],
      [
        `Generado a las ${dia
          .toLocaleTimeString("es-AR", options)
          .slice(0, 5)}hs del ${dia.toLocaleDateString()}`,
      ],
      [""],
    ];

    // Títulos de las columnas
    const columnTitles = ["Zona", "C.Paren", "Descripcion", "Cantidad"];

    // Unir encabezado original con nuevos títulos de columna
    const datosConTitulos = [...encabezado, columnTitles];

    // Agregar datos a la hoja de cálculo
    datos.forEach((item) => {
      const rowData = [item.zona, item.paren, item.parentesco, item.cantidad];
      datosConTitulos.push(rowData);
    });

    const worksheet = XLSX.utils.aoa_to_sheet(datosConTitulos);
    XLSX.utils.book_append_sheet(workbook, worksheet, nombre);
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${nombre}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const generateAndDownloadPDF = async () => {
    const blob = await pdf(
      <ResumenTitularesPDF datosPDF={datos} user={user} />
    ).toBlob();
    saveAs(
      blob,
      `Resumen de titulares y familiares por zona - ${new Date().toLocaleDateString()}`
    );
  };

  const handleChangeOption = () => {
    formik.resetForm();
    setDatos([]);
  };

  const handleKeyDown = (event, nextInputId) => {
    if (event.key === "Enter") {
      if (nextInputId === 2 && tipoBusqueda === "2") {
        document.getElementById(3).focus();
      } else if (tipoBusqueda === "2" && nextInputId === 3) {
        console.log("listo");
      } else if (tipoBusqueda === "1" && nextInputId === 2) {
        console.log("listo");
      } else {
        document.getElementById(nextInputId + 1).focus();
      }
    }
  };

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <HelperText
          message={
            "Permite ver todos los empleados dados de alta o baja por cambio de obra social entre las fechas y zonas indicadas"
          }
        />
      </Box>
      <Card>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                p: 3,
                gap: 3,
              }}
            >
              <FormControl component="fieldset" variant="standard">
                <RadioGroup
                  aria-label="metodoBusqueda"
                  value={tipoBusqueda}
                  onChange={(event, value) => {
                    setTipoBusqueda(event.target.value);
                    handleChangeOption();
                  }}
                  sx={{ flexDirection: "row", justifyContent: "center" }}
                >
                  <FormControlLabel
                    value="1"
                    disabled={isLoading}
                    control={<Radio />}
                    label="Resumen de titulares y familiares"
                  />
                  <FormControlLabel
                    value="2"
                    disabled={isLoading}
                    control={<Radio />}
                    label="Resumen de familiares"
                  />
                </RadioGroup>
              </FormControl>
              <Box
                sx={{
                  display: "flex",
                  gap: 3,
                  "@media (max-width: 600px)": {
                    flexDirection: "column",
                  },
                }}
              >
                <TextField
                  sx={{
                    width: "400px",
                    "@media (max-width: 600px)": {
                      width: "250px",
                    },
                  }}
                  fullWidth
                  key="zonaDesde1"
                  label={<CustomLabel>Zona Desde</CustomLabel>}
                  type="number"
                  {...getFieldProps("zonaDesde")}
                  error={Boolean(touched.zonaDesde && errors.zonaDesde)}
                  helperText={touched.zonaDesde && errors.zonaDesde}
                  id={1}
                  onKeyDown={(e) => handleKeyDown(e, 1)}
                />
                <TextField
                  sx={{
                    width: "400px",
                    "@media (max-width: 600px)": {
                      width: "250px",
                    },
                  }}
                  fullWidth
                  key="zonaHasta1"
                  label={<CustomLabel>Zona Hasta</CustomLabel>}
                  type="number"
                  {...getFieldProps("zonaHasta")}
                  error={Boolean(touched.zonaHasta && errors.zonaHasta)}
                  helperText={touched.zonaHasta && errors.zonaHasta}
                  id={2}
                  onKeyDown={(e) => handleKeyDown(e, 2)}
                />
              </Box>
              {tipoBusqueda === "2" && (
                <Autocomplete
                  sx={{
                    width: "400px",
                    "@media (max-width: 600px)": {
                      width: "250px",
                    },
                  }}
                  options={familiares}
                  getOptionLabel={(option) => option.label}
                  renderOption={(props, option) => (
                    <li {...props}>{option.label}</li>
                  )}
                  onChange={(event, value) => {
                    if (value) {
                      setFieldValue("familiar", value.codigo);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label={<CustomLabel>Familiar</CustomLabel>}
                      error={Boolean(touched.familiar && errors.familiar)}
                      helperText={touched.familiar && errors.familiar}
                    />
                  )}
                  id={3}
                  onKeyDown={(e) => handleKeyDown(e, 3)}
                />
              )}

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",

                  gap: 3,
                }}
              >
                <LoadingButton
                  loading={isLoading}
                  variant="contained"
                  onClick={formik.handleSubmit}
                >
                  Aceptar
                </LoadingButton>
              </Box>
            </Box>
            {isLoading && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  my: 10,
                }}
              >
                <CircularProgress size={75} />
              </Box>
            )}
            {datos.length > 0 && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    gap: 5,
                    mt: 5,
                  }}
                >
                  <Button
                    sx={{ height: "100%" }}
                    variant="contained"
                    color="success"
                    startIcon={<DownloadIcon />}
                    onClick={generateAndDownloadExcel}
                  >
                    EXCEL
                  </Button>
                  <LoadingButton
                    variant="contained"
                    color="error"
                    onClick={generateAndDownloadPDF}
                    startIcon={<DownloadIcon />}
                  >
                    PDF
                  </LoadingButton>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    mt: 3,
                  }}
                >
                  <BuscadorTabla datos={datos} setter={setFiltrado} />
                </Box>
                <Box sx={{ p: 4 }}>
                  <DataGrid
                    autoHeight
                    sx={{ padding: 1 }}
                    disableRowSelectionOnClick
                    localeText={
                      esES.components.MuiDataGrid.defaultProps.localeText
                    }
                    rows={filtrado}
                    columns={tipoBusqueda === "1" ? columns : columnas}
                    initialState={{
                      pagination: { paginationModel: { page: 0, pageSize: 5 } },
                    }}
                    pageSizeOptions={[5, 10, 25, 100]}
                  />
                </Box>
              </>
            )}
          </Form>
        </FormikProvider>
      </Card>
    </>
  );
}
