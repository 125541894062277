import { TextField, Box, InputAdornment } from "@mui/material";
import CustomLabel from "../theme/CustomLabel";

import SearchIcon from "@mui/icons-material/Search";

export const BuscadorTabla = ({ datos, setter }) => {
  const buscador = (e) => {
    const inputValue = e.target.value.toLowerCase();

    const resultados = datos.filter((dato) => {
      // Verificar si alguno de los campos contiene el valor buscado
      return Object.values(dato).some(
        (campo) =>
          typeof campo === "string" && campo.toLowerCase().includes(inputValue)
      );
    });

    setter(resultados);
  };

  return (
    <Box container sx={{ marginBottom: 2 }}>
      <Box item xl={11} xs={10} sm={10} md={11}>
        <TextField
          fullWidth
          label={<CustomLabel>Buscar</CustomLabel>}
          onChange={(e) => buscador(e)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </Box>
  );
};
