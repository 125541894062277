import PropTypes from "prop-types";
import { Container } from "@mui/material";
// Hooks
import useAuth from "../hooks/useAuth";
import NotPrivil from "../pages/auth/NotPrivil";

// ----------------------------------------------------------------------

RoleBasedGuard.propTypes = {
  accessibleRoles: PropTypes.array, // Example ['admin', 'leader']
  children: PropTypes.node,
};

const useCurrentRole = () => {
  const { user } = useAuth();
  const role = user.privi;
  return role;
};

export default function RoleBasedGuard({ accessibleRoles, children }) {
  const currentRole = useCurrentRole();

  if (!accessibleRoles.some((role) => currentRole.includes(role))) {
    return (
      <Container>
        <NotPrivil />
      </Container>
    );
  }

  return <>{children}</>;
}
