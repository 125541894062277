import React from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";

import {
  CardActionArea,
  CardContent,
  Grid,
  Typography as MuiTypography,
  Card as MuiCard,
} from "@mui/material";

import { spacing } from "@mui/system";
import MovingIcon from "@mui/icons-material/Moving";
import Cabecera from "../../components/Cabecera";

const Typography = styled(MuiTypography)(spacing);
const Card = styled(MuiCard)(spacing);

const Items = [
  {
    linkTo: "/dashboard/gerencia/tableroControl",
    icon: <MovingIcon sx={{ fontSize: 50 }} color="secondary" />,
    nombre: "Tablero de control",
  },
];

const items = [{ linkTo: "/dashboard/home", nombre: "Home" }];

const Gerencia = () => {
  return (
    <>
      <Cabecera
        title="Gerencia"
        items={items}
        divider={true}
        helmet="Gerencia"
      />
      <Grid container spacing={2}>
        {Items.map((item) => (
          <Grid item xs={12} sm={4} md={3}>
            <Card>
              <CardActionArea component={NavLink} to={item.linkTo}>
                <CardContent
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {item.icon}
                  <Typography gutterBottom variant="h5" component="h2">
                    {item.nombre}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Gerencia;
