import { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Card,
  TextField,
  CircularProgress,
} from "@mui/material";
import CustomLabel from "../../../../theme/CustomLabel.js";
import { useSnackbar } from "notistack";
import axios from "../../../../utils/axios.js";
import hosteo from "../../../../utils/hosteo.js";
import DownloadIcon from "@mui/icons-material/Download";
import { DataGrid, esES } from "@mui/x-data-grid";
import { convertirCuit } from "../../../../utils/formatNumber.js";
import * as XLSX from "xlsx/xlsx.js";
import { HelperText } from "../../HelperText.jsx";
import { BuscadorTabla } from "../../../BuscadorTabla.jsx";

const columns = [
  {
    field: "zona",
    headerName: "Zona",
    minWidth: 120,
    editable: false,
  },
  {
    field: "empresa",
    headerName: "Empresa",
    minWidth: 120,
    editable: false,
  },
  {
    field: "cuil",
    headerName: "Cuil",
    minWidth: 150,
    type: "number",
    editable: false,
    valueFormatter: (params) => convertirCuit(params.value),
  },
  {
    field: "nombre",
    headerName: "Nombre",
    flex: 1,
    editable: false,
  },
  {
    field: "concubinos",
    headerName: "Concubinos",
    minWidth: 80,
    type: "number",
    editable: false,
  },
  {
    field: "hijos",
    headerName: "Hijos",
    type: "number",
    headerAlign: "right",
    align: "right",
    minWidth: 80,
  },
  {
    field: "tut",
    headerName: "Tut",
    minWidth: 80,
    type: "number",
    editable: false,
  },
  {
    field: "FamACargo",
    headerName: "FamACargo",

    minWidth: 110,
    type: "number",
    editable: false,
  },
  {
    field: "Disc",
    headerName: "Disc",
    minWidth: 80,
    type: "number",
    editable: false,
  },
];

export default function NominaDeExtranjeros() {
  const [nacionalidadSelected, setNacionalidadSelected] = useState(null);
  const [nacionalidades, setNacionalidades] = useState([]);
  const [datosEmpresa, setDatosEmpresa] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [filtrado, setFiltrado] = useState([]);

  const handlePaginationChange = (params) => {
    if (params.pageSize !== pageSize) {
      setPageSize(params.pageSize);
      setCurrentPage(0);
    } else {
      setCurrentPage(params.page);
    }
  };

  const { enqueueSnackbar } = useSnackbar();

  const fetchNacionalidades = async () => {
    try {
      const response = await axios.get(
        `${hosteo}/api/generic/getNacionalidades`
      );
      setNacionalidades(response.data);
    } catch (error) {
      enqueueSnackbar(error.message || "No hay conexion a internet", {
        variant: "error",
      });
    }
  };

  const getXLSX = () => {
    let nombre = "Nomina de extranjeros";

    const workbook = XLSX.utils.book_new();

    // Crea una nueva hoja de cálculo con datos
    const dia = new Date();
    const options = {
      timeZone: "America/Buenos_Aires",
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };

    // Encabezado original
    const encabezado = [
      [`Reporte de ${nombre}`],
      [
        `Generado a las ${dia
          .toLocaleTimeString("es-AR", options)
          .slice(0, 5)}hs del ${dia.toLocaleDateString()}`,
      ],
      [""],
    ];

    // Títulos de las columnas
    const columnTitles = [
      "Zona",
      "Empresa",
      "Cuil",
      "Nombre",
      "Concubinos",
      "Hijos",
      "Tut",
      "F.C",
      "Disc",
    ];

    // Unir encabezado original con nuevos títulos de columna
    const datosConTitulos = [...encabezado, columnTitles];

    // Agregar datos a la hoja de cálculo
    datosEmpresa.forEach((item) => {
      const rowData = [
        item.zona,
        item.empresa,
        item.cuil,
        item.nombre,
        item.concubinos,
        item.hijos,
        item.tut,
        item.FamACargo,
        item.Disc,
      ];
      datosConTitulos.push(rowData);
    });

    const worksheet = XLSX.utils.aoa_to_sheet(datosConTitulos);
    XLSX.utils.book_append_sheet(workbook, worksheet, nombre);
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${nombre}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    fetchNacionalidades();
  }, []);

  const handleConsultar = async () => {
    if (nacionalidadSelected) {
      try {
        setLoading(true);
        const response = await axios.post(
          `${hosteo}/api/empadronamiento/getNominaExtranjeros`,
          {
            codigo: nacionalidadSelected,
          }
        );
        setLoading(false);
        setDatosEmpresa(
          response.data.map((element, index) => ({ ...element, id: index + 1 }))
        );
        setFiltrado(
          response.data.map((element, index) => ({ ...element, id: index + 1 }))
        );
      } catch (error) {
        setLoading(false);
        enqueueSnackbar(error.message || "No hay conexion a internet", {
          variant: "error",
        });
      }
    } else {
      setError(true);
    }
  };

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <HelperText
          message={
            "Permite ver el detalle de la nomina de extranjeros en las empresas, junto con su total"
          }
        />
      </Box>

      <Card
        sx={{
          padding: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mb: 2,
            gap: 2,
          }}
        >
          <Autocomplete
            disabled={loading}
            sx={{
              width: "400px",
              "@media (max-width: 600px)": {
                width: "250px",
              },
            }}
            autoHighlight
            blurOnSelect
            fullWidth
            options={nacionalidades}
            onChange={(event, value) => {
              setNacionalidadSelected(value?.codigo);
              setError(false);
            }}
            getOptionLabel={(option) =>
              `${option.nacional === "NULL" ? "" : option.nacional}`
            }
            renderOption={(props, option) => (
              <li {...props}>
                {option.nacional === "NULL" ? "" : option.nacional}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label={[<CustomLabel>Nacionalidad</CustomLabel>]}
                error={error}
                helperText={error ? "La nacionalidad es requerida" : ""}
                inputProps={{
                  ...params.inputProps,
                }}
              />
            )}
          />
          <LoadingButton
            variant="contained"
            onClick={handleConsultar}
            loading={loading}
          >
            Consultar
          </LoadingButton>
        </Box>
        {loading && (
          <Box sx={{ display: "flex", justifyContent: "center", my: 10 }}>
            <CircularProgress size={75} />
          </Box>
        )}
        {datosEmpresa.length > 0 && (
          <>
            <Box sx={{ display: "flex", justifyContent: "center", my: 4 }}>
              <LoadingButton
                variant="contained"
                color="success"
                onClick={getXLSX}
                startIcon={<DownloadIcon />}
              >
                EXCEL
              </LoadingButton>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: 3,
              }}
            >
              <BuscadorTabla datos={datosEmpresa} setter={setFiltrado} />
            </Box>
            <DataGrid
              autoHeight
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}
              rows={filtrado || []}
              columns={columns}
              onPaginationModelChange={(row) => handlePaginationChange(row)}
              onPageChange={(newPage) => setCurrentPage(newPage)}
              paginationModel={{
                page: currentPage,
                pageSize: pageSize,
              }}
              initialState={{
                pagination: { paginationModel: { page: 0, pageSize: 5 } },
              }}
              pageSizeOptions={[5, 10, 25, 100]}
              density="compact"
            />
          </>
        )}
      </Card>
    </>
  );
}
