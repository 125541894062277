import BenefTransfReducCard from "../../../../components/Empadronamiento/CardsReportes/BenefTransfReduc";
import Cabecera from "../../../../components/Cabecera";

const items = [
  { linkTo: "/dashboard/home", nombre: "Home" },
  { linkTo: "/dashboard/empadronamiento", nombre: "Empadronamiento" },
  {
    linkTo: "/dashboard/empadronamiento/beneficiarios/reportebenf",
    nombre: "Reportes de beneficiacios",
  },
];

export default function TransfYReduc() {
  return (
    <>
      <Cabecera
        title="Reduccion de beneficiarios"
        items={items}
        divider={false}
        helmet="Empadronamiento"
      />
      <BenefTransfReducCard />
    </>
  );
}
