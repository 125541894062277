import React from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

import { Paper, Typography, Box } from "@mui/material";

import ResetPasswordComponent from "../../components/auth/ResetPassword";

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function ResetPassword() {
  return (
    <React.Fragment>
      <Box>
        <img
          alt=""
          src="../../../static/img/brands/logo.png"
          style={{
            width: "268px",
            height: "58px",
            marginBottom: "10px",
          }}
        />
      </Box>
      <Wrapper>
        <Helmet title="Reiniciar la contraseña" />

        <Typography component="h1" variant="h4" align="center" gutterBottom>
          Reiniciar la contraseña
        </Typography>
        <Typography component="h2" variant="body1" align="center">
          Deberas ingresar una nueva contraseña de <b>6 digitos</b>, pueden ser
          numeros o letras
        </Typography>

        <ResetPasswordComponent />
      </Wrapper>
    </React.Fragment>
  );
}

export default ResetPassword;
