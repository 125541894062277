import { fCurrency, fDate2 } from "../../../utils/formatNumber";

export const columnsXLSXComparativa = [
  "calle",
  "cuit",
  "empresa",
  "id",
  "localidad",
  "numero",
  "pago",
  "personas",
  "razon",
  "remunerado",
  "zona",
];
export const columnsComparativa = [
  { field: "id", headerName: "ID" },
  {
    field: "calle",
    headerName: "Calle",
    width: 190,
  },
  {
    field: "cuit",
    headerName: "CUIT",
    type: "number",
    width: 190,
  },
  {
    field: "empresa",
    headerName: "Empresa",
    width: 190,
  },
  {
    field: "localidad",
    headerName: "Localidad",
    width: 110,
  },
  {
    field: "numero",
    headerName: "Numero",
    type: "number",
    width: 110,
  },
  {
    field: "pago",
    headerName: "Pago",
    valueFormatter: (params) => fCurrency(params.value),

    width: 210,
  },
  {
    field: "personas",
    headerName: "Personas",
    width: 210,
  },
  {
    field: "razon",
    headerName: "Razón",
    width: 210,
  },
  {
    field: "remunerado",
    headerName: "Remunerado",
    valueFormatter: (params) => fCurrency(params.value),

    width: 210,
  },
  {
    field: "zona",
    headerName: "Zona",
    width: 80,
  },
];

export const columnsXSLXConPagos = [
  "calle",
  "cuit",
  "empresa",
  "fecrec",
  "id",
  "localidad",
  "monto",
  "numero",
  "razon",
  "zona",
];
export const columnsConPagos = [
  { field: "id", headerName: "ID" },
  {
    field: "calle",
    headerName: "Calle",
    width: 190,
  },
  {
    field: "cuit",
    headerName: "CUIT",
    type: "number",
    width: 190,
  },
  {
    field: "empresa",
    headerName: "Empresa",
    width: 190,
  },
  {
    field: "fecrec",
    headerName: "Fecha rec",
    valueFormatter: (params) => fDate2(params.value),
    width: 190,
  },
  {
    field: "localidad",
    headerName: "Localidad",
    width: 110,
  },
  {
    field: "monto",
    headerName: "Monto",
    type: "number",
    valueFormatter: (params) => fCurrency(params.value),

    width: 190,
  },
  {
    field: "numero",
    headerName: "Numero",
    type: "number",
    width: 110,
  },
  {
    field: "razon",
    headerName: "Razón",
    width: 210,
  },
  {
    field: "zona",
    headerName: "Zona",
    width: 80,
  },
];

export const columnsXLSXEmpSinDDJJ = [
  "calle",
  "codigo",
  "cuit",
  "id",
  "localidad",
  "numero",
  "razon",
  "zona",
];
export const columnsEmpSinDDJJ = [
  { field: "id", headerName: "ID" },
  {
    field: "calle",
    headerName: "Calle",
    width: 190,
  },
  {
    field: "codigo",
    headerName: "Código",
    width: 80,
  },
  {
    field: "cuit",
    headerName: "CUIT",
    type: "number",
    width: 190,
  },
  {
    field: "localidad",
    headerName: "Localidad",
    width: 110,
  },
  {
    field: "numero",
    headerName: "Numero",
    type: "number",
    width: 110,
  },
  {
    field: "razon",
    headerName: "Razón",
    width: 210,
  },
  {
    field: "zona",
    headerName: "Zona",
    width: 80,
  },
];

export const columnsXLSXEmpConDDJJ = [
  "calle",
  "codigo",
  "cuit",
  "fecpre",
  "id",
  "localidad",
  "monto",
  "numero",
  "personas",
  "razon",
  "zona",
];

// esto está en base a con ddjj
export const columns = [
  { field: "id", headerName: "ID" },
  {
    field: "calle",
    headerName: "Calle",
    width: 190,
  },
  {
    field: "codigo",
    headerName: "Código",
    width: 80,
  },
  {
    field: "cuit",
    headerName: "CUIT",
    type: "number",
    width: 190,
  },
  {
    field: "fecpre",
    headerName: "Fecha presentación",
    // type: "date",
    width: 150,
    valueFormatter: (params) => fDate2(params.value),
  },
  {
    field: "localidad",
    headerName: "Localidad",
    width: 110,
  },
  {
    field: "monto",
    headerName: "Monto",
    type: "number",
    width: 110,
    valueFormatter: (params) => fCurrency(params.value),
  },
  {
    field: "numero",
    headerName: "Numero",
    type: "number",
    width: 110,
  },
  {
    field: "personas",
    headerName: "Personas",
    width: 110,
  },
  {
    field: "razon",
    headerName: "Razón",
    width: 210,
  },
  {
    field: "zona",
    headerName: "Zona",
    width: 80,
  },
];
