import React, { useState } from "react";
import styled from "@emotion/styled";
import { Power } from "react-feather";
import { useNavigate } from "react-router-dom";
import MegacambioModal from "./MegacambioModal";
import MegacambioCuit from "./MegacambioCuit";

import {
  Tooltip,
  Menu,
  MenuItem,
  IconButton as MuiIconButton,
} from "@mui/material";

import useAuth from "../../hooks/useAuth";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

function NavbarUserDropdown() {
  const [anchorMenu, setAnchorMenu] = React.useState(null);
  const navigate = useNavigate();
  const [openMegacambio, setOpenMegacambio] = useState(false);
  const [openMegacambioCuit, setOpenMegacambioCuit] = useState(false);
  const { signOut, user } = useAuth();

  const userPrivileges = user.privi;

  const toggleMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const handleRedirect = () => {
    navigate("/dashboard/perfil");
  };

  const handleOpenMegacambio = () => {
    setOpenMegacambio(true);
    closeMenu();
  };

  const handleOpenMegacambioCuit = () => {
    setOpenMegacambioCuit(true);
    closeMenu();
  };

  const handleCambioNBenef = () => {
    navigate("/dashboard/empadronamiento/cambioNBenef");
  };

  const handleSignOut = async () => {
    closeMenu();
    await signOut();
    navigate("/");
  };

  return (
    <React.Fragment>
      <Tooltip title="Cuenta">
        <IconButton
          aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
          size="large"
        >
          <Power />
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        <MenuItem onClick={handleRedirect}>Perfil</MenuItem>
        {userPrivileges.includes("315") && (
          <MenuItem onClick={handleOpenMegacambio}>Megacambio de CUIL</MenuItem>
        )}
        {userPrivileges.includes("314") && (
          <MenuItem onClick={handleOpenMegacambioCuit}>
            Megacambio de CUIT
          </MenuItem>
        )}
        {userPrivileges.includes("316") && (
          <MenuItem onClick={handleCambioNBenef}>
            Cambio de N° de benef
          </MenuItem>
        )}
        <MenuItem onClick={handleSignOut}>Cerrar sesión</MenuItem>
      </Menu>
      <MegacambioModal
        open={openMegacambio}
        close={() => setOpenMegacambio(false)}
      />
      <MegacambioCuit
        open={openMegacambioCuit}
        close={() => setOpenMegacambioCuit(false)}
      />
    </React.Fragment>
  );
}

export default NavbarUserDropdown;
