import * as Yup from "yup";
import { useState } from "react";
import { Form, FormikProvider, useFormik } from "formik";
import { DataGrid, esES } from "@mui/x-data-grid";
import { LoadingButton } from "@mui/lab";
import { Box, Card, TextField, CircularProgress } from "@mui/material";
import CustomLabel from "../../../../theme/CustomLabel";
import { useSnackbar } from "notistack";
import { convertirCuit } from "../../../../utils/formatNumber";
import axios from "../../../../utils/axios";
import hosteo from "../../../../utils/hosteo";
import * as XLSX from "../../../../../node_modules/xlsx/xlsx.js";
import DownloadIcon from "@mui/icons-material/Download";
import { BuscadorTabla } from "../../../BuscadorTabla.jsx";
import { HelperText } from "../../HelperText.jsx";

const validationSchemaCreate = Yup.object().shape({
  periodo: Yup.number()
    .required("El periodo es requerido")
    .test(
      "len",
      "El periodo debe tener 4 dígitos",
      (val) => val.toString().length === 4
    ),
});

const columns = [
  {
    field: "zona",
    headerName: "Zona",
    flex: 1,
    minWidth: 70,
    editable: false,
    headerAlign: "right",
    align: "right",
  },
  {
    field: "codigo",
    headerName: "Empresa",
    flex: 1,
    minWidth: 70,
    editable: false,
    headerAlign: "right",
    align: "right",
  },
  {
    field: "cuit",
    headerName: "Cuit",
    flex: 1,
    minWidth: 130,
    headerAlign: "right",
    align: "right",
    editable: false,
    valueFormatter: (params) => convertirCuit(params.value),
  },
  {
    field: "razon",
    headerName: "Razon",
    flex: 1,
    minWidth: 250,
    editable: false,
  },
  {
    field: "calle",
    headerName: "Calle",
    flex: 1,
    minWidth: 130,
    editable: false,
  },
  {
    field: "numero",
    headerName: "Numero",
    flex: 1,
    minWidth: 70,
    editable: false,
    headerAlign: "right",
    align: "right",
  },
  {
    field: "localidad",
    headerName: "Localidad",
    flex: 1,
    minWidth: 150,
    editable: false,
  },
  {
    field: "max_periodo",
    headerName: "Ult.Per.Declarado",
    flex: 1,
    minWidth: 150,
    editable: false,
    headerAlign: "right",
    align: "right",
  },
];

export default function NumeroEmpresa() {
  const [datosEmpresa, setDatosEmpresa] = useState([]);
  const [filtrado, setFiltrado] = useState([]);
  const [loading, setLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const formik = useFormik({
    validationSchema: validationSchemaCreate,
    enableReinitialize: true,
    initialValues: {
      periodo: "",
    },
    onSubmit: async (values) => {
      setDatosEmpresa([]);
      try {
        setLoading(true);
        const response = await axios.post(
          `${hosteo}/api/empadronamiento/empresas/getSinDDJJ`,
          {
            ...values,
          }
        );
        const rowsWithId = response.data
          ? response.data.map((item, index) => ({
              ...item,
              id: item.index || index + 1,
            }))
          : [];
        setDatosEmpresa(rowsWithId);
        setFiltrado(rowsWithId);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        enqueueSnackbar(error.message || "No tienes conexión a internet", {
          variant: "error",
        });
      }
      setLoading(false);
    },
  });
  const { handleSubmit, getFieldProps } = formik;

  const generateAndDownloadExcel = () => {
    let nombre = "Empresas sin ddjj";

    const workbook = XLSX.utils.book_new();

    // Crea una nueva hoja de cálculo con datos
    const dia = new Date();
    const options = {
      timeZone: "America/Buenos_Aires",
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };

    // Encabezado original
    const encabezado = [
      [`Reporte de ${nombre}`],
      [
        `Generado a las ${dia
          .toLocaleTimeString("es-AR", options)
          .slice(0, 5)}hs del ${dia.toLocaleDateString()}`,
      ],
      [""],
    ];

    // Títulos de las columnas
    const columnTitles = [
      "Zona",
      "Empresa",
      "Cuit",
      "Razon",
      "Calle",
      "Numero",
      "Localidad",
      "Ult.Per.Declarado",
    ];

    // Unir encabezado original con nuevos títulos de columna
    const datosConTitulos = [...encabezado, columnTitles];

    // Agregar datos a la hoja de cálculo
    datosEmpresa.forEach((item) => {
      const rowData = [
        item.zona,
        item.codigo,
        item.cuit,
        item.razon,
        item.calle,
        item.numero,
        item.localidad,
        item.max_periodo,
      ];
      datosConTitulos.push(rowData);
    });

    const worksheet = XLSX.utils.aoa_to_sheet(datosConTitulos);
    XLSX.utils.book_append_sheet(workbook, worksheet, nombre);
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${nombre}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Card
          sx={{
            padding: 3,
          }}
        >
          <Box sx={{ my: 2 }}>
            <HelperText
              message={`Empresas que no presentaron declaración jurada en el periodo indicado`}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: 2,
              mb: 2,
            }}
          >
            <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
              <TextField
                {...getFieldProps("periodo")}
                type="number"
                error={Boolean(formik.touched.periodo && formik.errors.periodo)}
                helperText={formik.touched.periodo && formik.errors.periodo}
                label={<CustomLabel>Periodo</CustomLabel>}
              />

              <LoadingButton
                variant="contained"
                type="submit"
                onClick={formik.handleSubmit}
                loading={loading}
              >
                Consultar
              </LoadingButton>
            </Box>
          </Box>
          {loading && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                my: 20,
              }}
            >
              <CircularProgress size={75} />
            </Box>
          )}
          {datosEmpresa.length > 0 && (
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mt: 6,
                  mb: 3,
                  gap: 4,
                }}
              >
                <LoadingButton
                  variant="contained"
                  color="success"
                  onClick={generateAndDownloadExcel}
                  startIcon={<DownloadIcon />}
                >
                  EXCEL
                </LoadingButton>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <BuscadorTabla datos={datosEmpresa} setter={setFiltrado} />
              </Box>
              <DataGrid
                sx={{ padding: 1 }}
                disableRowSelectionOnClick
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                rows={filtrado}
                columns={columns}
                pageSizeOptions={[5, 10, 25]}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 25,
                    },
                  },
                }}
              />
            </Box>
          )}
        </Card>
      </Form>
    </FormikProvider>
  );
}
