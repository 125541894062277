import Cabecera from "../../../../components/Cabecera";
import ConPresentacion from "../../../../components/Empadronamiento/Empresas/Reportes/ConPresentacionDdjj";

const items = [
  { linkTo: "/dashboard/home", nombre: "Home" },
  { linkTo: "/dashboard/empadronamiento", nombre: "Empadronamiento" },
  {
    linkTo: "/dashboard/empadronamiento/empresas/reportes",
    nombre: "Reportes de empresas",
  },
];

export default function TransfYReduc() {
  return (
    <>
      <Cabecera
        title="Empresas que presentaron declaración jurada"
        items={items}
        divider={false}
        helmet="Empadronamiento"
      />
      <ConPresentacion />
    </>
  );
}
