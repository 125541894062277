import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
import hosteo from "../../utils/hosteo";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  deudaGeneralDiscriminada: [],
  ultimaActualizacion: null,
  sortModel: [],
  filterModel: { items: [] },
};

const slice = createSlice({
  name: "gestionRecupero",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET GENERAL
    getDeudaGeneralDiscriminadaSuccess(state, action) {
      state.isLoading = false;
      state.deudaGeneralDiscriminada = action.payload;
      state.ultimaActualizacion = new Date();
    },
    deleteDeudaGeneralDiscriminada(state) {
      state.isLoading = false;
      state.deudaGeneralDiscriminada = [];
      state.ultimaActualizacion = new Date();
    },
    setSortModel(state, action) {
      state.sortModel = action.payload;
    },

    setFilterModel(state, action) {
      state.filterModel = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getDeudaGeneralDiscriminada(showAll) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${hosteo}/api/cobranza/deudaGeneralDiscriminada`,
        {
          showAll: showAll,
        }
      );
      dispatch(slice.actions.getDeudaGeneralDiscriminadaSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearDeudaGeneralDiscriminada() {
  return async (dispatch) => {
    dispatch(slice.actions.deleteDeudaGeneralDiscriminada());
  };
}
export function saveSort(sort) {
  return async (dispatch) => {
    dispatch(slice.actions.setSortModel(sort));
  };
}
export function saveFilter(filter) {
  return async (dispatch) => {
    dispatch(slice.actions.setFilterModel(filter));
  };
}
