import { Card, Grid, Box, Container } from "@mui/material";
import Button from "@mui/material/Button";
import { DataGrid } from "@mui/x-data-grid";
import { pdf } from "@react-pdf/renderer";
import * as XLSX from "../../../../node_modules/xlsx/xlsx.js";
import { saveAs } from "file-saver";
import { TotalesPropAdeMonoExtNacioPDF } from "./pdf/TotalesPropAdeMonoExtNacioPDF.jsx";

export const MonotributistasExtConNacio = ({ datos, user }) => {
  //columna datagrid
  const columns = [
    {
      field: "cZona",
      headerName: "Nacionalidad",
      flex: 1,
      minWidth: 140,
      editable: false,
    },
    {
      field: "titular",
      headerName: "Titular",
      flex: 1,
      minWidth: 120,
      editable: false,
    },
    {
      field: "conyuge",
      headerName: "Conyuge",
      flex: 1,
      minWidth: 120,
      editable: false,
    },
    {
      field: "concubino",
      headerName: "Concubino",
      flex: 1,
      minWidth: 120,
      editable: false,
    },
    {
      field: "menor21",
      headerName: "Hijo Soltero menor a 21",
      flex: 1,
      minWidth: 170,
      editable: false,
    },
    {
      field: "entre21a25",
      headerName: "Hijo soltero entre 21-25",
      flex: 1,
      minWidth: 170,
      editable: false,
    },
    {
      field: "HijSolConyMenor21",
      headerName: "Hij sol cony menor a 21",
      flex: 1,
      minWidth: 170,
      editable: false,
    },
    {
      field: "HSoConEsEntre21a25",
      headerName: "H. so. con es. entre 21 y 25",
      flex: 1,
      minWidth: 175,
      editable: false,
    },
    {
      field: "menortutela",
      headerName: "Menor Tutela",
      flex: 1,
      minWidth: 130,
      editable: false,
    },
    {
      field: "famacargo",
      headerName: "Fam. a cargo",
      flex: 1,
      minWidth: 130,
      editable: false,
    },
    {
      field: "discmayor25",
      headerName: "Mayor de 25 discapacitado",
      flex: 1,
      minWidth: 170,
      editable: false,
    },
    {
      field: "totalNac",
      headerName: "Total Nacion",
      flex: 1,
      minWidth: 140,
      editable: false,
    },
  ];
  const columnsXLSX = [
    "nacional",
    "titular",
    "conyuge",
    "concubino",
    "menor21",
    "entre21a25",
    "HijSolConyMenor21",
    "HSoConEsEntre21a25",
    "menortutela",
    "famacargo",
    "discmayor25",
  ];

  const handleDownload = async () => {
    const blob = await pdf(
      <TotalesPropAdeMonoExtNacioPDF datosPDF={datos} user={user} />
    ).toBlob();

    saveAs(
      blob,
      `Cantidad de titulares y familiares por zona - ${new Date().toLocaleDateString()}`
    );
  };
  //-------------------Descarga Excel--------------------------------------
  const handleDownloadExcel = () => {
    const data = datos.map((item) => columnsXLSX.map((column) => item[column]));
    const ws = XLSX.utils.aoa_to_sheet([
      columnsXLSX.map((column) => column),
      ...data,
    ]);
    const colWidths = columnsXLSX.map((column) => ({
      wch: column === "address" ? 30 : 18,
    }));
    ws["!cols"] = colWidths;
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, `Tot prop adh y monot`);
    XLSX.writeFile(wb, `TotPropAdMono.xlsx`);
  };

  return (
    <>
      <Grid container justifyContent="center" spacing={3}>
        <Grid item>
          <Button variant="contained" color="error" onClick={handleDownload}>
            PDF
          </Button>
        </Grid>
        {/* <Grid item>
          <Button
            variant="contained"
            color="success"
            onClick={handleDownloadExcel}
          >
            EXCEL
          </Button>
        </Grid> */}
      </Grid>

      {/* <Box justifyContent="center" spacing={3} marginTop={2}>
        <Container>
          <Card sx={{ padding: 5 }}>
            <DataGrid
              rows={datos}
              columns={columns}
              disableRowSelectionOnClick
              autoHeight
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              pageSizeOptions={[5]}
            />
          </Card>
        </Container>
      </Box> */}
    </>
  );
};
