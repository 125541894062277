import {
  Divider as MuiDivider,
  Typography as MuiTypography,
  Grid,
  Breadcrumbs as MuiBreadcrumbs,
  Link,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { Helmet } from "react-helmet-async";

const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Cabecera = ({ title, items, divider, helmet }) => {
  return (
    <>
      <Helmet title={helmet} />
      <Grid justifyContent="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h3" gutterBottom>
            {title}
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            {items.map((item) => (
              <Link component={NavLink} to={item.linkTo}>
                {item.nombre}
              </Link>
            ))}
            <Typography>{title}</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>
      {divider ? <Divider my={3} /> : <></>}
    </>
  );
};

export default Cabecera;
