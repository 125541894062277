import Cabecera from "../../../../components/Cabecera";
import NominaDeExtranjeros from "../../../../components/Empadronamiento/Beneficiarios/Reportes/NominaDeExtranjeros";

const items = [
  { linkTo: "/dashboard/home", nombre: "Home" },
  { linkTo: "/dashboard/empadronamiento", nombre: "Empadronamiento" },
  {
    linkTo: "/dashboard/empadronamiento/beneficiarios/reportebenf",
    nombre: "Reportes de beneficiacios",
  },
];

export default function TransfYReduc() {
  return (
    <>
      <Cabecera
        title="Nomina de Extranjeros por pais, zona y empresa"
        items={items}
        divider={false}
        helmet="Empadronamiento"
      />
      <NominaDeExtranjeros />
    </>
  );
}
