import { Card, CardActionArea } from "@mui/material";
import { H3 } from "../../Typography";
import { useNavigate } from "react-router-dom";

export default function CardReportes({ title, handleClick, icon, link }) {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate(`./${link}`);
  };

  return (
    <>
      <CardActionArea onClick={() => handleRedirect()}>
        <Card
          variant="outlined"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            textAlign: "end",
            padding: 2,
            height: "100px",
          }}
          onClick={handleClick}
        >
          {icon}
          <H3 fontSize={14} fontWeight={700} mr={3}>
            {title}
          </H3>
        </Card>
      </CardActionArea>
    </>
  );
}
