const empadronamiento = [
  //LOS PRIVILEGIOS SON DE 300 EN ADELANTE
  {
    title: "Beneficiarios",
    children: [
      {
        title: "ABM",
        href: "/dashboard/empadronamiento/beneficiarios/abm",
        privil: "302",
        actions: [
          {
            title: "Alta de afiliados",
            description: "Dar de alta a los afiliados",
            keywords: ["alta", "nuevo", "afiliados"],
          },
          {
            title: "Baja de afiliados",
            description: "Dar de baja a los afiliados",
            keywords: ["baja", "nuevo", "afiliados"],
          },
          {
            title: "Modificacion de afiliados",
            description: "Modificar a los afiliados",
            keywords: [
              "modificacion",
              "modificar",
              "modificación",
              "nuevo",
              "afiliados",
            ],
          },
          {
            title: "Alta de familiares",
            description: "Modificar a los familiares",
            keywords: [
              "modificacion",
              "modificar",
              "modificación",
              "nuevo",
              "familiares",
              "familiar",
            ],
          },
          {
            title: "Baja de familiares",
            description: "Modificar a los familiares",
            keywords: [
              "modificacion",
              "modificar",
              "modificación",
              "nuevo",
              "familiares",
              "familiar",
            ],
          },
          {
            title: "Modificacion de familiares",
            description: "Modificar a los familiares",
            keywords: [
              "modificacion",
              "modificar",
              "modificación",
              "nuevo",
              "familiares",
              "familiar",
            ],
          },
        ],
      },
      {
        title: "Reportes",
        href: "/dashboard/empadronamiento/beneficiarios/reportes",
        privil: "303",
        actions: [
          {
            title: "Seleccionar reportes",
            description: "Reportes de beneficiarios",
            keywords: ["seleccionar", "reportes", "beneficiarios"],
          },
        ],
      },
      {
        title: "Reportes padrón",
        href: "/dashboard/empadronamiento/beneficiarios/reportebenf/padron",
        privil: "303",
        actions: [
          {
            title: "Reportes padrón",
            description: "Reportes de padrón de beneficiarios",
            keywords: ["reportes", "padrón", "beneficiarios"],
          },
          {
            title: "Reporte farmanexus",
            description: "Reporte de farmanexus",
            keywords: ["farmanexus", "red farmanexus", "red", "padron"],
          },
          {
            title: "Reporte emergencias",
            description: "Reporte de farmanexus",
            keywords: ["emergencias", "emergencias", "padron"],
          },
          {
            title: "Reporte colegio de farmaceuticos",
            description: "Reporte para colegio de farmaceuticos",
            keywords: ["colegio", "farmaceuticos", "colegio de farmaceuticos"],
          },
          {
            title: "Reporte beneficiarios y familiares VITAL por zonas",
            description: "Reporte beneficiarios y familiares VITAL por zonas",
            keywords: ["beneficiarios", "familiares", "vital", "zonas"],
          },
        ],
      },
      {
        title: "Consulta por familiares",
        href: "/dashboard/empadronamiento/beneficiarios/reportebenf/consultaPorFamiliares",
        privil: "303",
        actions: [
          {
            title: "Consulta por familiares",
            description: "Reportes de consulta de familiares",
            keywords: ["reportes", "familiar", "familiares", "parentezco"],
          },
        ],
      },
      {
        title: "Total de titulares y familiares",
        href: "/dashboard/empadronamiento/beneficiarios/reportebenf/totalTitulares",
        privil: "303",
        actions: [
          {
            title: "Cantidad de afiliados titulares y familiares",
            description: "Total de afiliados titulares y familiares",
            keywords: [
              "reportes",
              "total",
              "familiares",
              "titulares",
              "afiliados",
            ],
          },
          {
            title: "Cantidad de afiliados titulares y familiares extranjeros",
            description:
              "Total de afiliados titulares y familiares extranjeros",
            keywords: [
              "reportes",
              "total",
              "familiares",
              "titulares",
              "afiliados",
              "extranjeros",
            ],
          },
          {
            title:
              "Cantidad de afiliados titulares y familiares extranjeros con nacionalidad",
            description:
              "Total de afiliados titulares y familiares extranjeros con nacionalidad",
            keywords: [
              "reportes",
              "total",
              "familiares",
              "titulares",
              "afiliados",
              "extranjeros",
              "con nacionalidad",
              "nacionalidad",
            ],
          },
        ],
      },
      {
        title: "Población beneficiaria",
        href: "/dashboard/empadronamiento/beneficiarios/reportebenf/poblacion",
        privil: "303",
        actions: [
          {
            title: "Población beneficiaria",
            description: "Reporte de población beneficiaria",
            keywords: ["reportes", "población", "poblacion", "beneficiaria"],
          },
        ],
      },

      {
        title: "Nomina de extranjeros por zona y empresa",
        href: "/dashboard/empadronamiento/beneficiarios/reportebenf/nominaDeExtranjeros",
        privil: "303",
        actions: [
          {
            title: "Nomina de extranjeros",
            description:
              "Reportes de nomina de extranjeros por zona y empresa o pais, zona y empresa",
            keywords: [
              "reportes",
              "nomina",
              "extranjero",
              "zona",
              "empresa",
              "pais",
            ],
          },
        ],
      },
      {
        title: "Beneficiarios entre numero de declaración",
        href: "/dashboard/empadronamiento/beneficiarios/reportebenf/benefEntreNumeroYDeclaracion",
        privil: "303",
        actions: [
          {
            title: "Beneficiarios entre numero de declaración",
            description: "Reporte de beneficiarios entre numero de declaración",
            keywords: [
              "reportes",
              "beneficiarios",
              "numero",
              "declaracion",
              "declaración",
            ],
          },
        ],
      },
      {
        title: "Personal de baja/alta por cambio de obra social",
        href: "/dashboard/empadronamiento/beneficiarios/reportebenf/personalDeBajaAlta",
        privil: "303",
        actions: [
          {
            title: "Personal de baja/alta por cambio de obra social",
            description: "Reporte de los empleados dados de alta o baja",
            keywords: [
              "baja",
              "alta",
              "beneficiarios",
              "cambio de obra social",
              "empleados",
            ],
          },
        ],
      },
      {
        title: "Monotributistas aporte y grupo familiar",
        href: "/dashboard/empadronamiento/beneficiarios/reportebenf/Monotributistas",
        privil: "303",
        actions: [
          {
            title: "Monotributistas aporte y grupo familiar",
            description: "Reporte de monotributistas aporte y grupo familiar",
            keywords: [
              "reportes",
              "monotributistas",
              "aporte",
              "grupo",
              "familiar",
              "periodo",
            ],
          },
        ],
      },
      {
        title: "Beneficiarios transferencias y reducciones",
        href: "/dashboard/empadronamiento/beneficiarios/reportebenf/benefTransYReduc",
        privil: "303",
        actions: [
          {
            title: "Transferencias y Reducciones de beneficiarios",
            description:
              "Reporte de Transferencias y Reducciones de beneficiarios",
            keywords: [
              "reportes",
              "transferencias",
              "reducciones",
              "beneficiarios",
            ],
          },
        ],
      },
      {
        title: "Movimientos de beneficiarios",
        href: "/dashboard/empadronamiento/beneficiarios/reportebenf/movimientos",
        privil: "303",
        actions: [
          {
            title: "Movimientos de beneficiarios",
            description: "Reporte de movimientos de beneficiarios",
            keywords: [
              "reportes",
              "movimientos",
              "beneficiario",
              "beneficiaria",
            ],
          },
        ],
      },
      {
        title: "Monotributistas sospechosos",
        href: "/dashboard/empadronamiento/beneficiarios/reportebenf/monotributistasSinPagos",
        privil: "303",
        actions: [
          {
            title: "Monotributistas sin pagos",
            description:
              "Este resumen aborda los monotributistas sociales que no han realizado pagos durante tres meses seguidos o durante cinco meses intercalados.",
            keywords: [
              "monotributista",
              "sospechosos",
              "sin pagos",
              "tres meses consecutivos",
              "cinco meses sorteados",
            ],
          },
        ],
      },
    ],
    privil: "301",
  },
  {
    title: "Empresas",
    children: [
      {
        title: "ABM",
        href: "/dashboard/empadronamiento/empresas/abm",
        privil: "304",
        actions: [
          {
            title: "Alta de Empresas",
            description: "Dar de alta a las empresas nuevas",
            keywords: ["alta", "nueva"],
          },
          {
            title: "Baja de Empresas",
            description: "Dar de baja a las empresas nuevas",
            keywords: ["baja", "nueva"],
          },
          {
            title: "Modificacion de Empresas",
            description: "Modificar a las empresas nuevas",
            keywords: ["modificacion", "nueva"],
          },
        ],
      },
      {
        title: "Reportes",
        href: "/dashboard/empadronamiento/empresas/reportes",
        privil: "305",
        actions: [
          {
            title: "Seleccionar reportes por zona",
            description: "Reportes de empresas",
            keywords: ["zona", "buscar", "seleccionar"],
          },
          {
            title: "Seleccionar reportes por total",
            description: "Reportes de empresas",
            keywords: ["zona", "total", "seleccionar"],
          },
        ],
      },
      {
        title: "Reporte empresas con presentación de DDJJ",
        href: "/dashboard/empadronamiento/empresas/reportes/reporte/Empresas que presentaron declaración jurada",
        privil: "305",
        actions: [
          {
            title: "Empresas que presentaron declaración jurada",
            description:
              "Reportes de empresas que presentaron declaración jurada",
            keywords: [
              "empresas",
              "reporte",
              "declaración jurada",
              "declaracion jurada",
              "total",
              "zona",
              "periodo",
            ],
          },
        ],
      },
      {
        title: "Reporte empresas sin presentación de DDJJ",
        href: "/dashboard/empadronamiento/empresas/reportes/reporte/Empresas que no presentaron declaración jurada",
        privil: "305",
        actions: [
          {
            title: "Empresas que no presentaron declaración jurada",
            description:
              "Reportes de empresas que no presentaron declaración jurada",
            keywords: [
              "empresas",
              "reporte",
              "declaración jurada",
              "declaracion jurada",
              "total",
              "zona",
              "periodo",
            ],
          },
        ],
      },
      {
        title: "Reporte empresas con presentación de pagos",
        href: "/dashboard/empadronamiento/empresas/reportes/reporte/Empresas que presentaron pagos",
        privil: "305",
        actions: [
          {
            title: "Empresas que presentaron pagos",
            description: "Reportes de empresas que presentaron pagos",
            keywords: [
              "empresas",
              "reporte",
              "pagos",
              "periodo",
              "total",
              "zona",
            ],
          },
        ],
      },
      {
        title: "Reporte comparativa entre declarado y pagado",
        href: "/dashboard/empadronamiento/empresas/reportes/reporte/Comparativa entre declarado y pagado",
        privil: "305",
        actions: [
          {
            title: "Comparativa entre declarado y pagado",
            description: "Reportes de comparativa entre declarado y pagado",
            keywords: [
              "empresas",
              "comparativa",
              "declarado",
              "pagado",
              "total",
              "zona",
              "periodo",
            ],
          },
        ],
      },
    ],
    privil: "401",
  },
  {
    title: "Archivos AFIP",
    href: "/dashboard/empadronamiento/archivos-afip",
    privil: "306",
    actions: [
      {
        title: "Ver archivos de AFIP",
        description: "Revisar archivos de AFIP",
        keywords: ["Subir", "archivos"],
      },
      {
        title: "Subida de archivos de AFIP",
        description: "Subir archivos de AFIP",
        keywords: [
          "Subir",
          "subida",
          "suma",
          "sumarte",
          "suma 70",
          "subsidios",
          "padron de empresas",
          "transferencias obra social",
          "reducciones",
        ],
      },
    ],
  },
  {
    title: "Reporte Archivos AFIP",
    href: "/dashboard/empadronamiento/archivos-afip/reporte",
    privil: "306",
    actions: [
      {
        title: "Reporte AFIP",
        description: "Reporte de archivos de AFIP ",
        keywords: ["Reporte", "Archivos AFIP"],
      },
    ],
  },
  {
    title: "Consulta Beneficiarios",
    href: "/dashboard/empadronamiento/consulta",
    privil: "301",
    actions: [
      {
        title: "Beneficiarios",
        description: "Permite buscar beneficiarios y sus parientes",
        keywords: ["consulta", "afiliados", "beneficiarios"],
      },
    ],
  },
  {
    title: "Numero Empresa",
    href: "/dashboard/empadronamiento/consultaNumeroZonaEmpresa",
    privil: "307",
    actions: [
      {
        title: "Numero Empresa",
        description: "Permite buscar zona y numero de empresa con el cuit",
        keywords: ["consulta", "empresa", "zona", "numero", "cuit"],
      },
    ],
  },
  {
    title: "Benf Reduc",
    href: "/dashboard/empadronamiento/reducBenef",
    privil: "308",
    actions: [
      {
        title: "Benef Reduc",
        description:
          "Permite buscar reducciones de benefeciarios por cuil, documento o nombre",
        keywords: [
          "consulta",
          "beneficiario",
          "reduccion",
          "reducciones",
          "cuil",
          "documento",
          "nombre",
        ],
      },
    ],
  },
  {
    title: "Emp Reduc",
    href: "/dashboard/empadronamiento/reducEmp",
    privil: "309",
    actions: [
      {
        title: "Emp Reduc",
        description: "Permite buscar reducciones de empresas por cuit",
        keywords: [
          "consulta",
          "empresa",
          "reduccion",
          "reducciones",
          "cuit",
          "periodo",
        ],
      },
    ],
  },
  {
    title: "Discapacidad",
    href: "/dashboard/empadronamiento/discapacidad",
    privil: "311",
    actions: [
      {
        title: "Discapacidad",
        description:
          "Permite agregar fecha de inicio y fin del certificado de discapacidad así como tambien modificarlo ",
        keywords: ["agregar", "cambiar", "discapacidad"],
      },
    ],
  },
  {
    title: "Cumpleanos",
    href: "/dashboard/empadronamiento/cumpleanos",
    privil: "310",
    actions: [
      {
        title: "Cumpleanos",
        description:
          "Permite realizar la carga de alta y baja temprana y las bajas automaticas por falta de DDJJ ",
        keywords: ["alta", "baja", "carga", "temprana", "falta de DDJJ"],
      },
    ],
  },
  {
    title: "Alta y baja temprana",
    href: "/dashboard/empadronamiento/cumpleanos",
    privil: "310",
    actions: [
      {
        title: "Alta y baja temprana",
        description: "Permite realizar la carga de alta y baja temprana",
        keywords: ["alta", "baja", "carga", "temprana", "txt"],
      },
    ],
  },
  {
    title: "Bajas automaticas",
    href: "/dashboard/empadronamiento/cumpleanos",
    privil: "310",
    actions: [
      {
        title: "Bajas automaticas ",
        description:
          "Permite realizar bajas automaticas y desempleos automaticos",
        keywords: [
          "bajas",
          "automaticas",
          "falta DDJJ",
          "DDJJ",
          "desempleo",
          "automatico",
        ],
      },
    ],
  },
  {
    title: "Localidades",
    href: "/dashboard/empadronamiento/nacionalidadesYLocalidades",
    privil: "312",
    actions: [
      {
        title: "Localidades",
        description: "Permite agregar, modificar y eliminar Localidades",
        keywords: [
          "nacionalidades",
          "localidades",
          "altas",
          "bajas",
          "modificaciones",
        ],
      },
    ],
  },

  {
    title: "Presentar novedades",
    href: "/dashboard/empadronamiento/presentarNovedades",
    privil: "313",
    actions: [
      {
        title: "Presentar novedades",
        description: "Permite cargar el archivo de novedades",
        keywords: ["novedades", "carga", "presentar"],
      },
    ],
  },
];

const administracion = [
  // falta crear usuarios
  {
    title: "Privilegios",
    href: "/dashboard/administracion/privilegios",
    privil: "801",
    actions: [
      {
        title: "Cambio de privilegios",
        description: "Cambiar privilegios ",
        keywords: ["modificar", "cambiar", "privilegios"],
      },
    ],
  },
  {
    title: "Ver usuarios",
    href: "/dashboard/administracion/verUsuarios",
    privil: "802",
    actions: [
      {
        title: "Ver usuarios",
        description:
          "Crear, blanquear claves, bloquear, desbloquear o eliminar usuarios",
        keywords: [
          "crear",
          "cambiar",
          "usuarios",
          "blanquear",
          "crear",
          "contarseña",
          "desbloquear",
        ],
      },
    ],
  },
];

const cobranza = [
  //Falta gestion de recupero de ver detalle o acta virtual
  {
    title: "Gestion de Recupero",
    href: "/dashboard/cobranza/gestionRecupero",
    privil: "502",
    actions: [
      {
        title: "Actualizar datos",
        description: "Control de recupero",
        keywords: ["actualizar", "datos", "control", "recupero"],
      },
    ],
  },
  {
    title: "Carga de actas",
    href: "/dashboard/cobranza/cargaDeActas",
    privil: "504",
    actions: [
      {
        title: "Subida de actas",
        description: "Subida de actas",
        keywords: ["carga", "subida", "actas"],
      },
      {
        title: "Buscar empresa",
        description: "Buscar empresa",
        keywords: ["buscar", "empresa"],
      },
    ],
  },
  {
    title: "Cheque rechazado",
    href: "/dashboard/cobranza/chequeRechazado",
    privil: "506",
    actions: [
      {
        title: "Cheque rechazado",
        description: "Rechazar cheque",
        keywords: ["rechazar", "cheque"],
      },
    ],
  },
  {
    title: "Ver judiciales",
    href: "/dashboard/cobranza/verJudiciales",
    privil: "505",
    actions: [
      {
        title: "Ver judiciales",
        description: "ver empresas con judiciales",
        keywords: ["judiciales", "empresas", "legales"],
      },
    ],
  },
  {
    title: "Carga de convenios",
    href: "/dashboard/cobranza/cargaDeConvenios",
    privil: "509",
    actions: [
      {
        title: "Carga de convenios",
        description: "realizar una carga de convenios a la empresa",
        keywords: ["carga", "empresas", "convenios"],
      },
    ],
  },
  {
    title: "Recibos",
    href: "/dashboard/cobranza/recibos",
    privil: "507",
    actions: [
      {
        title: "Recibos",
        description: "Declarar recibos",
        keywords: ["declarar", "recibos"],
      },
    ],
  },
  {
    title: "Reportes",
    children: [
      {
        title: "Rendicion de cuentas",
        href: "/dashboard/cobranza/reportes/rendicion",
        privil: "508",
        actions: [
          {
            title: "Reportes",
            description: "Reporte de rendicion de cuentas",
            keywords: ["rendicion", "cuentas"],
          },
        ],
      },
      {
        title: "Reporte de deudas por zonas",
        href: "/dashboard/cobranza/reportes/deudas",
        privil: "508",
        actions: [
          {
            title: "Reportes",
            description: "Reporte de deudas por zonas",
            keywords: ["reporte", "deuda", "zonas"],
          },
        ],
      },
    ],
    privil: "508",
  },
];

const prePrestacional = [
  {
    title: "Ingreso",
    privil: "401",
    href: "/dashboard/pre-prestacional/ingreso",
    actions: [
      {
        title: "Ingreso pre prestacional",
        description: "Ingreso pre prestacional",
        keywords: ["ingreso", "nuevo", "prestacion"],
      },
    ],
  },
  {
    title: "Auditoria de solicitudes",
    privil: "402",
    href: "/dashboard/pre-prestacional/auditoriaDeSolicitudes",
    actions: [
      {
        title: "Buscar auditoria de solicitudes",
        description: "Dar de alta a los afiliados nuevos",
        keywords: ["Buscar", "auditoria", "afiliados", "nuevos"],
      },
    ],
  },
];

const actaVirtual = [
  {
    title: "Ver detalles",
    href: "/dashboard/actaVirtual",
    privil: "201",
    actions: [
      {
        title: "Ver acta virtual",
        description: "Muestra el detalle del acta virtual",
        keywords: ["acta", "virtual", "detalles"],
      },
    ],
  },
];

const gerencia = [
  {
    title: "tablero de control",
    href: "/dashboard/gerencia/tableroControl",
    privil: "901",
    actions: [
      {
        title: "Tablero de control",
        description: "Muestra un resumen de la obra social",
        keywords: ["tablero", "control"],
      },
    ],
  },
];

const contaduria = [
  {
    title: "Reportes",
    href: "/dashboard/contaduria/reportes",
    privil: "601",
    actions: [
      {
        title: "Reportes",
        description: "Muestra los reportes de contaduria",
        keywords: [
          "reporte",
          "contaduria",
          "suma",
          "sumarte",
          "subsidios",
          "monotributo",
        ],
      },
    ],
  },
];

const searchOptions = [
  {
    title: "Empadronamiento",
    pages: empadronamiento,
  },
  {
    title: "Pre prestacional",
    pages: prePrestacional,
  },
  {
    title: "Cobranza",
    pages: cobranza,
  },
  {
    title: "Administracion",
    pages: administracion,
  },
  {
    title: "Acta Virtual",
    pages: actaVirtual,
  },
  {
    title: "Gerencia",
    pages: gerencia,
  },
  {
    title: "Contaduria",
    pages: contaduria,
  },
];

export default searchOptions;
