export const top100Films = [
  { label: "The Shawshank Redemption", year: 1994 },
  { label: "The Godfather", year: 1972 },
  { label: "The Godfather: Part II", year: 1974 },
  { label: "The Dark Knight", year: 2008 },
  { label: "12 Angry Men", year: 1957 },
  { label: "Schindler's List", year: 1993 },
  { label: "Pulp Fiction", year: 1994 },
];

export const relacionLaboralABM = [
  { label: "Propio Gremio (1)", db: "PROPIO GREMIO", id: 1 },
  { label: "Traspaso (2)", db: "TRASPASO", id: 2 },
  { label: "Monotributista (3)", db: "MONOTRIBUTISTA", id: 3 },
];

export const relacionLaboral = [
  { label: "Propio Gremio (1)", id: 1 },
  { label: "Traspaso (2)", id: 2 },
  { label: "Monotributista (3)", id: 3 },
];

export const sexo = [
  { label: "Masculino", id: "M" },
  { label: "Femenino", id: "F" },
  { label: "Otros (X)", id: "X" },
];

export const incapacitadoABM = [
  { label: "No incapacitado (0)", id: 0 },
  { label: "Incapacitado (1)", id: 1 },
];

export const incapacitado = [
  { label: "No incapacitado (0)", id: 0 },
  { label: "Incapacitado (1)", id: 1 },
];

export const tipoDomicilioABM = [
  { label: "Domicilio completo (1)", id: 1 },
  { label: "Domicilio rural (2)", id: 2 },
];

export const tipoDomicilio = [
  { label: "Domicilio completo", id: 1 },
  { label: "Domicilio rural", id: 2 },
];

export const tipoBenef = [
  // endpoint
  { label: "0 - Relacion de dependencia", id: 0 },
  { label: "1 - Empleado de monotributo", id: 1 },
  { label: "2 - Jubilado", id: 2 },
  { label: "3 - Adherente", id: 3 },
  { label: "4 - Monostributista autonomo", id: 4 },
  { label: "5 - Servicio domestico", id: 5 },
  { label: "6 - Jubilados españoles", id: 6 },
  { label: "7 - Monotributistas sociales", id: 7 },
  { label: "8 - Beneficiario Fondo de desempleo", id: 8 },
  { label: "9 - Benefec. renatrte", id: 9 },
  { label: "10- Jubilados y pencionados", id: 10 },
  { label: "11- Jubilados y pencionados de otros", id: 11 },
  { label: "12- Relacion dep S/AP Y CONT", id: 12 },
];

export const situacionRevista = [
  //endpoint
  { label: "0 - Recibe haberes regularmente", id: 0 },
  { label: "10- Maternidad", id: 10 },
  { label: "11- Periodo de reserva por enfermedad", id: 11 },
  { label: "12- Periodo de reserva por accidente", id: 12 },
  { label: "13- Desempleo", id: 13 },
  { label: "99- No se conoce situacion", id: 99 },
  { label: "50- Pensionados españoles", id: 50 },
  { label: "51- Pensionados espaloles nuevos fuera", id: 51 },
  { label: "52- Pensionados espaloles mayores a 70 profe.", id: 52 },
  { label: "53- Conyuges españoles", id: 53 },
  { label: "98- Cuit exceptuados TB = 3", id: 98 },
];
