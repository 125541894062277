export const acortarPalabras = (texto) => {
  const palabras = texto.split(" ");

  let resultado = "";

  for (let i = 0; i < palabras.length; i++) {
    const palabra = palabras[i];
    if (resultado.length + palabra.length <= 31) {
      if (palabra.length > 11) {
        resultado += palabra.substring(0, 5);
      } else {
        resultado += palabra;
      }
    } else {
      break;
    }
  }

  return resultado;
};
