import {
  Page,
  View,
  Text,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";

// ----------------------------------------------------------------------

Font.register({
  family: "Poppins",
  fonts: [
    {
      src: `${process.env.PUBLIC_URL}/static/fonts/Poppins/Poppins-Regular.ttf`,
    },
    { src: `${process.env.PUBLIC_URL}/static/fonts/Poppins/Poppins-Bold.ttf` },
  ],
});

const styles = StyleSheet.create({
  col4: { width: "25%" },
  col8: { width: "75%" },
  col6: { width: "50%", marginRight: 4 },
  mb2: { marginBottom: 2 },
  mb8: { marginBottom: 8 },
  mb40: { marginBottom: 40 },
  overline: {
    fontSize: 8,
    marginBottom: 8,
    fontWeight: "bold",
    letterSpacing: 1.2,
    textTransform: "uppercase",
  },
  h3: { fontSize: 16, fontWeight: "bold" },
  h4: { fontSize: 13, fontWeight: "bold" },
  h5: { fontSize: 11, fontWeight: "bold" },
  body1: { fontSize: 10 },
  subtitle1: { fontSize: 9, fontWeight: "bold" },
  subtitle2: { fontSize: 9, fontWeight: "bold", textAlign: "right" },
  alignRight: { textAlign: "right" },
  alignCenter: { textAlign: "center" },
  page: {
    padding: "25px 24px 50px 24px",
    marginBottom: "20px",
    fontSize: 9,
    lineHeight: 1.6,
    backgroundColor: "#fff",
    textTransform: "none",
    fontFamily: "Poppins",
  },
  footer: {
    position: "absolute",
    bottom: 24,
    right: 24,
  },
  gridContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 2,
  },

  noBorder: { paddingTop: 8, paddingBottom: 0, borderBottomWidth: 0 },
  tableCell_1: { width: "40%", textAlign: "left" },
  tableCell_2: { width: "20%", textAlign: "right" },
  tableCell_1Total: { width: "40%", textAlign: "right", fontWeight: "bold" },
  tableCell_2Total: { width: "20%", textAlign: "right", fontWeight: "bold" },
  textDisclaimer: { marginTop: 2, textAlign: "center" },
  detailContainer: { marginBottom: 2, display: "flex", flexDirection: "row" },
  netoContainer: {
    marginTop: 13,
    marginBottom: 16,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  table: {
    display: "table",
    width: "auto",
    marginTop: 20,
    alignItems: "center",
    justifyContent: "center",
  },
  tableHeader: { backgroundColor: "#f2f2f2" },
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderColor: "#DFE3E8",
  },
  tableRowNoBorder: {
    flexDirection: "row",
    borderBottomWidth: 0,
  },
  table2: {
    width: "75%",
  },
  tableCell: {
    padding: 2,
    marginBottom: -3,
    marginRight: -1,
    fontSize: 8,
    width: "auto",
    alignItems: "center",
  },
  tableCellHeader: {
    fontWeight: "bold",
  },
  breakBefore: {
    pageBreakBefore: "always",
  },
  breakAfter: {
    pageBreakAfter: "always",
  },
  bordeDerecho: {
    borderRightWidth: 2,
    borderColor: "black",
  },
  bordeArriba: {
    borderTopWidth: 2,
    borderColor: "black",
    borderBottomWidth: 0,
  },
});

// ----------------------------------------------------------------------

export const PoblacionBeneficiariaPDF = ({ datosPDF, user }) => {
  const dia = new Date();
  const options = {
    timeZone: "America/Buenos_Aires",
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };

  const getDatos = (grupoEt, zona) => {
    return zona.datos.filter((rango) => rango.rangoEtario === grupoEt)[0];
  };

  return (
    <Document>
      {datosPDF.map((item) => (
        <Page size="A4" style={styles.page} orientation="landscape">
          <View fixed>
            <View style={styles.gridContainer}>
              <Image
                source={`${process.env.PUBLIC_URL}/static/img/brands/logo.png`}
                style={{ width: 150, height: 30 }}
              />
              <View
                style={{
                  flex: 1,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text style={styles.h3}>
                  Población beneficiaria - En relación de dependencia
                </Text>
              </View>
              <View style={{ alignItems: "flex-end", flexDirection: "column" }}>
                <Text>
                  {dia.toLocaleDateString()},{" "}
                  {dia.toLocaleTimeString("es-AR", options).slice(0, 5)}
                </Text>
                <Text>
                  {user.name} {user.lastname}
                </Text>
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: 10,
              }}
            >
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Por Categoría, edad y sexo {"\n"}
                Zona {item.codZona} {item.nomZona}
              </Text>
            </View>
          </View>

          <>
            <View style={styles.table}>
              <View style={styles.tableHeader} fixed>
                <View style={styles.tableRow}>
                  <Text
                    style={[
                      styles.tableCell,
                      styles.tableCellHeader,
                      { width: "9.5%", textAlign: "left" },
                    ]}
                  ></Text>
                  <Text
                    style={[
                      styles.tableCell,
                      styles.tableCellHeader,
                      { width: "15%", textAlign: "center" },
                    ]}
                  >
                    TITULARES
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      styles.tableCellHeader,
                      { width: "9.5%", textAlign: "left" },
                    ]}
                  ></Text>
                  <Text
                    style={[
                      styles.tableCell,
                      styles.tableCellHeader,
                      { width: "15%", textAlign: "center" },
                    ]}
                  >
                    FAMILIARES
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      styles.tableCellHeader,
                      { width: "9.5%", textAlign: "left" },
                    ]}
                  ></Text>
                  <Text
                    style={[
                      styles.tableCell,
                      styles.tableCellHeader,
                      { width: "15%", textAlign: "center" },
                    ]}
                  >
                    FAMILIARES Y OTROS {"\n"}A CARGO
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      styles.tableCellHeader,
                      { width: "9.5%", textAlign: "left" },
                    ]}
                  ></Text>
                  <Text
                    style={[
                      styles.tableCell,
                      styles.tableCellHeader,
                      { width: "15%", textAlign: "center" },
                    ]}
                  >
                    TOTAL
                  </Text>
                </View>
                <View style={styles.tableRow}>
                  <Text
                    style={[
                      styles.tableCell,
                      styles.tableCellHeader,
                      styles.bordeDerecho,
                      { width: "7.7%", textAlign: "left" },
                    ]}
                  >
                    Grupo etareo
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      styles.tableCellHeader,
                      { width: "7.7%", textAlign: "center" },
                    ]}
                  >
                    F
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      styles.tableCellHeader,
                      { width: "7.7%", textAlign: "center" },
                    ]}
                  >
                    M
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      styles.tableCellHeader,
                      styles.bordeDerecho,
                      { width: "7.7%", textAlign: "center" },
                    ]}
                  >
                    TOTAL
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      styles.tableCellHeader,
                      { width: "7.7%", textAlign: "center" },
                    ]}
                  >
                    F
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      styles.tableCellHeader,
                      { width: "7.7%", textAlign: "center" },
                    ]}
                  >
                    M
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      styles.tableCellHeader,
                      styles.bordeDerecho,
                      { width: "7.7%", textAlign: "center" },
                    ]}
                  >
                    TOTAL
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      styles.tableCellHeader,
                      { width: "7.7%", textAlign: "center" },
                    ]}
                  >
                    F
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      styles.tableCellHeader,
                      { width: "7.7%", textAlign: "center" },
                    ]}
                  >
                    M
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      styles.tableCellHeader,
                      styles.bordeDerecho,
                      { width: "7.7%", textAlign: "center" },
                    ]}
                  >
                    TOTAL
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      styles.tableCellHeader,
                      { width: "7.7%", textAlign: "center" },
                    ]}
                  >
                    F
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      styles.tableCellHeader,
                      { width: "7.7%", textAlign: "center" },
                    ]}
                  >
                    M
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      styles.tableCellHeader,
                      styles.bordeDerecho,
                      { width: "7.7%", textAlign: "center" },
                    ]}
                  >
                    TOTAL
                  </Text>
                </View>
              </View>
              <View>
                {item.datos.map((item2, index, array) => {
                  const estilo =
                    index === array.length - 1
                      ? styles.tableRowNoBorder
                      : styles.tableRow;

                  return (
                    <View style={estilo} key={item.codZona}>
                      <Text
                        style={[
                          styles.tableCell,
                          styles.bordeDerecho,
                          { width: "7.7%", textAlign: "center" },
                          styles.h5,
                        ]}
                      >
                        {item2.rangoEtario}
                      </Text>
                      <Text
                        style={[
                          styles.tableCell,
                          { width: "7.7%", textAlign: "center" },
                          styles.h5,
                        ]}
                      >
                        {item2.datos?.Titular?.conteoSexo?.F || 0}
                      </Text>
                      <Text
                        style={[
                          styles.tableCell,
                          { width: "7.7%", textAlign: "center" },
                          styles.h5,
                        ]}
                      >
                        {item2.datos?.Titular?.conteoSexo?.M || 0}
                      </Text>
                      <Text
                        style={[
                          styles.tableCell,
                          styles.bordeDerecho,
                          { width: "7.7%", textAlign: "center" },
                          styles.h5,
                        ]}
                      >
                        {item2.datos?.Titular?.conteoSexo?.T || 0}
                      </Text>

                      <Text
                        style={[
                          styles.tableCell,
                          { width: "7.7%", textAlign: "center" },
                          styles.h5,
                        ]}
                      >
                        {item2.datos?.Familiar?.conteoSexo?.F || 0}
                      </Text>
                      <Text
                        style={[
                          styles.tableCell,
                          { width: "7.7%", textAlign: "center" },
                          styles.h5,
                        ]}
                      >
                        {item2.datos?.Familiar?.conteoSexo?.M || 0}
                      </Text>
                      <Text
                        style={[
                          styles.tableCell,
                          styles.bordeDerecho,
                          { width: "7.7%", textAlign: "center" },
                          styles.h5,
                        ]}
                      >
                        {item2.datos?.Familiar?.conteoSexo?.T || 0}
                      </Text>
                      <Text
                        style={[
                          styles.tableCell,
                          { width: "7.7%", textAlign: "center" },
                          styles.h5,
                        ]}
                      >
                        {item2.datos?.aCargo?.conteoSexo?.F || 0}
                      </Text>
                      <Text
                        style={[
                          styles.tableCell,
                          { width: "7.7%", textAlign: "center" },
                          styles.h5,
                        ]}
                      >
                        {item2.datos?.aCargo?.conteoSexo?.M || 0}
                      </Text>
                      <Text
                        style={[
                          styles.tableCell,
                          styles.bordeDerecho,
                          { width: "7.7%", textAlign: "center" },
                          styles.h5,
                        ]}
                      >
                        {item2.datos?.aCargo?.conteoSexo?.T || 0}
                      </Text>
                      <Text
                        style={[
                          styles.tableCell,
                          { width: "7.7%", textAlign: "center" },
                          styles.h5,
                        ]}
                      >
                        {(item2.datos?.Titular?.conteoSexo?.F || 0) +
                          (item2.datos?.Familiar?.conteoSexo?.F || 0) +
                          (item2.datos?.aCargo?.conteoSexo?.F || 0)}
                      </Text>
                      <Text
                        style={[
                          styles.tableCell,
                          { width: "7.7%", textAlign: "center" },
                          styles.h5,
                        ]}
                      >
                        {(item2?.datos?.Titular?.conteoSexo?.M || 0) +
                          (item2?.datos?.Familiar?.conteoSexo?.M || 0) +
                          (item2?.datos?.aCargo?.conteoSexo?.M || 0)}
                      </Text>
                      <Text
                        style={[
                          styles.tableCell,
                          styles.bordeDerecho,
                          { width: "7.7%", textAlign: "center" },
                          styles.h5,
                        ]}
                      >
                        {(item2?.datos?.Titular?.conteoSexo?.T || 0) +
                          (item2?.datos?.Familiar?.conteoSexo?.T || 0) +
                          (item2?.datos?.aCargo?.conteoSexo?.T || 0)}
                      </Text>
                    </View>
                  );
                })}
                <View style={styles.tableRowNoBorder} key={item.codZona + 1}>
                  <Text
                    style={[
                      styles.tableCell,
                      styles.bordeDerecho,
                      styles.bordeArriba,
                      { width: "7.7%", textAlign: "center" },
                      styles.h5,
                    ]}
                  ></Text>
                  <Text
                    style={[
                      styles.tableCell,
                      styles.bordeArriba,
                      { width: "7.7%", textAlign: "center" },
                      styles.h5,
                    ]}
                  >
                    {item.datos.reduce((total, dato) => {
                      if (
                        dato.datos &&
                        dato.datos.Titular &&
                        dato.datos.Titular.conteoSexo
                      ) {
                        // Suma el valor de "F" si existe en el conteoSexo, de lo contrario suma 0
                        return total + (dato.datos.Titular.conteoSexo.F || 0);
                      } else {
                        return total; // Si no hay datos de Titular, simplemente retorna el total sin cambios
                      }
                    }, 0)}
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      styles.bordeArriba,
                      { width: "7.7%", textAlign: "center" },
                      styles.h5,
                    ]}
                  >
                    {item.datos.reduce((total, dato) => {
                      if (
                        dato.datos &&
                        dato.datos.Titular &&
                        dato.datos.Titular.conteoSexo
                      ) {
                        // Suma el valor de "F" si existe en el conteoSexo, de lo contrario suma 0
                        return total + (dato.datos.Titular.conteoSexo.M || 0);
                      } else {
                        return total; // Si no hay datos de Titular, simplemente retorna el total sin cambios
                      }
                    }, 0)}
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      styles.bordeDerecho,
                      styles.bordeArriba,
                      { width: "7.7%", textAlign: "center" },
                      styles.h5,
                    ]}
                  >
                    {item.datos.reduce((total, dato) => {
                      if (
                        dato.datos &&
                        dato.datos.Titular &&
                        dato.datos.Titular.conteoSexo
                      ) {
                        // Suma el valor de "F" si existe en el conteoSexo, de lo contrario suma 0
                        return total + (dato.datos.Titular.conteoSexo.T || 0);
                      } else {
                        return total; // Si no hay datos de Titular, simplemente retorna el total sin cambios
                      }
                    }, 0)}
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      styles.bordeArriba,
                      { width: "7.7%", textAlign: "center" },
                      styles.h5,
                    ]}
                  >
                    {item.datos.reduce((total, dato) => {
                      if (
                        dato.datos &&
                        dato.datos.Familiar &&
                        dato.datos.Familiar.conteoSexo
                      ) {
                        // Suma el valor de "F" si existe en el conteoSexo, de lo contrario suma 0
                        return total + (dato.datos.Familiar.conteoSexo.F || 0);
                      } else {
                        return total; // Si no hay datos de Titular, simplemente retorna el total sin cambios
                      }
                    }, 0)}
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      styles.bordeArriba,
                      { width: "7.7%", textAlign: "center" },
                      styles.h5,
                    ]}
                  >
                    {item.datos.reduce((total, dato) => {
                      if (
                        dato.datos &&
                        dato.datos.Familiar &&
                        dato.datos.Familiar.conteoSexo
                      ) {
                        // Suma el valor de "F" si existe en el conteoSexo, de lo contrario suma 0
                        return total + (dato.datos.Familiar.conteoSexo.M || 0);
                      } else {
                        return total; // Si no hay datos de Titular, simplemente retorna el total sin cambios
                      }
                    }, 0)}
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      styles.bordeDerecho,
                      styles.bordeArriba,
                      { width: "7.7%", textAlign: "center" },
                      styles.h5,
                    ]}
                  >
                    {item.datos.reduce((total, dato) => {
                      if (
                        dato.datos &&
                        dato.datos.Familiar &&
                        dato.datos.Familiar.conteoSexo
                      ) {
                        // Suma el valor de "F" si existe en el conteoSexo, de lo contrario suma 0
                        return total + (dato.datos.Familiar.conteoSexo.T || 0);
                      } else {
                        return total; // Si no hay datos de Titular, simplemente retorna el total sin cambios
                      }
                    }, 0)}
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      styles.bordeArriba,
                      { width: "7.7%", textAlign: "center" },
                      styles.h5,
                    ]}
                  >
                    {item.datos.reduce((total, dato) => {
                      if (
                        dato.datos &&
                        dato.datos.aCargo &&
                        dato.datos.aCargo.conteoSexo
                      ) {
                        // Suma el valor de "F" si existe en el conteoSexo, de lo contrario suma 0
                        return total + (dato.datos.aCargo.conteoSexo.F || 0);
                      } else {
                        return total; // Si no hay datos de Titular, simplemente retorna el total sin cambios
                      }
                    }, 0)}
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      styles.bordeArriba,
                      { width: "7.7%", textAlign: "center" },
                      styles.h5,
                    ]}
                  >
                    {item.datos.reduce((total, dato) => {
                      if (
                        dato.datos &&
                        dato.datos.aCargo &&
                        dato.datos.aCargo.conteoSexo
                      ) {
                        // Suma el valor de "F" si existe en el conteoSexo, de lo contrario suma 0
                        return total + (dato.datos.aCargo.conteoSexo.M || 0);
                      } else {
                        return total; // Si no hay datos de Titular, simplemente retorna el total sin cambios
                      }
                    }, 0)}
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      styles.bordeDerecho,
                      styles.bordeArriba,
                      { width: "7.7%", textAlign: "center" },
                      styles.h5,
                    ]}
                  >
                    {item.datos.reduce((total, dato) => {
                      if (
                        dato.datos &&
                        dato.datos.aCargo &&
                        dato.datos.aCargo.conteoSexo
                      ) {
                        // Suma el valor de "F" si existe en el conteoSexo, de lo contrario suma 0
                        return total + (dato.datos.aCargo.conteoSexo.T || 0);
                      } else {
                        return total; // Si no hay datos de Titular, simplemente retorna el total sin cambios
                      }
                    }, 0)}
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      styles.bordeArriba,
                      { width: "7.7%", textAlign: "center" },
                      styles.h5,
                    ]}
                  >
                    {item.datos.reduce((total, dato) => {
                      if (
                        dato.datos &&
                        dato.datos.Titular &&
                        dato.datos.Titular.conteoSexo
                      ) {
                        // Suma el valor de "F" si existe en el conteoSexo, de lo contrario suma 0
                        return total + (dato.datos.Titular.conteoSexo.F || 0);
                      } else {
                        return total; // Si no hay datos de Titular, simplemente retorna el total sin cambios
                      }
                    }, 0) +
                      item.datos.reduce((total, dato) => {
                        if (
                          dato.datos &&
                          dato.datos.Familiar &&
                          dato.datos.Familiar.conteoSexo
                        ) {
                          // Suma el valor de "F" si existe en el conteoSexo, de lo contrario suma 0
                          return (
                            total + (dato.datos.Familiar.conteoSexo.F || 0)
                          );
                        } else {
                          return total; // Si no hay datos de Titular, simplemente retorna el total sin cambios
                        }
                      }, 0) +
                      item.datos.reduce((total, dato) => {
                        if (
                          dato.datos &&
                          dato.datos.aCargo &&
                          dato.datos.aCargo.conteoSexo
                        ) {
                          // Suma el valor de "F" si existe en el conteoSexo, de lo contrario suma 0
                          return total + (dato.datos.aCargo.conteoSexo.F || 0);
                        } else {
                          return total; // Si no hay datos de Titular, simplemente retorna el total sin cambios
                        }
                      }, 0)}
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      styles.bordeArriba,
                      { width: "7.7%", textAlign: "center" },
                      styles.h5,
                    ]}
                  >
                    {item.datos.reduce((total, dato) => {
                      if (
                        dato.datos &&
                        dato.datos.Titular &&
                        dato.datos.Titular.conteoSexo
                      ) {
                        // Suma el valor de "F" si existe en el conteoSexo, de lo contrario suma 0
                        return total + (dato.datos.Titular.conteoSexo.M || 0);
                      } else {
                        return total; // Si no hay datos de Titular, simplemente retorna el total sin cambios
                      }
                    }, 0) +
                      item.datos.reduce((total, dato) => {
                        if (
                          dato.datos &&
                          dato.datos.Familiar &&
                          dato.datos.Familiar.conteoSexo
                        ) {
                          // Suma el valor de "F" si existe en el conteoSexo, de lo contrario suma 0
                          return (
                            total + (dato.datos.Familiar.conteoSexo.M || 0)
                          );
                        } else {
                          return total; // Si no hay datos de Titular, simplemente retorna el total sin cambios
                        }
                      }, 0) +
                      item.datos.reduce((total, dato) => {
                        if (
                          dato.datos &&
                          dato.datos.aCargo &&
                          dato.datos.aCargo.conteoSexo
                        ) {
                          // Suma el valor de "F" si existe en el conteoSexo, de lo contrario suma 0
                          return total + (dato.datos.aCargo.conteoSexo.M || 0);
                        } else {
                          return total; // Si no hay datos de Titular, simplemente retorna el total sin cambios
                        }
                      }, 0)}
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      styles.bordeDerecho,
                      styles.bordeArriba,
                      { width: "7.7%", textAlign: "center" },
                      styles.h5,
                    ]}
                  >
                    {item.datos.reduce((total, dato) => {
                      if (
                        dato.datos &&
                        dato.datos.Titular &&
                        dato.datos.Titular.conteoSexo
                      ) {
                        // Suma el valor de "F" si existe en el conteoSexo, de lo contrario suma 0
                        return total + (dato.datos.Titular.conteoSexo.T || 0);
                      } else {
                        return total; // Si no hay datos de Titular, simplemente retorna el total sin cambios
                      }
                    }, 0) +
                      item.datos.reduce((total, dato) => {
                        if (
                          dato.datos &&
                          dato.datos.Familiar &&
                          dato.datos.Familiar.conteoSexo
                        ) {
                          // Suma el valor de "F" si existe en el conteoSexo, de lo contrario suma 0
                          return (
                            total + (dato.datos.Familiar.conteoSexo.T || 0)
                          );
                        } else {
                          return total; // Si no hay datos de Titular, simplemente retorna el total sin cambios
                        }
                      }, 0) +
                      item.datos.reduce((total, dato) => {
                        if (
                          dato.datos &&
                          dato.datos.aCargo &&
                          dato.datos.aCargo.conteoSexo
                        ) {
                          // Suma el valor de "F" si existe en el conteoSexo, de lo contrario suma 0
                          return total + (dato.datos.aCargo.conteoSexo.T || 0);
                        } else {
                          return total; // Si no hay datos de Titular, simplemente retorna el total sin cambios
                        }
                      }, 0)}
                  </Text>
                </View>
              </View>
            </View>
          </>

          <View style={[styles.footer, { paddingTop: "10px" }]} fixed>
            <Text
              render={({ pageNumber, totalPages }) =>
                `Página ${pageNumber} de ${totalPages}`
              }
            />
          </View>
        </Page>
      ))}
    </Document>
  );
};
