import { useState, useEffect } from "react";
import {
  Card,
  Grid,
  Typography,
  Box,
  Container,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import hosteo from "../../../utils/hosteo.js";
import axios from "../../../utils/axios.js";
import * as XLSX from "../../../../node_modules/xlsx/xlsx.js";
import useAuth from "../../../hooks/useAuth.js";
import { saveAs } from "file-saver";
import { HelperText } from "../HelperText.jsx";
import Radio from "@mui/material/Radio";
import Button from "@mui/material/Button";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { DataGrid } from "@mui/x-data-grid";
import { TotalesPropAdeMonoPDF } from "./pdf/TotalesPropAdeMonoPDF.jsx";
import { pdf } from "@react-pdf/renderer";
import { LoadingButton } from "@mui/lab";
import { DatePicker } from "@mui/x-date-pickers";
import es from "date-fns/locale/es";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { fDate } from "../../../utils/formatNumber.js";
import { MonotributistasExtConNacio } from "./MonotributistasExtConNacio.jsx";
import DownloadIcon from "@mui/icons-material/Download.js";

export default function TotalesPropAdeMonoCard() {
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState();
  const [datosNacio, setDatosNacio] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [adherente, setAdherente] = useState("propios");
  const [rowDataGrid, setRowDataGrid] = useState();
  const [error, setError] = useState(false);
  const [fecha, setFecha] = useState();
  const { user } = useAuth();
  const [zonas, setZonas] = useState();

  //columna datagrid
  const columns = [
    {
      field: "cZona",
      headerName: "C.Zona",
      flex: 1,
      minWidth: 140,
      editable: false,
    },
    {
      field: "nZona",
      headerName: "N.Zona",
      flex: 1,
      minWidth: 140,
      editable: false,
    },
    {
      field: "titular",
      headerName: "Titular",
      flex: 1,
      minWidth: 140,
      valueFormatter: (params) => checkZero(params.value),
      editable: false,
    },
    {
      field: "conyuge",
      headerName: "Conyuge",
      flex: 1,
      minWidth: 140,
      valueFormatter: (params) => checkZero(params.value),
      editable: false,
    },
    {
      field: "concubino",
      headerName: "Concubino",
      flex: 1,
      minWidth: 140,
      valueFormatter: (params) => checkZero(params.value),
      editable: false,
    },
    {
      field: "menor21",
      headerName: "Hijo menor a 21",
      flex: 1,
      valueFormatter: (params) => checkZero(params.value),
      minWidth: 140,
      editable: false,
    },
    {
      field: "entre21a25",
      headerName: "Hijo soltero entre 21-25",
      flex: 1,
      valueFormatter: (params) => checkZero(params.value),
      minWidth: 140,
      editable: false,
    },
    {
      field: "HijSolConyMenor21",
      headerName: "Hij sol cony menor a 21",
      flex: 1,
      valueFormatter: (params) => checkZero(params.value),
      minWidth: 140,
      editable: false,
    },
    {
      field: "HSoConEsEntre21a25",
      headerName: "H. so. con es. entre 21 y 25",
      valueFormatter: (params) => checkZero(params.value),
      flex: 1,
      minWidth: 140,
      editable: false,
    },
    {
      field: "menortutela",
      headerName: "Menor Tutela",
      valueFormatter: (params) => checkZero(params.value),
      flex: 1,
      minWidth: 140,
      editable: false,
    },
    {
      field: "famacargo",
      headerName: "Fam. a cargo",
      valueFormatter: (params) => checkZero(params.value),
      flex: 1,
      minWidth: 140,
      editable: false,
    },
    {
      field: "discmayor25",
      headerName: "Mayor de 25 discapacitado",
      valueFormatter: (params) => checkZero(params.value),
      flex: 1,
      minWidth: 140,
      editable: false,
    },
    adherente === "propios" && {
      field: "tZona",
      headerName: "T. Zona",
      valueFormatter: (params) => checkZero(params.value),
      flex: 1,
      minWidth: 140,
      editable: false,
    },
  ];

  const columnsNacionalidad = [
    {
      field: "nacionalidad",
      headerName: "Nacionalidad",
      flex: 1,
      minWidth: 140,
      editable: false,
    },

    {
      field: "titular",
      headerName: "Titular",
      flex: 1,
      minWidth: 140,
      valueFormatter: (params) => checkZero(params.value),
      editable: false,
    },
    {
      field: "conyuge",
      headerName: "Conyuge",
      flex: 1,
      minWidth: 140,
      valueFormatter: (params) => checkZero(params.value),
      editable: false,
    },
    {
      field: "concubino",
      headerName: "Concubino",
      flex: 1,
      minWidth: 140,
      valueFormatter: (params) => checkZero(params.value),
      editable: false,
    },
    {
      field: "menor21",
      headerName: "Hijo menor a 21",
      flex: 1,
      valueFormatter: (params) => checkZero(params.value),
      minWidth: 140,
      editable: false,
    },
    {
      field: "entre21a25",
      headerName: "Hijo soltero entre 21-25",
      flex: 1,
      valueFormatter: (params) => checkZero(params.value),
      minWidth: 140,
      editable: false,
    },
    {
      field: "HijSolConyMenor21",
      headerName: "Hij sol cony menor a 21",
      flex: 1,
      valueFormatter: (params) => checkZero(params.value),
      minWidth: 140,
      editable: false,
    },
    {
      field: "HSoConEsEntre21a25",
      headerName: "H. so. con es. entre 21 y 25",
      valueFormatter: (params) => checkZero(params.value),
      flex: 1,
      minWidth: 140,
      editable: false,
    },
    {
      field: "menortutela",
      headerName: "Menor Tutela",
      valueFormatter: (params) => checkZero(params.value),
      flex: 1,
      minWidth: 140,
      editable: false,
    },
    {
      field: "famacargo",
      headerName: "Fam. a cargo",
      valueFormatter: (params) => checkZero(params.value),
      flex: 1,
      minWidth: 140,
      editable: false,
    },
    {
      field: "discmayor25",
      headerName: "Mayor de 25 discapacitado",
      valueFormatter: (params) => checkZero(params.value),
      flex: 1,
      minWidth: 140,
      editable: false,
    },
    adherente === "propios" && {
      field: "tZona",
      headerName: "T. Zona",
      valueFormatter: (params) => checkZero(params.value),
      flex: 1,
      minWidth: 140,
      editable: false,
    },
  ];

  const checkZero = (number) => {
    if (number) {
      return number;
    } else {
      return 0;
    }
  };
  const columnsXLSX = [
    adherente !== "extranjeros_con_nacionalidad" ? "cZona" : "",
    adherente !== "extranjeros_con_nacionalidad" ? "nZona" : "nacionalidad",
    "titular",
    "conyuge",
    "concubino",
    "menor21",
    "entre21a25",
    "HijSolConyMenor21",
    "HSoConEsEntre21a25",
    "menortutela",
    "famacargo",
    "discmayor25",
    adherente === "propios" ? "tZona" : "",
  ];
  //-------------------Descarga PDF-------------------------------------
  const handleDownload = async () => {
    const blob = await pdf(
      <TotalesPropAdeMonoPDF datosPDF={rowDataGrid} user={user} />
    ).toBlob();

    saveAs(blob, `TotPropAdMono - ${new Date().toLocaleDateString()}`);
  };
  //-------------------Descarga Excel--------------------------------------
  const handleDownloadExcel = () => {
    const data = rowDataGrid.map((item) =>
      columnsXLSX.map((column) => item[column])
    );
    const ws = XLSX.utils.aoa_to_sheet([
      columnsXLSX.map((column) => column),
      ...data,
    ]);
    const colWidths = columnsXLSX.map((column) => ({
      wch: column === "address" ? 30 : 18,
    }));
    ws["!cols"] = colWidths;
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, `Tot prop adh y monot`);
    XLSX.writeFile(wb, `TotPropAdMono.xlsx`);
  };

  //--------------------Traida de datos---------------------------------------------------
  //si adherente es tal, llamar a otro endpoint
  const getPropiosTotales = async () => {
    try {
      if (fecha) {
        setIsLoading(true);
        const response = await axios.post(
          adherente === "propios"
            ? `${hosteo}/api/empadronamiento/beneficiarios/getPropiosAdherentesMono`
            : `${hosteo}/api/empadronamiento/beneficiarios/getExtranjerosMono`,
          { fecha: fDate(fecha) }
        );
        setData(response.data);
        setZonas(response.data.zonas);
      } else {
        setError(true);
      }
    } catch (error) {
      enqueueSnackbar(error.message || "No tienes conexión a internet", {
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    // si adherente es igual a propios ademas de data
    if (data) {
      wrapDataArrays();
    }
  }, [data]);
  //---------------------------------------------------------------------

  const array = [];
  // junta los datos de los array que coinciden nZona
  const wrapDataArrays = () => {
    for (let prop in data) {
      if (prop !== "zonas") {
        array.push(...asignamientoDeZona(data[prop]));
      }
    }
    const mergedObjects = {};
    array.forEach((obj, index) => {
      if (!mergedObjects[obj.nZona]) {
        mergedObjects[obj.nZona] = { ...obj, id: index };
      } else {
        mergedObjects[obj.nZona] = {
          ...mergedObjects[obj.nZona],
          ...obj,
          id: index,
        };
      }
    });

    const finalArray = Object.values(mergedObjects);
    // creacion del total (tZona) si es igual a propios
    if (adherente === "propios") {
      finalArray.forEach((objeto) => {
        let keys = Object.keys(objeto);
        let total = 0;

        keys.forEach((key) => {
          if (key !== "nZona" && key !== "cZona" && key !== "id") {
            total += parseInt(objeto[key]);
          }
        });

        objeto["tZona"] = total;
      });
    }

    setRowDataGrid(finalArray);
  };
  const asignamientoDeZona = (datos) => {
    const nuevosDatos = datos.map((element) => {
      const codZona = element.zonaCod;
      return {
        nZona: codZona
          ? zonas.find((zona) => zona?.codigo === codZona)?.zona
          : "Capital federal",
        cZona: element.zonaCod ? element.zonaCod : "1-199",
        [convertNumberToHeader(element)]: element.total,
      };
    });
    return nuevosDatos;
  };

  const convertNumberToHeader = (element) => {
    switch (element.paren) {
      case "1":
        return "conyuge";
      case "2":
        return "concubino";
      case "3":
        return "menor21";
      case "4":
        return "entre21a25";
      case "5":
        return "HijSolConyMenor21";
      case "6":
        return "HSoConEsEntre21a25";
      case "7":
        return "menortutela";
      case "8":
        return "famacargo";
      case "9":
        return "discmayor25";
      case "20":
        return "hijoMenor21Disc";
      case "21":
        return "hijoSolt21a25Disc";
      case "22":
        return "menorBajoGuardaOTutelaDisc";
      case "23":
        return "conyugeDisc";
      case "24":
        return "concubinoDisc";
      case "25":
        return "familiarACargoDisc";
      default:
        return "titular";
    }
  };
  // extranjeros con nacionalidad
  const getPropiosTotales2 = async () => {
    try {
      if (fecha) {
        setIsLoading(true);
        const response = await axios.post(
          `${hosteo}/api/empadronamiento/beneficiarios/getExtranjerosNacio`,
          { fecha: fDate(fecha) }
        );
        setDatosNacio(response.data);
      } else {
        setError(true);
      }
    } catch (error) {
      enqueueSnackbar(error.message || "No tienes conexión a internet", {
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  //extranjeros con nacionalidad
  const separarPorNacionalidad = () => {
    var resultado = [];
    datosNacio.familiares.forEach((objeto, index) => {
      const nacionalidad = objeto?.nacional?.trim();
      if (!resultado[nacionalidad]) {
        resultado[nacionalidad] = [];
      }
      resultado[nacionalidad].push(objeto);
    });
    const wrapData = datosNacio.titulares.reduce((acc, obj) => {
      const nacionalidad = obj?.nacional?.trim();
      if (!resultado[nacionalidad]) {
        resultado[nacionalidad] = [];
      }
      resultado[nacionalidad].push(obj);
      return resultado;
    }, []);

    var final = [];
    for (const nacionalidad in wrapData) {
      if (wrapData.hasOwnProperty(nacionalidad)) {
        final = [...final, armarNacion(wrapData[nacionalidad], nacionalidad)];
      }
    }
    return final;
  };

  const armarNacion = (nacion, nacionalidad) => {
    var nuevaNacion = {
      nacionalidad,
      titular: 0,
      conyuge: 0,
      concubino: 0,
      menor21: 0, //h21: 0,
      entre21a25: 0,
      HijSolConyMenor21: 0,
      HSoConEsEntre21a25: 0,
      menortutela: 0,
      famacargo: 0,
      discmayor25: 0,
      hijoMenor21Disc: 0,
      hijoSolt21a25Disc: 0,
      menorBajoGuardaOTutelaDisc: 0,
      conyugeDisc: 0,
      concubinoDisc: 0,
      familiarACargoDisc: 0,
    };
    nacion.forEach((benef) => {
      if (!benef.paren) {
        nuevaNacion = { ...nuevaNacion, titular: Number(benef?.cant) };
      } else {
        nuevaNacion = sumarCant(benef?.paren, Number(benef?.cant), nuevaNacion);
      }
    });

    return nuevaNacion;
  };

  useEffect(() => {
    if (datosNacio && adherente) {
      const rowsWithId =
        separarPorNacionalidad(datosNacio) &&
        separarPorNacionalidad(datosNacio)?.map((item, index) => ({
          ...item,
          id: index,
        }));
      setRowDataGrid(rowsWithId);
    }
  }, [separarPorNacionalidad]);

  const sumarCant = (paren, cant, nacion) => {
    switch (paren) {
      case "1":
        return { ...nacion, conyuge: Number(nacion?.conyuge) + Number(cant) };
      case "2":
        return {
          ...nacion,
          concubino: Number(nacion?.concubino) + Number(cant),
        };
      case "3":
        return { ...nacion, menor21: Number(nacion?.menor21) + Number(cant) };
      case "4":
        return {
          ...nacion,
          entre21a25: Number(nacion?.entre21a25) + Number(cant),
        };
      case "5":
        return {
          ...nacion,
          HijSolConyMenor21: Number(nacion?.HijSolConyMenor21) + Number(cant),
        };
      case "6":
        return {
          ...nacion,
          HSoConEsEntre21a25: Number(nacion?.HSoConEsEntre21a25) + Number(cant),
        };
      case "7":
        return {
          ...nacion,
          menortutela: Number(nacion?.menortutela) + Number(cant),
        };
      case "8":
        return {
          ...nacion,
          famacargo: Number(nacion?.famacargo) + Number(cant),
        };
      case "9":
        return {
          ...nacion,
          discmayor25: Number(nacion?.discmayor25) + Number(cant),
        };
      case "20":
        return {
          ...nacion,
          hijoMenor21Disc: Number(nacion?.hijoMenor21Disc) + Number(cant),
        };
      case "21":
        return {
          ...nacion,
          hijoSolt21a25Disc: Number(nacion?.hijoSolt21a25Disc) + Number(cant),
        };
      case "22":
        return {
          ...nacion,
          menorBajoGuardaOTutelaDisc:
            Number(nacion?.menorBajoGuardaOTutelaDisc) + Number(cant),
        };
      case "23":
        return {
          ...nacion,
          conyugeDisc: Number(nacion?.conyugeDisc) + Number(cant),
        };
      case "24":
        return {
          ...nacion,
          concubinoDisc: Number(nacion?.concubinoDisc) + Number(cant),
        };
      case "25":
        return {
          ...nacion,
          familiarACargoDisc: Number(nacion?.familiarACargoDisc) + Number(cant),
        };
      default:
        return "";
    }
  };

  return (
    <>
      <HelperText
        message={
          "Cantidad de adherentes y monotributistas por zona o nacionalidad, se puede corroborar por propios, extranjeros o extranjeros con nacionalidad"
        }
      />

      <>
        <Grid container justifyContent="center" spacing={3} marginTop={2}>
          <Grid item xs={12} sm={12} md={10} lg={8} xl={6}>
            <Card sx={{ padding: 10 }}>
              <Grid container spacing={2}>
                <Grid item md="auto" sm={12}>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      defaultValue="propios"
                      onChange={(e) => setAdherente(e.target.value)}
                    >
                      <FormControlLabel
                        value="propios"
                        control={<Radio />}
                        disabled={isLoading}
                        label="Propios"
                      />
                      <FormControlLabel
                        value="extranjeros"
                        control={<Radio />}
                        disabled={isLoading}
                        label="Extranjeros"
                      />
                      <FormControlLabel
                        value="extranjeros_con_nacionalidad"
                        control={<Radio />}
                        disabled={isLoading}
                        label="Extranjeros con nacionalidad"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item md={3} sm={11} xs={10}>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={es}
                  >
                    <DatePicker
                      label="Buscar..."
                      disabled={isLoading}
                      maxDate={new Date()}
                      slotProps={{
                        textField: {
                          error: error,
                          helperText: error ? "debes incorporar una fecha" : "",
                        },
                      }}
                      renderInput={(params) => (
                        <TextField fullWidth {...params} />
                      )}
                      error={error}
                      sx={{ width: "100%" }}
                      onChange={(e) => {
                        setError(false);
                        setFecha(e);
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item md={1} sm={1} xs={2} my="auto">
                  <LoadingButton
                    variant="contained"
                    onClick={
                      adherente === "extranjeros_con_nacionalidad"
                        ? getPropiosTotales2
                        : getPropiosTotales
                    }
                    loading={isLoading}
                  >
                    Buscar
                  </LoadingButton>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
        {rowDataGrid ? (
          <>
            <Grid container justifyContent="center" spacing={3} marginTop={2}>
              <Grid item>
                {datosNacio && adherente === "extranjeros_con_nacionalidad" ? (
                  <MonotributistasExtConNacio datos={rowDataGrid} user={user} />
                ) : (
                  <Button
                    sx={{ height: "100%" }}
                    variant="contained"
                    startIcon={<DownloadIcon />}
                    color="error"
                    onClick={handleDownload}
                  >
                    PDF
                  </Button>
                )}
              </Grid>
              <Grid item>
                <Button
                  sx={{ height: "100%" }}
                  variant="contained"
                  startIcon={<DownloadIcon />}
                  color="success"
                  onClick={handleDownloadExcel}
                >
                  EXCEL
                </Button>
              </Grid>
            </Grid>

            <Box justifyContent="center" spacing={3} marginTop={2}>
              <Container>
                <Card sx={{ padding: 5 }}>
                  <DataGrid
                    rows={rowDataGrid}
                    columns={
                      adherente === "extranjeros_con_nacionalidad"
                        ? columnsNacionalidad
                        : columns
                    }
                    disableRowSelectionOnClick
                    autoHeight
                    initialState={{
                      pagination: {
                        paginationModel: {
                          pageSize: 5,
                        },
                      },
                    }}
                    pageSizeOptions={[5, 10, 25, 100]}
                  />
                </Card>
              </Container>
            </Box>
          </>
        ) : data === null ? (
          <Grid container justifyContent="center" mt={5}>
            <Typography variant="h3">No se encontraron datos</Typography>
          </Grid>
        ) : (
          ""
        )}
      </>
    </>
  );
}
