import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Card,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import CustomLabel from "../../../theme/CustomLabel";
import { useSnackbar } from "notistack";
import DownloadIcon from "@mui/icons-material/Download";
import axios from "../../../utils/axios";
import hosteo from "../../../utils/hosteo";
import { ModalBeneficiarios } from "../EmpReduc/ModalBeneficiarios";
import { DataGrid, esES } from "@mui/x-data-grid";
import { MovBenefPDF } from "./pdf/MovBenefPDF";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import useAuth from "../../../hooks/useAuth.js";
import * as XLSX from "../../../../node_modules/xlsx/xlsx.js";
import { BuscadorTabla } from "../../BuscadorTabla.jsx";
import { HelperText } from "../HelperText.jsx";

const columns = [
  {
    field: "fcambio",
    headerName: "Fecha",
    flex: 1,
    minWidth: 160,
    editable: false,
  },
  {
    field: "tcambio",
    headerName: "Evento",
    minWidth: 160,
    editable: false,
  },
  {
    field: "detalle",
    headerName: "Motivo",
    valueFormatter: (params) => (!params.value ? "-" : params.value),
    flex: 1,
    width: 150,
    editable: false,
  },
  {
    field: "usuario",
    valueFormatter: (params) => checkUser(params.value),
    headerName: "Usuario",
    width: 150,
    editable: false,
  },
];

function checkUser(user) {
  if (!user) {
    return "Automatico";
  } else {
    return user;
  }
}

export default function ReduccionesDeBenef() {
  const [dato, setDato] = useState("");
  const [datosEmpresa, setDatosEmpresa] = useState([]);
  const [type, setType] = useState("cuil");
  const [loading, setLoading] = useState(false);
  const [beneficiario, setBeneficiario] = useState([]);
  const [filtrado, setFiltrado] = useState([]);
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const { user } = useAuth();

  const { enqueueSnackbar } = useSnackbar();

  const handleConsultar = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${hosteo}/api/empadronamiento/beneficiarios/getMovimientosDeBenef`,
        {
          id: dato,
          type,
        }
      );
      setLoading(false);
      if (response.data.beneficiarios?.length > 0) {
        setRows(response.data.beneficiarios);
        setOpen(true);
      } else {
        setBeneficiario(response.data.beneficiario);
        setDatosEmpresa(
          response.data.movimientos.map((element, index) => ({
            ...element,
            id: index + 1,
          }))
        );
        setFiltrado(
          response.data.movimientos.map((element, index) => ({
            ...element,
            id: index + 1,
          }))
        );
      }
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(error.message || "No hay conexion a internet", {
        variant: "error",
      });
    }
  };

  const handleSearch = async (cuil) => {
    try {
      setLoading(true);
      setOpen(false);
      const response = await axios.post(
        `${hosteo}/api/empadronamiento/beneficiarios/getMovimientosDeBenef`,
        {
          id: cuil,
          type: "cuil",
        }
      );
      setLoading(false);
      setBeneficiario(response.data.beneficiario);
      setDatosEmpresa(
        response.data.movimientos.map((element, index) => ({
          ...element,
          id: index + 1,
        }))
      );
      setFiltrado(
        response.data.movimientos.map((element, index) => ({
          ...element,
          id: index + 1,
        }))
      );
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(error.message || "No hay conexion a internet", {
        variant: "error",
      });
    }
  };

  const handlePaginationChange = (params) => {
    if (params.pageSize !== pageSize) {
      setPageSize(params.pageSize);
      setCurrentPage(0);
    } else {
      setCurrentPage(params.page);
    }
  };

  const generateAndDownloadExcel = () => {
    let nombre = "Movimiento de beneficiarios";

    const workbook = XLSX.utils.book_new();

    // Crea una nueva hoja de cálculo con datos
    const dia = new Date();
    const options = {
      timeZone: "America/Buenos_Aires",
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };

    // Encabezado original
    const encabezado = [
      [`Reporte de ${nombre}`],
      [
        `Generado a las ${dia
          .toLocaleTimeString("es-AR", options)
          .slice(0, 5)}hs del ${dia.toLocaleDateString()}`,
      ],
      [""],
    ];

    // Títulos de las columnas
    const columnTitles = ["Fecha", "Evento", "Motivo"];

    // Unir encabezado original con nuevos títulos de columna
    const datosConTitulos = [...encabezado, columnTitles];

    // Agregar datos a la hoja de cálculo
    datosEmpresa.forEach((item) => {
      const rowData = [item.fcambio, item.tcambio, item.detalle];
      datosConTitulos.push(rowData);
    });

    const worksheet = XLSX.utils.aoa_to_sheet(datosConTitulos);
    XLSX.utils.book_append_sheet(workbook, worksheet, nombre);
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${nombre}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const generateAndDownloadPDF = async () => {
    const blob = await pdf(
      <MovBenefPDF
        datosPDF={datosEmpresa}
        beneficiario={beneficiario}
        user={user}
      />
    ).toBlob();
    saveAs(
      blob,
      `Movimiento de titulares - ${new Date().toLocaleDateString()}`
    );
  };

  return (
    <Card
      sx={{
        padding: 3,
      }}
    >
      <Box sx={{ my: 2 }}>
        <HelperText
          message={"Permite ver toda las novedades del beneficiario"}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <TextField
            disabled={loading}
            type={type === "nombre" ? "text" : "number"}
            variant="outlined"
            label={[<CustomLabel>{type.toUpperCase()}</CustomLabel>]}
            onChange={(e) => {
              setDato(e.target.value);
            }}
          />
          <FormControl>
            <FormLabel
              id="demo-radio-buttons-group-label"
              sx={{ textAlign: "center" }}
            >
              Modo de búsqueda de beneficiario
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={type}
              onChange={(e) => {
                setType(e.target.value);
              }}
              name="radio-buttons-group"
            >
              <FormControlLabel
                disabled={loading}
                value="cuil"
                control={<Radio />}
                label="CUIL"
              />
              <FormControlLabel
                disabled={loading}
                value="documento"
                control={<Radio />}
                label="Número de documento"
              />
              <FormControlLabel
                disabled={loading}
                value="nombre"
                control={<Radio />}
                label="Nombre parcial"
              />
            </RadioGroup>
          </FormControl>
        </Box>

        <LoadingButton
          variant="contained"
          onClick={handleConsultar}
          loading={loading}
          sx={{ mb: 2 }}
        >
          Consultar
        </LoadingButton>
      </Box>
      <ModalBeneficiarios
        open={open}
        handleSearch={handleSearch}
        datos={rows}
        handleClose={() => setOpen(false)}
      />
      {datosEmpresa.length > 0 && (
        <>
          <Box
            sx={{ display: "flex", justifyContent: "center", my: 5, gap: 2 }}
          >
            <LoadingButton
              variant="contained"
              color="success"
              onClick={generateAndDownloadExcel}
              startIcon={<DownloadIcon />}
            >
              EXCEL
            </LoadingButton>
            <LoadingButton
              variant="contained"
              color="error"
              onClick={generateAndDownloadPDF}
              startIcon={<DownloadIcon />}
            >
              PDF
            </LoadingButton>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <BuscadorTabla datos={datosEmpresa} setter={setFiltrado} />
          </Box>
          <DataGrid
            autoHeight
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            disableRowSelectionOnClick
            onPaginationModelChange={(row) => handlePaginationChange(row)}
            onPageChange={(newPage) => setCurrentPage(newPage)}
            paginationModel={{
              page: currentPage,
              pageSize: pageSize,
            }}
            rows={filtrado}
            columns={columns}
            initialState={{
              pagination: { paginationModel: { page: 0, pageSize: 10 } },
            }}
            pageSizeOptions={[10, 25, 50, 100]}
          />
        </>
      )}
    </Card>
  );
}
