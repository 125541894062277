import Cabecera from "../../../../components/Cabecera";
import SinPresentacion from "../../../../components/Empadronamiento/Empresas/Reportes/SinPresentacionDdjj";

const items = [
  { linkTo: "/dashboard/home", nombre: "Home" },
  { linkTo: "/dashboard/empadronamiento", nombre: "Empadronamiento" },
  {
    linkTo: "/dashboard/empadronamiento/empresas/reportes",
    nombre: "Reportes de empresas",
  },
];

export default function SinPresentacionDdjj() {
  return (
    <>
      <Cabecera
        title="Empresas que no presentaron declaraciones juradas"
        items={items}
        divider={false}
        helmet="Empadronamiento"
      />
      <SinPresentacion />
    </>
  );
}
