import {
  Page,
  View,
  Text,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";

// ----------------------------------------------------------------------

Font.register({
  family: "Poppins",
  fonts: [
    {
      src: `${process.env.PUBLIC_URL}/static/fonts/Poppins/Poppins-Regular.ttf`,
    },
    { src: `${process.env.PUBLIC_URL}/static/fonts/Poppins/Poppins-Bold.ttf` },
  ],
});

const styles = StyleSheet.create({
  col4: { width: "25%" },
  col8: { width: "75%" },
  col6: { width: "50%", marginRight: 4 },
  mb2: { marginBottom: 2 },
  mb8: { marginBottom: 8 },
  mb40: { marginBottom: 40 },
  overline: {
    fontSize: 8,
    marginBottom: 8,
    fontWeight: "bold",
    letterSpacing: 1.2,
    textTransform: "uppercase",
  },
  h3: { fontSize: 16, fontWeight: "bold" },
  h4: { fontSize: 13, fontWeight: "bold" },
  body1: { fontSize: 10 },
  subtitle1: { fontSize: 9, fontWeight: "bold" },
  subtitle2: { fontSize: 9, fontWeight: "bold", textAlign: "right" },
  alignRight: { textAlign: "right" },
  alignCenter: { textAlign: "center" },
  page: {
    padding: "40px 24px 50px 24px",
    marginBottom: "20px",
    fontSize: 9,
    lineHeight: 1.6,
    backgroundColor: "#fff",
    textTransform: "none",
    fontFamily: "Poppins",
  },
  footer: {
    position: "absolute",
    bottom: 24,
    right: 24,
  },
  gridContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 20,
  },

  noBorder: { paddingTop: 8, paddingBottom: 0, borderBottomWidth: 0 },
  tableCell_1: { width: "40%", textAlign: "left" },
  tableCell_2: { width: "20%", textAlign: "right" },
  tableCell_1Total: { width: "40%", textAlign: "right", fontWeight: "bold" },
  tableCell_2Total: { width: "20%", textAlign: "right", fontWeight: "bold" },
  textDisclaimer: { marginTop: 2, textAlign: "center" },
  detailContainer: { marginBottom: 2, display: "flex", flexDirection: "row" },
  netoContainer: {
    marginTop: 13,
    marginBottom: 16,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  table: {
    display: "table",
    width: "auto",
    marginBottom: 10,
    alignItems: "center",
    justifyContent: "center",
  },
  tableHeader: { backgroundColor: "#f2f2f2" },
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderColor: "#DFE3E8",
  },
  table2: {
    width: "75%",
  },
  tableCell: {
    padding: 2,
    fontSize: 8,
    width: "auto",
  },
  tableCellHeader: {
    fontWeight: "bold",
  },
});

// ----------------------------------------------------------------------

export const TotalesPropAdeMonoPDF = ({ datosPDF, user }) => {
  const dia = new Date();
  const options = {
    timeZone: "America/Buenos_Aires",
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };

  return (
    <Document>
      <Page size="A4" style={styles.page} orientation="landscape" wrap>
        <View style={styles.gridContainer} fixed>
          <Image
            source={`${process.env.PUBLIC_URL}/static/img/brands/logo.png`}
            style={{ width: 150, height: 30 }}
          />
          <View
            style={{ flex: 1, alignItems: "center", justifyContent: "center" }}
          >
            <Text style={styles.h3}>
              Totales propios adherentes y monotributistas
            </Text>
          </View>
          <View style={{ alignItems: "flex-end", flexDirection: "column" }}>
            <Text>
              {dia.toLocaleDateString()},{" "}
              {dia.toLocaleTimeString("es-AR", options).slice(0, 5)}
            </Text>
            <Text>
              {user?.name} {user?.lastname}
            </Text>
          </View>
        </View>

        <>
          <View style={styles.table}>
            <View style={styles.tableHeader} fixed>
              <View style={styles.tableRow}>
                <Text
                  style={[
                    styles.tableCell,
                    styles.tableCellHeader,
                    { width: "5%", textAlign: "right" },
                  ]}
                >
                  C.Zona
                </Text>
                <Text
                  style={[
                    styles.tableCell,
                    styles.tableCellHeader,
                    { width: "12.5%", textAlign: "right" },
                  ]}
                >
                  N.Zona
                </Text>
                <Text
                  style={[
                    styles.tableCell,
                    styles.tableCellHeader,
                    { width: "7.5%", textAlign: "right" },
                  ]}
                >
                  Titular
                </Text>
                <Text
                  style={[
                    styles.tableCell,
                    styles.tableCellHeader,
                    { width: "7.5%", textAlign: "right" },
                  ]}
                >
                  Conyuge
                </Text>
                <Text
                  style={[
                    styles.tableCell,
                    styles.tableCellHeader,
                    { width: "7.5%", textAlign: "right" },
                  ]}
                >
                  Concubino
                </Text>
                <Text
                  style={[
                    styles.tableCell,
                    styles.tableCellHeader,
                    { width: "7.5%", textAlign: "right" },
                  ]}
                >
                  Hijo menor a 21
                </Text>
                <Text
                  style={[
                    styles.tableCell,
                    styles.tableCellHeader,
                    { width: "7.5%", textAlign: "right" },
                  ]}
                >
                  Hijo sol est. entre 21 y 25
                </Text>
                {/*  */}
                <Text
                  style={[
                    styles.tableCell,
                    styles.tableCellHeader,
                    { width: "7.5%", textAlign: "right" },
                  ]}
                >
                  Hij sol cony menor a 21
                </Text>
                <Text
                  style={[
                    styles.tableCell,
                    styles.tableCellHeader,
                    { width: "7.5%", textAlign: "right" },
                  ]}
                >
                  H. so. con es. entre 21 y 25
                </Text>
                {/*  */}
                <Text
                  style={[
                    styles.tableCell,
                    styles.tableCellHeader,
                    { width: "7.5%", textAlign: "right" },
                  ]}
                >
                  Menor tutela
                </Text>
                <Text
                  style={[
                    styles.tableCell,
                    styles.tableCellHeader,
                    { width: "7.5%", textAlign: "right" },
                  ]}
                >
                  Fam a cargo
                </Text>
                <Text
                  style={[
                    styles.tableCell,
                    styles.tableCellHeader,
                    { width: "7.5%", textAlign: "right" },
                  ]}
                >
                  Mayor de 25 discapacitado
                </Text>
                <Text
                  style={[
                    styles.tableCell,
                    styles.tableCellHeader,
                    { width: "7.5%", textAlign: "right" },
                  ]}
                >
                  T.Zona
                </Text>
              </View>
            </View>
            {datosPDF?.map((item) => (
              <View style={styles.tableRow} key={item.detalle}>
                <Text
                  style={[
                    styles.tableCell,
                    { width: "5%", textAlign: "right" },
                  ]}
                >
                  {item?.cZona ? item?.cZona : "0"}
                </Text>
                <Text
                  style={[
                    styles.tableCell,
                    { width: "12.5%", textAlign: "right" },
                  ]}
                >
                  {item?.nZona ? item?.nZona : "0"}
                </Text>
                <Text
                  style={[
                    styles.tableCell,
                    { width: "7.5%", textAlign: "right" },
                  ]}
                >
                  {item?.titular ? item?.titular : "0"}
                </Text>
                <Text
                  style={[
                    styles.tableCell,
                    { width: "7.5%", textAlign: "right" },
                  ]}
                >
                  {item?.conyuge ? item?.conyuge : "0"}
                </Text>
                <Text
                  style={[
                    styles.tableCell,
                    { width: "7.5%", textAlign: "right" },
                  ]}
                >
                  {item?.concubino ? item?.concubino : "0"}
                </Text>
                <Text
                  style={[
                    styles.tableCell,
                    { width: "7.5%", textAlign: "right" },
                  ]}
                >
                  {item?.menor21 ? item?.menor21 : "0"}
                </Text>
                <Text
                  style={[
                    styles.tableCell,
                    { width: "7.5%", textAlign: "right" },
                  ]}
                >
                  {item?.entre2125 ? item?.entre2125 : "0"}
                </Text>
                {/*  */}
                <Text
                  style={[
                    styles.tableCell,
                    { width: "7.5%", textAlign: "right" },
                  ]}
                >
                  {item?.entre2125 ? item?.entre2125 : "0"}
                  {/*  Hij sol cony menor a 21 */}
                </Text>
                <Text
                  style={[
                    styles.tableCell,
                    { width: "7.5%", textAlign: "right" },
                  ]}
                >
                  {item?.entre2125 ? item?.entre2125 : "0"}
                </Text>
                {/*  */}
                <Text
                  style={[
                    styles.tableCell,
                    { width: "7.5%", textAlign: "right" },
                  ]}
                >
                  {item?.menortutela ? item?.menortutela : "0"}
                </Text>
                <Text
                  style={[
                    styles.tableCell,
                    { width: "7.5%", textAlign: "right" },
                  ]}
                >
                  {item?.famacargo ? item?.famacargo : "0"}
                </Text>
                <Text
                  style={[
                    styles.tableCell,
                    { width: "7.5%", textAlign: "right" },
                  ]}
                >
                  {item?.discmayor25 ? item?.discmayor25 : "0"}
                </Text>
                <Text
                  style={[
                    styles.tableCell,
                    { width: "7.5%", textAlign: "right" },
                  ]}
                >
                  {item?.tZona ? item?.tZona : "0"}
                </Text>
              </View>
            ))}
            {/* <View style={styles.tableHeader}>
              <View style={styles.tableRow}>
                <Text style={[styles.tableCell, { width: "60%" }]}>Total:</Text>
                <Text
                  style={[
                    styles.tableCell,
                    { width: "40%", textAlign: "right" },
                  ]}
                >
                  {datosPDF?.reduce(
                    (sum, row) => sum + Number(row.cantidad),
                    0
                  )}
                </Text>
              </View>
            </View> */}
          </View>
        </>

        <View style={[styles.footer, { paddingTop: "10px" }]} fixed>
          <Text
            render={({ pageNumber, totalPages }) =>
              `Página ${pageNumber} de ${totalPages}`
            }
          />
        </View>
      </Page>
    </Document>
  );
};
