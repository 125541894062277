import * as React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import DataGridReporte from "./DataGridReportes";
import { useParams } from "react-router-dom";
import { Divider } from "@mui/material";

export default function Reporte() {
  const { reporteID } = useParams();
  return (
    <Box sx={{ width: "100%" }}>
      {/* <Typography variant="h3" textAlign={"center"}>
        {reporteID}
      </Typography>
      <Divider sx={{ marginTop: 3 }} /> */}
      <DataGridReporte reporte={reporteID} />
    </Box>
  );
}
