export const cards = [
  {
    nombre: "Reducciones",
    tipo: ".zip",
    id: 7,
  },
  {
    nombre: "Transferencias Obra Social",
    tipo: ".zip",
    id: 6,
  },

  {
    nombre: "Relaciones Laborales",
    tipo: ".txt",
    id: 8,
  },
  {
    nombre: "Padron de empresas",
    tipo: ".zip",
    id: 5,
  },
  {
    nombre: "SUMA",
    tipo: ".txt",
    id: 1,
  },
  {
    nombre: "SUMARTE",
    tipo: ".txt",
    id: 2,
  },
  {
    nombre: "SUMA 70",
    tipo: ".txt",
    id: 3,
  },
  {
    nombre: "SUBSIDIOS",
    tipo: ".zip",
    id: 4,
  },
];
