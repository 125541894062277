import { useState } from "react";
import styled from "@emotion/styled";
import {
  Box,
  Card as MuiCard,
  CardContent,
  Stack,
  TextField as MuiTextField,
  Alert,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Radio,
  Skeleton,
} from "@mui/material";
import { spacing } from "@mui/system";
import CustomLabel from "../../../theme/CustomLabel";
import { Confirmacion } from "./datosBenef/Confirmacion";
import { BuscarAfiliado } from "./datosBenef/BuscarAfiliado";
import { LoadingButton } from "@mui/lab";

const Card = styled(MuiCard)(spacing);

const TextField = styled(MuiTextField)(spacing);

export const BuscoBenef = ({
  handleBusqueda,
  datosBef,
  handleNext,
  openBuscar,
  closeBuscar,
  isLoading,
  handleChosen,
}) => {
  const [datoBusqueda, setDatoBusqueda] = useState("");
  const [tipoBusqueda, setTipoBusqueda] = useState("numBenef");
  const [openConfirmacion, setOpenConfirmacion] = useState(false);

  // ----------------------------------------- Formateadores de datos -----------------------------------------
  const formatDate = (inputDate) => {
    if (inputDate) {
      const [datePart] = inputDate.split(" ");
      const [year, month, day] = datePart.split("-");

      return `${day}/${month}/${year}`;
    }
    return "";
  };

  const convertirCuit = (numero) => {
    if (numero) {
      const numeroString = numero.toString();
      const parte1 = numeroString.slice(0, 2);
      const parte2 = numeroString.slice(2, 10);
      const parte3 = numeroString.slice(10, 11);

      return `${parte1}-${parte2}-${parte3}`;
    }
    return "";
  };

  const dniFormatter = (dni) => {
    if (dni) {
      return dni.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
    return "";
  };

  const nroBenefMaker = (nro, parent) => {
    if (nro && parent) {
      const nroBenef = nro + "/" + parent;
      return nroBenef;
    }
    return "";
  };

  const splitNroBenef = (data) => {
    const [nro, parent] = data.split("/");
    return { nro: nro, parent: parent ? parent : "-1" };
  };

  const handlePickBenef = (benef) => {
    handleChosen(benef);
    closeBuscar();
  };

  // --------------------------------------Fin de formateadores-------------------------------------------------

  // --------------------------------------------Modales--------------------------------------------------------

  // -----Modal de confirmacion-----
  const handleOpenConfirmar = () => {
    setOpenConfirmacion(true);
  };

  const handleCloseConfirmar = () => {
    setOpenConfirmacion(false);
  };

  // -----------------------------------------Fin modales-------------------------------------------------------

  const handleBuscar = () => {
    if (tipoBusqueda === "numBenef") {
      const dato = splitNroBenef(datoBusqueda);
      handleBusqueda(tipoBusqueda, dato);
    } else {
      handleBusqueda(tipoBusqueda, datoBusqueda);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleBuscar();
    }
  };

  const handleHelper = () => {
    if (tipoBusqueda === "documento" || tipoBusqueda === "cuil") {
      return "Sin puntos ni guiones";
    }
    return "";
  };

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Card sx={{ p: 3, mb: 2 }}>
          <CardContent>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <FormControl component="fieldset" variant="standard">
                <RadioGroup
                  aria-label="metodoBusqueda"
                  value={tipoBusqueda}
                  onChange={(event, value) => {
                    setTipoBusqueda(event.target.value);
                  }}
                  sx={{ flexDirection: "row" }}
                >
                  <FormControlLabel
                    value="numBenef"
                    control={<Radio />}
                    label="Número de beneficiario"
                  />
                  <FormControlLabel
                    value="documento"
                    control={<Radio />}
                    label="Documento"
                  />
                  <FormControlLabel
                    value="cuil"
                    control={<Radio />}
                    label="CUIL"
                  />
                  <FormControlLabel
                    value="nombre"
                    control={<Radio />}
                    label="Nombre"
                  />
                </RadioGroup>
              </FormControl>
              <Stack direction="row" spacing={2} sx={{ mt: 3 }}>
                <TextField
                  id="search"
                  label={[<CustomLabel>Ingresa un dato</CustomLabel>]}
                  value={datoBusqueda}
                  onChange={(event, value) => {
                    setDatoBusqueda(event.target.value);
                  }}
                  helperText={handleHelper()}
                  variant="outlined"
                  onKeyDown={handleKeyDown}
                />
                <LoadingButton
                  type="submit"
                  loading={isLoading}
                  variant="contained"
                  color="primary"
                  disabled={!datoBusqueda}
                  onClick={handleBuscar}
                  sx={{ ml: 3, borderRadius: "10px" }}
                >
                  Buscar
                </LoadingButton>
              </Stack>
            </Box>
          </CardContent>
        </Card>
      </Box>
      {isLoading && (
        <Skeleton
          variant="rounded"
          animation="wave"
          height={250}
          sx={{ borderRadius: "20px", margin: 2, marginBottom: 2, padding: 1 }}
        />
      )}
      {datosBef.Beneficiario && !isLoading && (
        <Card sx={{ p: 3, mb: 4 }}>
          <CardContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column", mt: 3 }}>
                <Stack direction="row" spacing={2} sx={{ mt: 3 }}>
                  <TextField
                    id="nroBenef"
                    label={[<CustomLabel>N° Beneficiario</CustomLabel>]}
                    value={nroBenefMaker(
                      datosBef.Beneficiario,
                      datosBef.Parentezco
                    )}
                    variant="outlined"
                  />
                  <TextField
                    id="nameBenef"
                    label={[<CustomLabel>Nombre de beneficiario</CustomLabel>]}
                    value={datosBef.Nombre || ""}
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                    variant="outlined"
                  />
                </Stack>
                <Stack direction="row" spacing={2} sx={{ mt: 3 }}>
                  <TextField
                    id="dni"
                    label={[<CustomLabel>DNI</CustomLabel>]}
                    value={dniFormatter(datosBef.Documento) || ""}
                    fullWidth
                    variant="outlined"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <TextField
                    id="cuil"
                    label={[<CustomLabel>CUIL</CustomLabel>]}
                    value={convertirCuit(datosBef.Cuil) || ""}
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                    variant="outlined"
                  />
                  <TextField
                    id="fechaNac"
                    label={[<CustomLabel>Fecha de nacimiento</CustomLabel>]}
                    value={formatDate(datosBef.Fnacim) || ""}
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                    variant="outlined"
                  />
                </Stack>
                {datosBef.Fecha_hasta_Discap && (
                  <Box sx={{ mt: 3 }}>
                    <Alert variant="outlined" severity="info">
                      DISCAPACIDAD Hasta: {datosBef.Fecha_hasta_Discap} Obs:{" "}
                      {datosBef.Observacion_discap
                        ? datosBef.Observacion_discap
                        : ""}
                    </Alert>
                  </Box>
                )}
              </Box>
            </Box>
          </CardContent>
        </Card>
      )}
      {datosBef.Beneficiario && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <LoadingButton variant="contained" onClick={handleOpenConfirmar}>
            Nueva solicitud
          </LoadingButton>
        </Box>
      )}
      <Confirmacion
        handleClose={handleCloseConfirmar}
        show={openConfirmacion}
        nombreBenef={datosBef.Nombre || ""}
        cuilBenef={datosBef.Cuil || ""}
        handleNext={handleNext}
      />
      <BuscarAfiliado
        handleClose={closeBuscar}
        show={openBuscar}
        formatterDni={dniFormatter}
        datos={datosBef}
        handleBuscar={handlePickBenef}
      />
    </>
  );
};

/* ------------------------- Endpoint para traer datos benef ------------------------------

  const [datosBef, setDatosBef] = useState([]);

  const getDatosBef = async (nroBenef) => {
    try {
      const response = await axios.post(`${hosteo}/api/prestacional/getBenef`, {
        nbenef: nroBenef.nro,
        paren: nroBenef.parent,
      });
      console.log("datos", response.data);
      // setDatosBef(response.data);
    } catch (error) {
      const infoBenef = {};

      const infoEmpresa = {};

      setDatosBef({
        infoBenef: infoBenef,
        infoEmpresa: infoEmpresa,
        novBenef: undefined,
      });
      enqueueSnackbar(error.message || "No tienes conexión a internet", {
        variant: "error",
      });
    }
  };

  const handleBusqueda = (nroBenef) => {
    getDatosBef(nroBenef);
  };


*/

/* ---------------- Codigo anterior -------------------



const validationSchema = Yup.object().shape({
  busqueda: Yup.string().max(255).required("Ingrese un dato para la busqueda"),
});

const BuscoBenef = ({
  handleBusqueda,
  datosBusqueda,
  setDatosBusqueda,
  datosBef,
}) => {
  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      await timeOut(1500);
      resetForm();
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  const isMobile = useMediaQuery({ maxWidth: 768 });

  return (
    <Formik
      initialValues={{
        busqueda: "",
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Card mb={4}>
          <CardContent>
            <form onSubmit={handleSubmit}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                }}
                justifyContent="flex-start"
                alignItems="center"
              >
                <Box xs={12} md={4}>
                  <FormControl component="fieldset" variant="standard">
                    <RadioGroup
                      aria-label="metodoBusqueda"
                      name="busqueda1"
                      value={datosBusqueda.type}
                      onChange={(event, value) => {
                        setDatosBusqueda({ ...datosBusqueda, type: value });
                      }}
                      sx={{ flexDirection: "row" }}
                    >
                      <FormControlLabel
                        value="numBenef"
                        control={<Radio />}
                        label="Numero de beneficiario"
                      />
                      <FormControlLabel
                        value="documento"
                        control={<Radio />}
                        label="Documento"
                      />
                      <FormControlLabel
                        value="cuil"
                        control={<Radio />}
                        label="CUIL"
                      />
                      <FormControlLabel
                        value="nombre"
                        control={<Radio />}
                        label="Nombre"
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
                <Box item xs={12} md={4}>
                  <TextField
                    label="Busqueda"
                    variant="outlined"
                    value={datosBusqueda.id}
                    onChange={(event, value) => {
                      setDatosBusqueda({
                        ...datosBusqueda,
                        id: event.target.value,
                      });
                    }}
                  />
                </Box>

                <Box>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    m={3}
                    onClick={() => handleBusqueda()}
                  >
                    Buscar
                  </Button>
                </Box>
              </Box>
            </form>
          </CardContent>
        </Card>
      )}
    </Formik>









*/
