import { useState, useRef } from "react";
import {
  TextField,
  Card,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  CircularProgress,
} from "@mui/material";
import { HelperText } from "../HelperText.jsx";
import { Form, FormikProvider, useFormik } from "formik";
import { useSnackbar } from "notistack";
import LoadingButton from "@mui/lab/LoadingButton";
import { DataGrid, esES } from "@mui/x-data-grid";
import hosteo from "../../../utils/hosteo.js";
import axios from "../../../utils/axios.js";
import * as XLSX from "../../../../node_modules/xlsx/xlsx.js";
import DownloadIcon from "@mui/icons-material/Download";
import CustomLabel from "../../../theme/CustomLabel.js";
import { formatearDates, fDateArgentina } from "../../../utils/formatNumber.js";
import { PersonalBajaAltaPDF } from "./pdf/PersonalBajaAltaPDF.jsx";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import { convertirCuit, formatDate } from "../../../utils/formatNumber.js";
import useAuth from "../../../hooks/useAuth.js";
// import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import es from "date-fns/locale/es";
import {
  MobileDateRangePicker,
  DesktopDateRangePicker,
} from "@material-ui/lab";
import { BrowserView, MobileView } from "react-device-detect";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import esLocale from "date-fns/locale/es";
import { BuscadorTabla } from "../../BuscadorTabla.jsx";

export default function PersonalBajaAlta() {
  const { enqueueSnackbar } = useSnackbar();
  const [desde, setDesde] = useState(null);
  const [hasta, setHasta] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState("1");
  const [datos, setDatos] = useState([]);
  const [filtrado, setFiltrado] = useState([]);
  const [pageSize, setPageSize] = useState(25);
  const [currentPage, setCurrentPage] = useState(0);
  const [entre, setEntre] = useState({});
  const [tipo, setTipo] = useState("");
  const [value, setValue] = useState([null, null]);
  const { user } = useAuth();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      desde: "",
      zonaDesde: "",
      zonaHasta: "",
    },
    // validationSchema: Yup.object().shape({
    //   zona: Yup.string().required("La zona es obligatoria"),
    //   familiar: Yup.string().required("El familiar es obligatorio"),
    // }),
    onSubmit: async (values) => {
      setDatos([]);
      setFiltrado([]);
      try {
        setIsLoading(true);
        setEntre({
          desde: fDateArgentina(values.zonaDesde),
          hasta: fDateArgentina(values.zonaHasta),
        });
        if (selectedOption === "1") {
          const response = await axios.post(
            `${hosteo}/api/empadronamiento/beneficiarios/getPersonalAlta`,
            {
              zonaInicial: values.zonaDesde,
              zonaFinal: values.zonaHasta,
              desde: value[0].toISOString().split("T")[0],
              hasta: value[1].toISOString().split("T")[0],
            }
          );
          const rowsWithId = response.data
            ? response?.data?.map((item, index) => ({
                ...item,
                id: item.index || index + 1,
              }))
            : [];
          setTipo("Alta");
          setDatos(rowsWithId);
          setFiltrado(rowsWithId);
        } else {
          const response = await axios.post(
            `${hosteo}/api/empadronamiento/beneficiarios/getPersonalBaja`,
            {
              desde: value[0].toISOString().split("T")[0],
              hasta: value[1].toISOString().split("T")[0],
              zonaInicial: values.zonaDesde,
              zonaFinal: values.zonaHasta,
            }
          );

          const rowsWithId = response?.data
            ? response?.data?.map((item, index) => ({
                ...item,
                id: item.index || index + 1,
              }))
            : [];
          setTipo("Baja");
          setDatos(rowsWithId);
          setFiltrado(rowsWithId);
        }
      } catch (error) {
        enqueueSnackbar(error.message || "Algo salió mal", {
          variant: "error",
        });
      } finally {
        setIsLoading(false);
      }
    },
  });
  const { handleSubmit, getFieldProps, touched, errors } = formik;

  const handlePaginationChange = (params) => {
    if (params.pageSize !== pageSize) {
      setPageSize(params.pageSize);
      setCurrentPage(0);
    } else {
      setCurrentPage(params.page);
    }
  };

  const columns = [
    {
      field: "zona",
      headerName: "Zona",
      flex: 1,
      minWidth: 150,
      editable: false,
      type: "number",
    },
    {
      field: "empresa",
      headerName: "Empresa",
      flex: 1,
      minWidth: 120,
      editable: false,
      type: "number",
    },
    {
      field: "razon",
      headerName: "Razon",
      flex: 1,
      minWidth: 120,
      editable: false,
    },
    {
      field: "cuil",
      headerName: "Cuil",
      flex: 1,
      minWidth: 120,
      editable: false,
      type: "number",
      valueFormatter: (params) => convertirCuit(params.value),
    },
    {
      field: "nombre",
      headerName: "Nombre",
      flex: 1,
      minWidth: 120,
      editable: false,
    },
    {
      field: "fecha",
      headerName: "Fecha",
      flex: 1,
      minWidth: 150,
      editable: false,
      valueFormatter: (params) => formatDate(params.value),
    },
  ];

  const generateAndDownloadExcel = () => {
    let nombre = "Personal baja-alta";

    const workbook = XLSX.utils.book_new();

    // Crea una nueva hoja de cálculo con datos
    const dia = new Date();
    const options = {
      timeZone: "America/Buenos_Aires",
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };

    // Encabezado original
    const encabezado = [
      [`Reporte de ${nombre}`],
      [
        `Generado a las ${dia
          .toLocaleTimeString("es-AR", options)
          .slice(0, 5)}hs del ${dia.toLocaleDateString()}`,
      ],
      [""],
    ];

    // Títulos de las columnas
    const columnTitles = [
      "Zona",
      "Empresa",
      "Razon",
      "Cuil",
      "Nombre",
      "Fecha",
    ];

    // Unir encabezado original con nuevos títulos de columna
    const datosConTitulos = [...encabezado, columnTitles];

    // Agregar datos a la hoja de cálculo
    datos.forEach((item) => {
      const rowData = [
        item.zona,
        item.empresa,
        item.razon,
        item.cuil,
        item.nombre,
        item.altaoso,
      ];
      datosConTitulos.push(rowData);
    });

    const worksheet = XLSX.utils.aoa_to_sheet(datosConTitulos);
    XLSX.utils.book_append_sheet(workbook, worksheet, nombre);
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${nombre}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const generateAndDownloadPDF = async () => {
    const blob = await pdf(
      <PersonalBajaAltaPDF
        datosPDF={datos}
        entre={entre}
        tipo={tipo}
        user={user}
      />
    ).toBlob();
    saveAs(
      blob,
      `${tipo} por cambios de obra social - ${new Date().toLocaleDateString()}`
    );
  };

  const clearForm = () => {
    formik.resetForm();
    setDatos([]);
    setFiltrado([]);
    setHasta(null);
    setDesde(null);
  };

  const handleKeyDown = (event, nextInputId) => {
    if (event.key === "Enter") {
      if (nextInputId === 1) {
        datePickerRef.current.focus();
      } else if (nextInputId === 2) {
        datePickerRef2.current.focus();
      } else if (nextInputId === 4) {
      } else {
        document.getElementById(nextInputId + 1).focus();
      }
    }
    console.log(nextInputId + 1);
  };
  const datePickerRef = useRef(null);
  const datePickerRef2 = useRef(null);

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
        <Box sx={{ mt: 2 }}>
          <HelperText
            message={
              "Permite ver todos los empleados dados de alta o baja por cambio de obra social entre las fechas y zonas indicadas"
            }
          />
        </Box>
        <Card>
          <Typography sx={{ textAlign: "center", mt: 2 }}>
            Seleccione una opcion.
          </Typography>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                <RadioGroup
                  row
                  aria-label="radio"
                  name="radio"
                  value={selectedOption}
                  onChange={(event, value) => {
                    setSelectedOption(value);
                    clearForm();
                  }}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <FormControlLabel
                    disabled={isLoading}
                    value="1"
                    control={<Radio />}
                    label="Alta"
                  />
                  <FormControlLabel
                    disabled={isLoading}
                    value="2"
                    control={<Radio />}
                    label="Baja"
                  />
                </RadioGroup>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  p: 3,
                  gap: 3,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    justifyContent: "center",
                    "@media (max-width: 700px)": {
                      flexDirection: "column",
                      alignItems: "center",
                      width: "100%",
                    },
                  }}
                >
                  <MobileView>
                    <MobileDateRangePicker
                      toolbarTitle="Seleccione entre fechas"
                      inputFormat="dd/MM/yyyy"
                      startText={<CustomLabel>Desde</CustomLabel>}
                      endText={<CustomLabel>Hasta</CustomLabel>}
                      value={value}
                      onChange={(newValue) => {
                        setValue(newValue);
                      }}
                      renderInput={(startProps, endProps) => (
                        <>
                          <TextField
                            name="desde"
                            autoComplete="off"
                            {...startProps}
                          />
                          <Box sx={{ mx: 2 }}> a </Box>
                          <TextField
                            name="hasta"
                            autoComplete="off"
                            {...endProps}
                          />
                        </>
                      )}
                    />
                  </MobileView>
                  <BrowserView>
                    <DesktopDateRangePicker
                      inputFormat="dd/MM/yyyy"
                      startText={<CustomLabel>Desde</CustomLabel>}
                      endText={<CustomLabel>Hasta</CustomLabel>}
                      value={value}
                      onChange={(newValue) => {
                        {
                          setValue(newValue);
                        }
                      }}
                      renderInput={(startProps, endProps) => (
                        <>
                          <TextField
                            name="desde"
                            autoComplete="off"
                            {...startProps}
                          />
                          <Box sx={{ mx: 2 }}> a </Box>
                          <TextField
                            name="hasta"
                            autoComplete="off"
                            {...endProps}
                          />
                        </>
                      )}
                    />
                  </BrowserView>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: 3,
                    "@media (max-width: 600px)": {
                      flexDirection: "column",
                      alignItems: "center",
                    },
                  }}
                >
                  <TextField
                    disabled={isLoading}
                    sx={{
                      width: "280px",
                      "@media (max-width: 600px)": {
                        width: "250px",
                      },
                    }}
                    key="zonaDesde1"
                    label={<CustomLabel>Zona Desde</CustomLabel>}
                    type="number"
                    {...getFieldProps("zonaDesde")}
                    error={Boolean(touched.zonaDesde && errors.zonaDesde)}
                    helperText={touched.zonaDesde && errors.zonaDesde}
                    id={3}
                    onKeyDown={(e) => handleKeyDown(e, 3)}
                  />
                  <TextField
                    disabled={isLoading}
                    sx={{
                      width: "280px",
                      "@media (max-width: 600px)": {
                        width: "250px",
                      },
                    }}
                    key="zonaHasta1"
                    label={<CustomLabel>Zona Hasta</CustomLabel>}
                    type="number"
                    {...getFieldProps("zonaHasta")}
                    error={Boolean(touched.zonaHasta && errors.zonaHasta)}
                    helperText={touched.zonaHasta && errors.zonaHasta}
                    id={4}
                    onKeyDown={(e) => handleKeyDown(e, 4)}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    mt: 3,
                    gap: 3,
                  }}
                >
                  <LoadingButton
                    sx={{ height: "100%" }}
                    loading={isLoading}
                    variant="contained"
                    onClick={formik.handleSubmit}
                  >
                    Buscar
                  </LoadingButton>
                </Box>
              </Box>
              {isLoading && (
                <Box sx={{ display: "flex", justifyContent: "center", my: 10 }}>
                  <CircularProgress size={75} />
                </Box>
              )}
              {datos.length > 0 && (
                <Box sx={{ p: 4 }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      mb: 5,
                      gap: 2,
                    }}
                  >
                    <LoadingButton
                      variant="contained"
                      color="success"
                      onClick={generateAndDownloadExcel}
                      startIcon={<DownloadIcon />}
                    >
                      EXCEL
                    </LoadingButton>
                    <LoadingButton
                      variant="contained"
                      color="error"
                      onClick={generateAndDownloadPDF}
                      startIcon={<DownloadIcon />}
                    >
                      PDF
                    </LoadingButton>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <BuscadorTabla datos={datos} setter={setFiltrado} />
                  </Box>
                  <DataGrid
                    autoHeight
                    localeText={
                      esES.components.MuiDataGrid.defaultProps.localeText
                    }
                    disableRowSelectionOnClick
                    onPaginationModelChange={(row) =>
                      handlePaginationChange(row)
                    }
                    onPageChange={(newPage) => setCurrentPage(newPage)}
                    paginationModel={{
                      page: currentPage,
                      pageSize: pageSize,
                    }}
                    rows={filtrado || []}
                    columns={columns}
                    initialState={{
                      pagination: {
                        paginationModel: { page: 0, pageSize: 25 },
                      },
                    }}
                    pageSizeOptions={[10, 25, 50, 100]}
                  />
                </Box>
              )}
            </Form>
          </FormikProvider>
        </Card>
      </LocalizationProvider>
    </>
  );
}
