import { useState } from "react";
import { useFormik } from "formik";
import {
  TextField,
  Button,
  Card,
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import { enqueueSnackbar } from "notistack";

import axios from "../../../utils/axios";
import hosteo from "../../../utils/hosteo";
import CustomLabel from "../../../theme/CustomLabel";

const validationSchema = yup.object({
  nombre: yup
    .string()
    .required("Campo requerido")
    .max(30, "El nombre puede tener como máximo 30 caracteres")
    .min(5, "El nombre debe tener minimo 5 caracteres"),

  apellido: yup
    .string()
    .required("Campo requerido")
    .max(30, "El apellido puede tener como máximo 30 caracteres")
    .min(5, "El apellido debe tener minimo 5 caracteres"),

  posicion: yup
    .string()
    .required("Campo requerido")
    .max(30, "La posicon puede tener como máximo 30 caracteres")
    .min(5, "La posicon debe tener minimo 5 caracteres"),

  username: yup
    .string()
    .required("Campo requerido")
    .max(20, "El usuario puede tener como máximo 20 caracteres")
    .min(5, "El usuario debe tener minimo 5 caracteres"),
  email: yup
    .string()
    .email("Formato de correo electrónico inválido")
    .required("Campo requerido"),
});

const CreateUserForm = () => {
  const [exito, setExito] = useState(false);
  const [usuario, setUsuario] = useState("");
  const formik = useFormik({
    initialValues: {
      nombre: "",
      apellido: "",
      posicion: "",
      username: "",
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const response = await axios.post(
          `${hosteo}/api/account/createAccount`,
          {
            nombre: values.nombre,
            apellido: values.apellido,
            posicion: values.posicion,
            username: values.username,
            email: values.email,
          }
        );
        enqueueSnackbar(response.data.message, { variant: "success" });
        setUsuario(values.username);
        setExito(true);
      } catch (error) {
        enqueueSnackbar(error.message || "No tienes conexión a internet", {
          variant: "error",
        });
      }
      formik.resetForm();
    },
  });

  return (
    <>
      <Card>
        <form onSubmit={formik.handleSubmit}>
          <Grid container p={3} spacing={3} sx={{ justifyContent: "center" }}>
            <Grid item xs={10}>
              <TextField
                fullWidth
                id="nombre"
                name="nombre"
                label={[<CustomLabel>Nombre</CustomLabel>]}
                value={formik.values.nombre}
                onChange={formik.handleChange}
                error={formik.touched.nombre && Boolean(formik.errors.nombre)}
                helperText={formik.touched.nombre && formik.errors.nombre}
              />
            </Grid>
            <Grid item xs={10}>
              <TextField
                fullWidth
                id="apellido"
                name="apellido"
                label={[<CustomLabel>Apellido</CustomLabel>]}
                value={formik.values.apellido}
                onChange={formik.handleChange}
                error={
                  formik.touched.apellido && Boolean(formik.errors.apellido)
                }
                helperText={formik.touched.apellido && formik.errors.apellido}
              />
            </Grid>
            <Grid item xs={10}>
              <TextField
                fullWidth
                id="posicion"
                name="posicion"
                label={[<CustomLabel>Posición</CustomLabel>]}
                value={formik.values.posicion}
                onChange={formik.handleChange}
                error={
                  formik.touched.posicion && Boolean(formik.errors.posicion)
                }
                helperText={formik.touched.posicion && formik.errors.posicion}
              />
            </Grid>
            <Grid item xs={10}>
              <TextField
                fullWidth
                id="username"
                name="username"
                label={[<CustomLabel>Nombre de Usuario</CustomLabel>]}
                value={formik.values.username}
                onChange={formik.handleChange}
                error={
                  formik.touched.username && Boolean(formik.errors.username)
                }
                helperText={formik.touched.username && formik.errors.username}
              />
            </Grid>

            <Grid item xs={10}>
              <TextField
                fullWidth
                id="email"
                name="email"
                label={[<CustomLabel>Email</CustomLabel>]}
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
            <Grid item xs={10}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
              >
                Crear Usuario
              </Button>
            </Grid>
          </Grid>
        </form>
      </Card>

      <Dialog
        open={exito}
        onClose={() => setExito(false)}
        fullWidth
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Usuario creado con exito
        </DialogTitle>
        <DialogContent>
          <Typography variant="h3" gutterBottom>
            Envie la siguiente informacion al usuario:
          </Typography>
          <Typography variant="h5" gutterBottom>
            Usuario: {usuario}
          </Typography>
          <Typography variant="h5" gutterBottom>
            Contraseña: 1234
          </Typography>
          <Typography variant="h5" gutterBottom>
            Al ingresar debe cambiar la contraseña
          </Typography>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CreateUserForm;
