import { useState, useEffect } from "react";
import { Card, Box, CircularProgress } from "@mui/material";
import { HelperText } from "../HelperText.jsx";
import { useSnackbar } from "notistack";
import LoadingButton from "@mui/lab/LoadingButton";
import { DataGrid, esES } from "@mui/x-data-grid";
import hosteo from "../../../utils/hosteo.js";
import axios from "../../../utils/axios.js";
import * as XLSX from "../../../../node_modules/xlsx/xlsx.js";
import DownloadIcon from "@mui/icons-material/Download";
import { BuscadorTabla } from "../../BuscadorTabla.jsx";

const columns = [
  {
    field: "ddjj",
    headerName: "Declaracion Jurada",
    flex: 1,
    minWidth: 150,
    editable: false,
    headerAlign: "right",
    align: "right",
  },
  {
    field: "cuil",
    headerName: "Cuil",
    flex: 1,
    minWidth: 120,
    editable: false,
    headerAlign: "right",
    align: "right",
  },
  {
    field: "periodo",
    headerName: "Periodo",
    flex: 1,
    minWidth: 120,
    editable: false,
    headerAlign: "right",
    align: "right",
  },
  {
    field: "nbenef",
    headerName: "N° Beneficiario",
    flex: 1,
    minWidth: 120,
    editable: false,
    headerAlign: "right",
    align: "right",
  },
  {
    field: "zona",
    headerName: "Zona",
    flex: 1,
    minWidth: 120,
    editable: false,
    headerAlign: "right",
    align: "right",
  },
  {
    field: "empresa",
    headerName: "Empresa",
    flex: 1,
    minWidth: 120,
    editable: false,
    headerAlign: "right",
    align: "right",
  },
  {
    field: "nombre",
    headerName: "Nombre",
    flex: 1,
    minWidth: 170,
    editable: false,
  },
  {
    field: "numdoc",
    headerName: "N° Documento",
    flex: 1,
    minWidth: 120,
    editable: false,
    headerAlign: "right",
    align: "right",
  },
  {
    field: "baja",
    headerName: "Baja",
    flex: 1,
    minWidth: 120,
    editable: false,
    renderCell: (params) => {
      const bajaValue =
        params.value === "0000-00-00" || params.value === null
          ? "-"
          : params.value;
      return <div>{bajaValue}</div>;
    },
  },
];

export default function MonotributistaSinPagos() {
  const { enqueueSnackbar } = useSnackbar();
  const [datos, setDatos] = useState([]);
  const [filtrado, setFiltrado] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchMonotributistas = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${hosteo}/api/empadronamiento/getMonotributistasSospechosos`
      );
      const rowsWithId = response.data
        ? response.data.map((item, index) => ({
            ...item,
            id: item.index || index + 1,
          }))
        : [];
      setDatos(rowsWithId);
      setFiltrado(rowsWithId);
    } catch (error) {
      enqueueSnackbar(error.message || "No tienes conexión a internet", {
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchMonotributistas();
  }, []);

  const generateAndDownloadExcel = () => {
    let nombre = "Monotrubutistas sin pagos";

    const workbook = XLSX.utils.book_new();

    // Crea una nueva hoja de cálculo con datos
    const dia = new Date();
    const options = {
      timeZone: "America/Buenos_Aires",
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };

    // Encabezado original
    const encabezado = [
      [`Reporte de ${nombre}`],
      [
        `Generado a las ${dia
          .toLocaleTimeString("es-AR", options)
          .slice(0, 5)}hs del ${dia.toLocaleDateString()}`,
      ],
      [""],
    ];

    // Títulos de las columnas
    const columnTitles = [
      "Declaracion jurada",
      "Cuil",
      "Periodo",
      "Numero de beneficiario",
      "Empresa",
      "Nombre",
      "Numero de documento",
      "Baja",
    ];

    // Unir encabezado original con nuevos títulos de columna
    const datosConTitulos = [...encabezado, columnTitles];

    // Agregar datos a la hoja de cálculo
    datos.forEach((item) => {
      const rowData = [
        item.ddjj,
        item.cuil,
        item.periodo,
        item.nbenef,
        item.empresa,
        item.nombre,
        item.numdoc,
        item.baja,
      ];
      datosConTitulos.push(rowData);
    });

    const worksheet = XLSX.utils.aoa_to_sheet(datosConTitulos);
    XLSX.utils.book_append_sheet(workbook, worksheet, nombre);
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${nombre}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <HelperText
          message={
            "Este resumen aborda los beneficios para los monotributistas sociales que no han realizado pagos durante tres meses seguidos o durante cinco meses intercalados."
          }
        />
      </Box>
      <Card sx={{ p: 3, mt: 2 }}>
        {isLoading ? (
          <Box sx={{ display: "flex", justifyContent: "center", my: 10 }}>
            <CircularProgress size={75} />
          </Box>
        ) : (
          <>
            <Box sx={{ display: "flex", justifyContent: "center", my: 5 }}>
              <LoadingButton
                variant="contained"
                color="success"
                onClick={generateAndDownloadExcel}
                startIcon={<DownloadIcon />}
              >
                EXCEL
              </LoadingButton>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                mt: 2,
              }}
            >
              <BuscadorTabla datos={datos} setter={setFiltrado} />
            </Box>
            <DataGrid
              sx={{ padding: 1 }}
              autoHeight
              disableRowSelectionOnClick
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}
              rows={filtrado || []}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 25,
                  },
                },
              }}
              pageSizeOptions={[5, 10, 25]}
            />
          </>
        )}
      </Card>
    </>
  );
}
