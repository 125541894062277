import { useState, useEffect } from "react";
import { Box, Card, CircularProgress } from "@mui/material";
import { HelperText } from "../HelperText.jsx";
import { useSnackbar } from "notistack";
import { DataGrid, esES } from "@mui/x-data-grid";
import hosteo from "../../../utils/hosteo.js";
import axios from "../../../utils/axios.js";
import * as XLSX from "../../../../node_modules/xlsx/xlsx.js";
import DownloadIcon from "@mui/icons-material/Download";
import useAuth from "../../../hooks/useAuth.js";
import { LoadingButton } from "@mui/lab";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import { PoblacionBeneficiariaPDF } from "./pdf/PoblacionBeneficiariaPDF.jsx";
import { BuscadorTabla } from "../../BuscadorTabla.jsx";

// ----------------------------- Codigos y a que zona pertenecen -----------------------------

// Capital Federal
const cafe = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41,
  42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60,
  61, 62, 63, 135, 163, 199, 500,
];

// Gran Buenos Aires
const grba = [
  64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82,
  83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 103,
  104, 105, 106, 107, 108, 109, 111, 114, 115, 116, 117, 118, 119, 120, 121,
  122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 136, 137,
  138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150, 151, 152,
  153, 154, 155, 156, 157, 158, 159, 160, 161, 162, 164, 165, 166, 167, 168,
  169, 170, 171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183,
  184, 185, 186, 189, 190, 191, 192, 193, 194, 195, 196, 197, 198,
];

// Resto Pcia Buenos Aires
const reba = [101, 102, 112, 110, 113, 187, 188, 301, 304, 306, 307];

//
const gruposEtareos = [
  { id: "0-4", min: 0, max: 4 },
  { id: "5-9", min: 5, max: 9 },
  { id: "10-14", min: 10, max: 14 },
  { id: "15-19", min: 15, max: 19 },
  { id: "20-24", min: 20, max: 24 },
  { id: "25-29", min: 25, max: 29 },
  { id: "30-34", min: 30, max: 34 },
  { id: "35-39", min: 35, max: 39 },
  { id: "40-44", min: 40, max: 44 },
  { id: "45-49", min: 45, max: 49 },
  { id: "50-54", min: 50, max: 54 },
  { id: "55-59", min: 55, max: 59 },
  { id: "60-64", min: 60, max: 64 },
  { id: "65-69", min: 65, max: 69 },
  { id: "70-74", min: 70, max: 74 },
  { id: "75", min: 75, max: "75+" },
];

// -------------------------------------------------------------------------------------------

export default function PoblacionBeneficiariaCards() {
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [filtrado, setFiltrado] = useState([]);
  const [datos, setDatos] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();

  const fetchBenef = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${hosteo}/api/empadronamiento/beneficiarios/getPoblacionBenef`
      );
      const rowsWithId = response.data.map((item, index) => ({
        ...item,
        id: index + 1,
      }));
      setDatos(asignamientoDeZona(rowsWithId));
      setFiltrado(asignamientoDeZona(rowsWithId));
      groupByZona(response.data);
    } catch (error) {
      enqueueSnackbar(error.message || "Algo salió mal", {
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchBenef();
  }, []);

  const groupByZona = (data) => {
    return data.reduce((acc, item) => {
      const zona = item.zona ? item.zona.trim() : null;
      if (!zona) {
        return acc;
      }
      if (!acc[zona]) {
        acc[zona] = [];
      }
      acc[zona].push(item);
      return acc;
    }, {});
  };

  const asignamientoDeZona = (datos) => {
    const nuevosDatos = datos.map((element) => {
      const codZona = element.codZona;
      if (cafe.includes(Number(codZona))) {
        return { ...element, zona: "Capital Federal" };
      } else if (grba.includes(Number(codZona))) {
        return { ...element, zona: "Gran Buenos Aires" };
      } else if (reba.includes(Number(codZona))) {
        return { ...element, zona: "Resto Pcia Buenos Aires" };
      } else {
        return element;
      }
    });
    return nuevosDatos;
  };

  const generateAndDownloadExcel = () => {
    setIsDownloading(true);
    let nombre = "Grupo etareo(Agrupado)";

    const workbook = XLSX.utils.book_new();

    // Crea una nueva hoja de cálculo con datos
    const dia = new Date();
    const options = {
      timeZone: "America/Buenos_Aires",
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
    const encabezado = [
      [`Reporte de ${nombre}`],
      [
        `Generado a las ${dia
          .toLocaleTimeString("es-AR", options)
          .slice(0, 5)}hs del ${dia.toLocaleDateString()}`,
      ],
      [""],
    ];
    const worksheet = XLSX.utils.aoa_to_sheet(encabezado);
    XLSX.utils.sheet_add_json(worksheet, datos, { origin: -1 });
    XLSX.utils.book_append_sheet(workbook, worksheet, nombre);
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${nombre}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setIsDownloading(false);
  };

  const generateAndDownloadPDF = async () => {
    setIsDownloading(true);
    const blob = await pdf(
      <PoblacionBeneficiariaPDF
        datosPDF={devolverResultadoFinal(datos)}
        user={user}
      />
    ).toBlob();
    saveAs(
      blob,
      `Población beneficiaria - En relacion de dependencia - ${new Date().toLocaleDateString()}.pdf`
    );
    setIsDownloading(false);
  };

  const columns = [
    {
      field: "fnacim",
      headerName: "Fecha",
      flex: 1,
      minWidth: 150,
      editable: false,
    },
    {
      field: "paren",
      headerName: "Parentezco",
      flex: 1,
      minWidth: 120,
      editable: false,
    },
    {
      field: "sexo",
      headerName: "Sexo",
      flex: 1,
      minWidth: 120,
      editable: false,
    },
    {
      field: "zona",
      headerName: "Zona",
      flex: 1,
      minWidth: 120,
      editable: false,
    },
  ];

  // ----------------------- Funciones para armar los datos del PDF ---------------------

  function separarPorZona(benefs) {
    var objetosPorZona = {};
    benefs.forEach(function (benef) {
      const zona = benef?.zona?.trim();
      if (!objetosPorZona[zona]) {
        objetosPorZona[zona] = [];
      }
      objetosPorZona[zona].push(benef);
    });

    const datosSeparados = Object.keys(objetosPorZona).map((zona) => ({
      zona,
      datos: objetosPorZona[zona],
    }));

    return datosSeparados;
  }

  function separarPorZonaNico(benefs) {
    const arrayAgrupado = benefs.reduce((acumulador, objeto) => {
      const { codZona, zona } = objeto;

      // Verifica si ya existe una entrada para la codZona actual
      const entradaExistente = acumulador.find(
        (entry) => entry.codZona === codZona
      );

      if (entradaExistente) {
        // Si existe, agrega el objeto actual a esa entrada
        entradaExistente.datos.push(objeto);
      } else {
        // Si no existe, crea una nueva entrada
        acumulador.push({
          codZona: codZona,
          nomZona: zona,
          datos: [objeto],
        });
      }

      return acumulador;
    }, []);

    return arrayAgrupado;
  }

  function edad(fnac) {
    const fnacDate = new Date(fnac);
    const currentDate = new Date();

    if (currentDate.getMonth() + 1 > fnacDate.getMonth() + 1) {
      return currentDate.getFullYear() - fnacDate.getFullYear();
    } else {
      return currentDate.getFullYear() - fnacDate.getFullYear();
    }
  }

  const procesarDatos = (zona) => {
    var index = 0;
    var nuevaZona = [];
    while (index < gruposEtareos.length) {
      nuevaZona = [
        ...nuevaZona,
        separarPorEdad(
          zona,
          gruposEtareos[index].min,
          gruposEtareos[index].max
        ),
      ];
      index += 1;
    }
    console.log(nuevaZona);
  };

  const crearGrupoEtareo = (benefs, grupo, zona) => {
    var nuevoGrupo = {
      id: grupo,
      zona,
      tf: 0,
      tm: 0,
      tt: 0,
      ff: 0,
      fm: 0,
      ft: 0,
      cf: 0,
      cm: 0,
      ct: 0,
    };

    benefs.forEach(function (benef) {
      if (benef.paren === 0) {
        nuevoGrupo = agregarBenef(benef, nuevoGrupo, "1");
      } else if (benef.paren < 5 || benef.paren === 9) {
        nuevoGrupo = agregarBenef(benef, nuevoGrupo, "2");
      } else {
        nuevoGrupo = agregarBenef(benef, nuevoGrupo, "3");
      }
    });

    return nuevoGrupo;
  };

  const agregarBenef = (benef, grupo, tipo) => {
    const tipoSexo = benef.sexo + tipo;
    const { tm, fm, om, tf, ff, cf } = grupo;

    switch (tipoSexo) {
      case "M1":
        return { ...grupo, tm: tm + 1 };
      case "M2":
        return { ...grupo, fm: fm + 1 };
      case "M3":
        return { ...grupo, om: om + 1 };
      case "F1":
        return { ...grupo, tf: tf + 1 };
      case "F2":
        return { ...grupo, ff: ff + 1 };
      case "F3":
        return { ...grupo, cf: cf + 1 };
    }
  };

  const separarPorEdad = (zona, min, max) => {
    var filtrados = [];
    if (max === "75+") {
      filtrados = zona.filter((benef) => Number(edad(benef.fnacim)) >= min);
    } else {
      filtrados = zona.filter(
        (benef) =>
          Number(edad(benef.fnacim)) >= min && Number(edad(benef.fnacim)) <= max
      );
    }

    return filtrados;
  };

  // Armar función para que itere cada array de cada zona y agarre cada grupo etareo de cada zona para hacer un nuevo array con objetos de los grupos etareos

  // ------------------------------------------------------------------------------------
  const rangosEtariosArray = [
    "0-4",
    "5-9",
    "10-14",
    "15-19",
    "20-24",
    "25-29",
    "30-34",
    "35-39",
    "40-44",
    "45-49",
    "50-54",
    "55-59",
    "60-64",
    "65-69",
    "70-74",
    "75+",
  ];

  function calcularRangoEtario(fechaNacimiento) {
    const hoy = new Date();
    const nacimiento = new Date(fechaNacimiento);
    const edad = hoy.getFullYear() - nacimiento.getFullYear();

    if (edad >= 0 && edad <= 4) {
      return "0-4";
    } else if (edad >= 5 && edad <= 9) {
      return "5-9";
    } else if (edad >= 10 && edad <= 14) {
      return "10-14";
    } else if (edad >= 15 && edad <= 19) {
      return "15-19";
    } else if (edad >= 20 && edad <= 24) {
      return "20-24";
    } else if (edad >= 25 && edad <= 29) {
      return "25-29";
    } else if (edad >= 30 && edad <= 34) {
      return "30-34";
    } else if (edad >= 35 && edad <= 39) {
      return "35-39";
    } else if (edad >= 40 && edad <= 44) {
      return "40-44";
    } else if (edad >= 45 && edad <= 49) {
      return "45-49";
    } else if (edad >= 50 && edad <= 54) {
      return "50-54";
    } else if (edad >= 55 && edad <= 59) {
      return "55-59";
    } else if (edad >= 60 && edad <= 64) {
      return "60-64";
    } else if (edad >= 65 && edad <= 69) {
      return "65-69";
    } else if (edad >= 70 && edad <= 74) {
      return "70-74";
    } else {
      return "75+";
    }
  }

  const crearGruposEtarios = (acumulador, objeto) => {
    const entradaExistente = acumulador.find(
      (entry) => entry.rangoEtario === "75+"
    );

    if (!entradaExistente) {
      rangosEtariosArray.map((rango, index) => {
        acumulador.push({
          rangoEtario: rango,
          datos: [],
        });
      });
    }
  };

  function agruparPorZonaYRango(datos) {
    return datos.map((zona) => ({
      ...zona,
      datos: zona.datos.reduce((acumulador, objeto) => {
        crearGruposEtarios(acumulador, objeto);
        const rangoEtario = calcularRangoEtario(objeto.fnacim);

        // Verifica si ya existe una entrada para el rango etario actual
        const entradaExistente = acumulador.find(
          (entry) => entry.rangoEtario === rangoEtario
        );

        if (entradaExistente) {
          // Si existe, agrega el objeto actual a esa entrada
          entradaExistente.datos.push(objeto);
        } else {
          // Si no existe, crea una nueva entrada
          acumulador.push({
            rangoEtario: rangoEtario,
            datos: [objeto],
          });
        }

        return acumulador;
      }, []),
    }));
  }

  // Función para agrupar por la propiedad "paren"
  const agruparPorParen = (acumulador, objeto) => {
    const { paren } = objeto;
    const parentesco = obtenerParentesco(paren);
    acumulador[parentesco] = acumulador[parentesco] || {
      datos: [],
      conteoSexo: { M: 0, F: 0, T: 0 },
    };

    // Incrementar el conteo de sexo
    const { sexo } = objeto;
    acumulador[parentesco].conteoSexo[sexo] =
      (acumulador[parentesco].conteoSexo[sexo] || 0) + 1;
    acumulador[parentesco].conteoSexo["T"] += 1;
    acumulador[parentesco].datos.push(objeto);
    return acumulador;
  };

  const obtenerParentesco = (paren) => {
    if (paren === "0") {
      return "Titular";
    } else if (paren < 5 || paren === 9) {
      return "Familiar";
    } else {
      return "aCargo";
    }
  };

  // Iterar sobre los datos y realizar la agrupación

  const devolverResultadoFinal = (datos) => {
    const separadoPorZonas = separarPorZonaNico(datos);
    const arrayAgrupadoPorZonaYRango = agruparPorZonaYRango(separadoPorZonas);
    const datosAgrupados = arrayAgrupadoPorZonaYRango.map((zona) => ({
      ...zona,
      datos: zona.datos.map((rangoEtario) => ({
        ...rangoEtario,
        datos: rangoEtario.datos.reduce(agruparPorParen, {}),
      })),
    }));
    const datosOrdenados = datosAgrupados.slice().sort((a, b) => {
      if (Number(a.codZona) < Number(b.codZona)) {
        return -1;
      }
      if (Number(a.codZona) > Number(b.codZona)) {
        return 1;
      }
      return 0;
    });
    console.log("datosOrdenados", datosOrdenados);
    return datosOrdenados;
  };

  return (
    <Card sx={{ padding: 2, mb: 2 }}>
      <Box sx={{ mt: 2 }}>
        <HelperText
          message={
            "Genera archivo para analisis de población beneficiaria, brindando de forma anonima, fecha de nacimiento, sexo y zona."
          }
        />
      </Box>
      {/* <EnDesarrollo /> */}
      {isLoading && (
        <Box sx={{ display: "flex", justifyContent: "center", my: 10 }}>
          <CircularProgress size={75} />
        </Box>
      )}
      {datos.length > 0 && (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              my: 5,
              gap: 2,
            }}
          >
            <LoadingButton
              variant="contained"
              color="success"
              onClick={generateAndDownloadExcel}
              loading={isDownloading}
              disabled={isDownloading}
              startIcon={<DownloadIcon />}
            >
              EXCEL
            </LoadingButton>
            <LoadingButton
              variant="contained"
              color="error"
              loading={isDownloading}
              disabled={isDownloading}
              onClick={generateAndDownloadPDF}
              startIcon={<DownloadIcon />}
            >
              PDF
            </LoadingButton>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <BuscadorTabla datos={datos} setter={setFiltrado} />
          </Box>
          <Box sx={{ mt: 3 }}>
            <DataGrid
              autoHeight
              sx={{ padding: 1 }}
              disableRowSelectionOnClick
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}
              initialState={{
                pagination: { paginationModel: { page: 0, pageSize: 10 } },
              }}
              rows={filtrado || []}
              columns={columns}
              pageSizeOptions={[5, 10, 25]}
            />
          </Box>
        </>
      )}
    </Card>
  );
}
