import { useState } from "react";
import {
  TextField,
  Card,
  Box,
  CircularProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
} from "@mui/material";
import { HelperText } from "../HelperText.jsx";
import { Form, FormikProvider, useFormik } from "formik";
import { useSnackbar } from "notistack";
import LoadingButton from "@mui/lab/LoadingButton";
import { DataGrid, esES } from "@mui/x-data-grid";
import hosteo from "../../../utils/hosteo.js";
import axios from "../../../utils/axios.js";
import * as XLSX from "../../../../node_modules/xlsx/xlsx.js";
import { fCurrency } from "../../../utils/formatNumber.js";
import DownloadIcon from "@mui/icons-material/Download";
import CustomLabel from "../../../theme/CustomLabel.js";
import { convertirCuit, fDateArgentina } from "../../../utils/formatNumber.js";
import { MonotributistasPDF } from "./pdf/MonotributistasPDF.jsx";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import useAuth from "../../../hooks/useAuth.js";
import { BuscadorTabla } from "../../BuscadorTabla.jsx";

function formatearPeriodo(fecha) {
  // Extrayendo año y mes
  let fecha_original = fecha.toString();
  let año = "20" + fecha_original.substring(0, 2);
  let mes = fecha_original.substring(2);

  // Formateando la fecha
  let fecha_formateada = año + "-" + mes;

  return fecha_formateada;
}

export default function MonotribYGpoFamCard() {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [datos, setDatos] = useState([]);
  const [open, setOpen] = useState(false);
  const [consumos, setConsumos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filtrado, setFiltrado] = useState([]);
  const [periodoSelected, setPeriodoSelected] = useState("");
  const { user } = useAuth();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      periodo: "",
    },

    onSubmit: async (values) => {
      setDatos([]);
      try {
        setIsLoading(true);
        const response = await axios.post(
          `${hosteo}/api/empadronamiento/beneficiarios/getMonoAporteGrupFamiliar`,
          {
            periodo: values.periodo,
          }
        );
        const rowsWithId = response.data
          ? response.data.map((item, index) => ({
              ...item,
              id: item.index || index + 1,
            }))
          : [];

        setPeriodoSelected(values.periodo);
        setDatos(rowsWithId);
        setFiltrado(rowsWithId);
      } catch (error) {
        enqueueSnackbar(error.message || "Algo salió mal", {
          variant: "error",
        });
      } finally {
        setIsLoading(false);
      }
    },
  });
  const { handleSubmit, getFieldProps, touched, errors } = formik;

  const generateAndDownloadExcel = () => {
    let nombre = "Monotribu aport y gpo familiar";

    const workbook = XLSX.utils.book_new();

    // Crea una nueva hoja de cálculo con datos
    const dia = new Date();
    const options = {
      timeZone: "America/Buenos_Aires",
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };

    // Encabezado original
    const encabezado = [
      [`Reporte de ${nombre}`],
      [
        `Generado a las ${dia
          .toLocaleTimeString("es-AR", options)
          .slice(0, 5)}hs del ${dia.toLocaleDateString()}`,
      ],
      [""],
    ];

    // Títulos de las columnas
    const columnTitles = [
      "Beneficiario",
      "Cuil",
      "Nombre",
      "Pariente",
      "Importe",
    ];

    // Unir encabezado original con nuevos títulos de columna
    const datosConTitulos = [...encabezado, columnTitles];

    // Agregar datos a la hoja de cálculo
    datos.forEach((item) => {
      const rowData = [
        item.nbenef,
        item.cuil,
        item.nombre,
        item.parien,
        item.importe,
      ];
      datosConTitulos.push(rowData);
    });

    const worksheet = XLSX.utils.aoa_to_sheet(datosConTitulos);
    XLSX.utils.book_append_sheet(workbook, worksheet, nombre);
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${nombre}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const generateAndDownloadPDF = async () => {
    const blob = await pdf(
      <MonotributistasPDF
        datosPDF={datos}
        periodo={periodoSelected}
        user={user}
      />
    ).toBlob();
    saveAs(
      blob,
      `Monotributistas aporte y grupo familiar - ${new Date().toLocaleDateString()}`
    );
  };

  const columns = [
    {
      field: "nbenef",
      headerName: "Beneficiario",
      flex: 1,
      minWidth: 150,
      editable: false,
      headerAlign: "right",
      align: "right",
    },
    {
      field: "cuil",
      headerName: "CUIL",
      flex: 1,
      minWidth: 120,
      editable: false,
      headerAlign: "right",
      align: "right",
      valueFormatter: (params) => convertirCuit(params.value),
    },
    {
      field: "nombre",
      headerName: "Nombre",
      flex: 1,
      minWidth: 120,
      editable: false,
    },
    {
      field: "parien",
      headerName: "Pariente",
      flex: 1,
      minWidth: 120,
      editable: false,
      type: "number",
    },
    {
      field: "importe",
      headerName: "Importe",
      flex: 1,
      minWidth: 120,
      editable: false,
      type: "number",
      valueFormatter: (params) => fCurrency(params.value),
    },
    {
      field: "options",
      headerName: "",
      minWidth: 100,
      editable: false,
      type: "number",
      renderCell: (params) => (
        <LoadingButton
          variant="contained"
          loading={loading}
          onClick={() => handleViewConsumos(params.row.numdoc)}
        >
          Consumos
        </LoadingButton>
      ),
    },
  ];

  const columnsConsumos = [
    {
      field: "fecha",
      headerName: "Fecha",
      minWidth: 100,
      editable: false,
      headerAlign: "right",
      align: "right",
      valueFormatter: (params) => fDateArgentina(params.value),
    },
    {
      field: "prestacion",
      headerName: "Prestacion",
      flex: 1,
      minWidth: 150,
      editable: false,
      headerAlign: "right",
      align: "right",
    },
    {
      field: "observa",
      headerName: "Observacion",
      flex: 1,
      minWidth: 150,
      editable: false,
    },
    {
      field: "medaut",
      headerName: "Med. Aut.",
      flex: 1,
      minWidth: 150,
      editable: false,
    },
    {
      field: "institu",
      headerName: "Institu.",
      flex: 1,
      minWidth: 150,
      editable: false,
    },
  ];

  const handleViewConsumos = async (numdoc) => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${hosteo}/api/empadronamiento/beneficiarios/getConsumos`,
        {
          numdoc,
          periodo: formatearPeriodo(periodoSelected),
        }
      );
      setConsumos(
        response.data.map((item, index) => ({
          ...item,
          id: index + 1,
        }))
      );
      setOpen(true);
      setLoading(false);
    } catch (error) {
      setConsumos([]);
      setOpen(false);
      setLoading(false);
      enqueueSnackbar(error.message || "Algo salió mal", {
        variant: "error",
      });
    }
  };

  return (
    <>
      <Card>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Box sx={{ mt: 2 }}>
              <HelperText
                message={
                  "Busqueda por periodo, el listado de monotributistas, para chequear lo que aporta y la cantidad de familiares que tiene "
                }
              />
            </Box>
            <Box
              sx={{
                p: 3,
                display: "flex",
                gap: 3,
                justifyContent: "center",
                "@media (max-width: 600px)": {
                  flexDirection: "column",
                  alignItems: "center",
                },
              }}
            >
              <TextField
                disabled={isLoading}
                sx={{
                  width: "400px",
                  "@media (max-width: 600px)": {
                    width: "250px",
                  },
                }}
                key="periodo1"
                label={<CustomLabel>Periodo</CustomLabel>}
                type="number"
                {...getFieldProps("periodo")}
                error={Boolean(touched.periodo && errors.periodo)}
                helperText={touched.periodo && errors.periodo}
              />
              <Box sx={{ height: "52px", display: "flex", gap: 3 }}>
                <LoadingButton
                  sx={{ height: "100%" }}
                  loading={isLoading}
                  variant="contained"
                  type="submit"
                >
                  Buscar
                </LoadingButton>
              </Box>
            </Box>
          </Form>
        </FormikProvider>
        {isLoading && (
          <Box sx={{ display: "flex", justifyContent: "center", my: 10 }}>
            <CircularProgress size={75} />
          </Box>
        )}
        {datos.length > 0 && (
          <Box sx={{ p: 4 }}>
            <Box
              sx={{ display: "flex", justifyContent: "center", my: 5, gap: 2 }}
            >
              <LoadingButton
                variant="contained"
                color="success"
                onClick={generateAndDownloadExcel}
                startIcon={<DownloadIcon />}
              >
                EXCEL
              </LoadingButton>
              <LoadingButton
                variant="contained"
                color="error"
                onClick={generateAndDownloadPDF}
                startIcon={<DownloadIcon />}
              >
                PDF
              </LoadingButton>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <BuscadorTabla datos={datos} setter={setFiltrado} />
            </Box>
            <DataGrid
              sx={{ padding: 1 }}
              autoHeight
              disableRowSelectionOnClick
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}
              rows={filtrado}
              columns={columns}
              pageSizeOptions={[5, 10, 25]}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 25,
                  },
                },
              }}
            />
          </Box>
        )}
      </Card>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth={"xl"}
        fullWidth
      >
        <DialogTitle sx={{ textAlign: "center" }}>
          {consumos[0]?.nombre} - DNI: {consumos[0]?.ndoc}
        </DialogTitle>
        <DialogContent>
          {consumos?.length > 0 ? (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <DataGrid
                sx={{ padding: 1 }}
                autoHeight
                disableRowSelectionOnClick
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                rows={consumos}
                columns={columnsConsumos}
                pageSizeOptions={[5, 10, 25]}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 25,
                    },
                  },
                }}
              />
            </Box>
          ) : (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Typography variant="h4">No se encontraron consumos</Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
