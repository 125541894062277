import {
  Page,
  View,
  Text,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import { fCurrency } from "../../utils/formatNumber";

// ----------------------------------------------------------------------

Font.register({
  family: "Poppins",
  fonts: [
    {
      src: `${process.env.PUBLIC_URL}/static/fonts/Poppins/Poppins-Regular.ttf`,
    },
    { src: `${process.env.PUBLIC_URL}/static/fonts/Poppins/Poppins-Bold.ttf` },
  ],
});

const styles = StyleSheet.create({
  col4: { width: "25%" },
  col8: { width: "75%" },
  col6: { width: "50%", marginRight: 4 },
  mb2: { marginBottom: 2 },
  mb8: { marginBottom: 8 },
  mb40: { marginBottom: 40 },
  overline: {
    fontSize: 8,
    marginBottom: 8,
    fontWeight: "bold",
    letterSpacing: 1.2,
    textTransform: "uppercase",
  },
  h3: { fontSize: 16, fontWeight: "bold" },
  h4: { fontSize: 13, fontWeight: "bold" },
  body1: { fontSize: 10 },
  subtitle1: { fontSize: 9, fontWeight: "bold" },
  subtitle2: { fontSize: 9, fontWeight: "bold", textAlign: "right" },
  alignRight: { textAlign: "right" },
  alignCenter: { textAlign: "center" },
  page: {
    padding: "40px 24px 50px 24px",
    marginBottom: "20px",
    fontSize: 9,
    lineHeight: 1.6,
    backgroundColor: "#fff",
    textTransform: "none",
    fontFamily: "Poppins",
  },
  footer: {
    flexDirection: "row",
    gap: 4,
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 1,
    position: "absolute",
    bottom: 0,
    right: 0,
    padding: 24,
    textAlign: "right",
  },
  gridContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 4,
  },

  noBorder: { paddingTop: 8, paddingBottom: 0, borderBottomWidth: 0 },
  tableCell_1: { width: "40%", textAlign: "left" },
  tableCell_2: { width: "20%", textAlign: "right" },
  tableCell_1Total: { width: "40%", textAlign: "right", fontWeight: "bold" },
  tableCell_2Total: { width: "20%", textAlign: "right", fontWeight: "bold" },
  textDisclaimer: { marginTop: 2, textAlign: "center" },
  detailContainer: { marginBottom: 2, display: "flex", flexDirection: "row" },
  netoContainer: {
    marginTop: 13,
    marginBottom: 16,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  table: {
    display: "table",
    width: "auto",
    marginBottom: 10,
  },
  tableHeader: { backgroundColor: "#f2f2f2" },
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderColor: "#DFE3E8",
  },
  tableCell: {
    padding: 4,
    fontSize: 9,
    width: "auto",
  },
  tableCellHeader: {
    fontWeight: "bold",
  },
});

// ----------------------------------------------------------------------

export const RecibosPDF = ({ datosPDF, user, periodo, reporte }) => {
  const dia = new Date();
  const options = {
    timeZone: "America/Buenos_Aires",
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };

  return (
    <Document>
      <Page size="A4" style={styles.page} orientation="landscape">
        <View style={styles.gridContainer}>
          <Image
            source={`${process.env.PUBLIC_URL}/static/img/brands/logo.png`}
            style={{ width: 150, height: 30 }}
          />
          <View
            style={{ flex: 1, alignItems: "center", justifyContent: "center" }}
          >
            <Text style={styles.h3}>{reporte}</Text>
          </View>
          <View style={{ alignItems: "flex-end", flexDirection: "column" }}>
            <Text>
              {dia.toLocaleDateString()},{" "}
              {dia.toLocaleTimeString("es-AR", options).slice(0, 5)}
            </Text>
            <Text>
              {user.name} {user.lastname}
            </Text>
            <Text>Periodo: {periodo}</Text>
          </View>
        </View>

        <View style={styles.table}>
          <View style={styles.tableHeader} fixed>
            <View style={styles.tableRow}>
              <Text
                style={[
                  styles.tableCell,
                  styles.tableCellHeader,
                  { width: "15%" },
                ]}
              >
                Id
              </Text>
              <Text
                style={[
                  styles.tableCell,
                  styles.tableCellHeader,
                  { width: "25%" },
                ]}
              >
                Calle
              </Text>
              <Text
                style={[
                  styles.tableCell,
                  styles.tableCellHeader,
                  { width: "15%", textAlign: "right" },
                ]}
              >
                Código
              </Text>
              <Text
                style={[
                  styles.tableCell,
                  styles.tableCellHeader,
                  { width: "15%", textAlign: "right" },
                ]}
              >
                CUIT
              </Text>
              <Text
                style={[
                  styles.tableCell,
                  styles.tableCellHeader,
                  { width: "15%", textAlign: "right" },
                ]}
              >
                Fecha presentación
              </Text>
              <Text
                style={[
                  styles.tableCell,
                  styles.tableCellHeader,
                  { width: "15%", textAlign: "right" },
                ]}
              >
                Localidad
              </Text>
              <Text
                style={[
                  styles.tableCell,
                  styles.tableCellHeader,
                  { width: "15%" },
                ]}
              >
                Monto
              </Text>
              <Text
                style={[
                  styles.tableCell,
                  styles.tableCellHeader,
                  { width: "15%" },
                ]}
              >
                Numero
              </Text>
              <Text
                style={[
                  styles.tableCell,
                  styles.tableCellHeader,
                  { width: "15%" },
                ]}
              >
                Personas
              </Text>
              <Text
                style={[
                  styles.tableCell,
                  styles.tableCellHeader,
                  { width: "15%" },
                ]}
              >
                Razón
              </Text>
              <Text
                style={[
                  styles.tableCell,
                  styles.tableCellHeader,
                  { width: "15%" },
                ]}
              >
                Zona
              </Text>
            </View>
          </View>
          {datosPDF.map((item) => (
            <View style={styles.tableRow} key={item.ncheque}>
              <Text style={[styles.tableCell, { width: "15%" }]}>
                {item.id}
              </Text>
              <Text style={[styles.tableCell, { width: "25%" }]}>
                {item.calle}
              </Text>
              <Text
                style={[styles.tableCell, { width: "15%", textAlign: "right" }]}
              >
                {item.codigo}
              </Text>
              <Text
                style={[styles.tableCell, { width: "15%", textAlign: "right" }]}
              >
                {item.cuit}
              </Text>
              <Text
                style={[styles.tableCell, { width: "15%", textAlign: "right" }]}
              >
                {item.fecpre}
              </Text>
              <Text
                style={[styles.tableCell, { width: "15%", textAlign: "right" }]}
              >
                {item.localidad}
              </Text>
              <Text
                style={[styles.tableCell, { width: "15%", textAlign: "right" }]}
              >
                {fCurrency(item.monto)}
              </Text>
              <Text style={[styles.tableCell, { width: "15%" }]}>
                {item.numero}
              </Text>
              <Text
                style={[styles.tableCell, { width: "15%", textAlign: "right" }]}
              >
                {item.personas}
              </Text>
              <Text
                style={[styles.tableCell, { width: "15%", textAlign: "right" }]}
              >
                {item.razon}
              </Text>
              <Text
                style={[styles.tableCell, { width: "15%", textAlign: "right" }]}
              >
                {item.zona}
              </Text>
            </View>
          ))}
        </View>

        <View style={styles.footer}>
          <View>
            <Text
              render={({ pageNumber, totalPages }) =>
                `Página ${pageNumber} de ${totalPages}`
              }
              fixed
            />
          </View>
        </View>
      </Page>
    </Document>
  );
};
