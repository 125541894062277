import * as XLSX from "../../node_modules/xlsx/xlsx.js";
import { acortarPalabras } from "./nombreArchivos.js";

export function generateAndDowloadXLSXReporteYTransf({ data, columnaXSLXTYR }) {
  const dato = data?.map((item) =>
    columnaXSLXTYR?.map((column) => item[column])
  );

  console.log(columnaXSLXTYR);
  const ws = XLSX.utils.aoa_to_sheet([
    columnaXSLXTYR?.map((column) => column),
    ...dato,
  ]);
  const colWidths = columnaXSLXTYR?.map((column) => ({
    wch: 30,
  }));
  ws["!cols"] = colWidths;

  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "transfyreducdebenef");
  XLSX.writeFile(wb, `transfyreducdebenef.xlsx`);
}
export const generateAndDowloadXLSX = ({ data, columnXSLX, reporte }) => {
  const dato = data?.data?.map((item) =>
    columnXSLX.map((column) => item[column])
  );
  const ws = XLSX.utils.aoa_to_sheet([
    columnXSLX.map((column) => column),
    ...dato,
  ]);
  const colWidths = columnXSLX.map((column) => ({
    wch: column === "cuit" ? 15 : 10,
  }));
  ws["!cols"] = colWidths;
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, acortarPalabras(reporte));
  XLSX.writeFile(wb, `${acortarPalabras(reporte)}.xlsx`);
};
