import Cabecera from "../../../../components/Cabecera";
import PersonalBajaAlta from "../../../../components/Empadronamiento/CardsReportes/PersonalBajaAlta";
const items = [
  { linkTo: "/dashboard/home", nombre: "Home" },
  { linkTo: "/dashboard/empadronamiento", nombre: "Empadronamiento" },
  {
    linkTo: "/dashboard/empadronamiento/beneficiarios/reportebenf",
    nombre: "Reportes de beneficiacios",
  },
];

export default function TransfYReduc() {
  return (
    <>
      <Cabecera
        title="Personal de alta o baja por cambio de obra social"
        items={items}
        divider={false}
        helmet="Empadronamiento"
      />
      <PersonalBajaAlta />
    </>
  );
}
