import React from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

import { Paper, Typography, Box } from "@mui/material";

import SignInComponent from "../../components/auth/SignIn";

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;
const SignIn = () => {
  return (
    <>
      <Box>
        <img
          alt=""
          src="../static/img/brands/logo.png"
          style={{
            width: "268px",
            height: "58px",
            marginBottom: "10px",
          }}
        />
      </Box>
      <Wrapper>
        <Helmet title="Ingreso" />

        <Typography component="h1" variant="h4" align="center" gutterBottom>
          ¡Bienvenido!
        </Typography>
        <Typography component="h2" variant="body1" align="center">
          Ingresa con tu cuenta para continuar
        </Typography>

        <SignInComponent />
      </Wrapper>
    </>
  );
};

export default SignIn;
