import { format } from "date-fns";
import { replace } from "lodash";
import { es } from "date-fns/locale";
import numeral from "numeral";

// ----------------------------------------------------------------------

export function fCurrency(number) {
  return Number(number).toLocaleString("es-AR", {
    style: "currency",
    currency: "ARS",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

export function fCurrencyNoFloor(number) {
  return Number(number).toLocaleString("es-AR", {
    style: "currency",
    currency: "ARS",
    minimumFractionDigits: 2,
  });
}

export function fPercent(number) {
  return numeral(number / 100).format("0.0%");
}

export function fDateArgentina(date) {
  if (!date) {
    return "";
  }
  if (date === "0000-00-00") {
    return "/ /";
  }
  if (date.length === 10) {
    return format(new Date(`${date} 00:00`), "dd/MM/yyyy", { locale: es });
  } else {
    return format(new Date(date), "dd/MM/yyyy", { locale: es });
  }
}

export function fTime(number) {
  return number.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });
}

export function fRound(numero) {
  return Math.round(numero * 100) / 100;
}

export function fNumber(number) {
  return numeral(number).format("0,0");
}

export function fShortenNumber(number) {
  return replace(numeral(number).format("0.00a"), ".00", "");
}

export function fData(number) {
  return numeral(number).format("0.0 b");
}

export function fDateDifHoras(date) {
  if (date?.length === 10) {
    return new Date(`${date} 00:00`);
  } else if (!date) {
    return null;
  } else {
    return new Date(date);
  }
}

export function fDate(date) {
  if (!date) {
    return null;
  }
  return format(fDateDifHoras(date), "yyyy-MM-dd");
}
export function fDate2(date) {
  const dateObj = new Date(date);
  return format(dateObj, "dd-MM-yyyy");
}

export function formatDate(inputDate) {
  if (inputDate && typeof inputDate === "string") {
    const [datePart] = inputDate.split(" ");
    const [year, month, day] = datePart.split("-");

    return `${day}/${month}/${year}`;
  } else {
    return "";
  }
}
export function formatearDates(inputDate) {
  const date = new Date(inputDate);
  if (isNaN(date.getTime())) {
    return "Fecha inválida";
  }
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
}

export function convertirCuit(numero) {
  if (numero) {
    const numeroString = numero.toString();
    const parte1 = numeroString.slice(0, 2);
    const parte2 = numeroString.slice(2, 10);
    const parte3 = numeroString.slice(10, 11);

    return `${parte1}-${parte2}-${parte3}`;
  }
  return "";
}

export function dniFormatter(dni) {
  if (dni) {
    return String(dni).replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }
  return "";
}

export function convertirFecha(fechaString) {
  if (fechaString === null) {
    return null;
  }

  if (fechaString.length === 10) {
    return new Date(`${fechaString} 00:00`);
  } else {
    return null;
  }

  // const fecha = new Date(fechaString);
  // const diasSemana = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  // const meses = [
  //   "Jan",
  //   "Feb",
  //   "Mar",
  //   "Apr",
  //   "May",
  //   "Jun",
  //   "Jul",
  //   "Aug",
  //   "Sep",
  //   "Oct",
  //   "Nov",
  //   "Dec",
  // ];

  // // Obtener los valores de día, mes y año de la fecha
  // const diaSemana = diasSemana[fecha.getDay()];
  // const mes = meses[fecha.getMonth()];
  // const dia = fecha.getDate();
  // const año = fecha.getFullYear();

  // // Formatear la fecha según el formato deseado
  // const fechaFormateada = `${diaSemana} ${mes} ${dia} ${año} 00:00:00 GMT${
  //   fecha.getTimezoneOffset() > 0 ? "-" : "+"
  // }${Math.abs(fecha.getTimezoneOffset() / 60)
  //   .toString()
  //   .padStart(2, "0")}:00`;

  // return new Date(fechaFormateada);
}

export function convertirFechaSend(fechaInput) {
  if (fechaInput == null) {
    return null;
  }
  const fecha = new Date(fechaInput);

  const year = fecha.getFullYear();
  const month = (fecha.getMonth() + 1).toString().padStart(2, "0");
  const day = fecha.getDate().toString().padStart(2, "0");

  // Formatear la fecha como "YYYY-MM-DD"
  return `${year}-${month}-${day}`;
}
