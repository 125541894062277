import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  CircularProgress,
} from "@mui/material";
import CustomLabel from "../../../theme/CustomLabel";
import { useSnackbar } from "notistack";
import DownloadIcon from "@mui/icons-material/Download";
import {
  fDateArgentina,
  fCurrency,
  convertirCuit,
} from "../../../utils/formatNumber";
import axios from "../../../utils/axios";
import hosteo from "../../../utils/hosteo";
import { ModalBeneficiarios } from "../EmpReduc/ModalBeneficiarios";
import { DataGrid, esES } from "@mui/x-data-grid";
import { BenefPDF } from "../EmpReduc/BenefPDF";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import { HelperText } from "../HelperText.jsx";
import * as XLSX from "../../../../node_modules/xlsx/xlsx.js";

const columns = [
  {
    field: "periodo",
    headerName: "Periodo",
    flex: 1,
    minWidth: 160,
    editable: false,
  },
  {
    field: "cuit",
    headerName: "Cuit",
    headerAlign: "right",
    align: "right",
    width: 150,
    valueFormatter: (params) => convertirCuit(params.value),
    editable: false,
  },
  {
    field: "razon",
    headerName: "Razon",
    flex: 1,
    minWidth: 160,
    editable: false,
  },
  {
    field: "situacio",
    headerName: "Concep",
    headerAlign: "right",
    align: "right",
    width: 150,
    editable: false,
  },
  {
    field: "fecpro",
    headerName: "Fecha",
    headerAlign: "right",
    align: "right",
    valueFormatter: (params) => fDateArgentina(params.value),
    width: 150,
    editable: false,
  },
  {
    field: "reimpo",
    headerName: "Importe",
    headerAlign: "right",
    valueFormatter: (params) => fCurrency(params.value),
    align: "right",
    width: 150,
    editable: false,
  },
];

function agruparPorCuit(objetos) {
  return objetos.reduce((agrupados, objeto) => {
    // Verifica si ya existe un grupo para el 'cuit' actual
    if (!agrupados[objeto.cuit]) {
      // Si no existe, crea un nuevo grupo con un array vacío
      agrupados[objeto.cuit] = [];
    }
    // Agrega el objeto al grupo correspondiente
    agrupados[objeto.cuit].push(objeto);
    return agrupados;
  }, {});
}

export default function BenefTransfReducCard() {
  const [dato, setDato] = useState("");
  const [datosEmpresa, setDatosEmpresa] = useState([]);
  const [type, setType] = useState("cuil");
  const [loading, setLoading] = useState(false);
  const [beneficiario, setBeneficiario] = useState([]);
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);

  const { enqueueSnackbar } = useSnackbar();

  const handleConsultar = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${hosteo}/api/empadronamiento/getBenefReduc`,
        {
          id: dato,
          type,
        }
      );
      setLoading(false);
      if (response.data.beneficiarios?.length > 0) {
        setRows(response.data.beneficiarios);
        setOpen(true);
      } else {
        setBeneficiario(response.data.beneficiario);
        setDatosEmpresa(
          response.data.reducciones.map((element, index) => ({
            ...element,
            id: index + 1,
          }))
        );
      }
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(error.message || "No hay conexion a internet", {
        variant: "error",
      });
    }
  };

  const handleSearch = async (cuil) => {
    try {
      setLoading(true);
      setOpen(false);
      const response = await axios.post(
        `${hosteo}/api/empadronamiento/getBenefReduc`,
        {
          id: cuil,
          type: "cuil",
        }
      );
      setLoading(false);
      setBeneficiario(response.data.beneficiario);
      setDatosEmpresa(
        response.data.reducciones.map((element, index) => ({
          ...element,
          id: index + 1,
        }))
      );
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(error.message || "No hay conexion a internet", {
        variant: "error",
      });
    }
  };

  const handlePaginationChange = (params) => {
    if (params.pageSize !== pageSize) {
      setPageSize(params.pageSize);
      setCurrentPage(0);
    } else {
      setCurrentPage(params.page);
    }
  };

  const generateAndDownloadPDF = async () => {
    const datos = agruparPorCuit(datosEmpresa);

    const blob = await pdf(
      <BenefPDF datosPDF={datos} beneficiario={beneficiario} />
    ).toBlob();
    saveAs(
      blob,
      `Reduccion por beneficiario - ${new Date().toLocaleDateString()}`
    );
  };

  const generateAndDownloadExcel = () => {
    let nombre = "Transferencias de beneficiarios";

    const workbook = XLSX.utils.book_new();

    // Crea una nueva hoja de cálculo con datos
    const dia = new Date();
    const options = {
      timeZone: "America/Buenos_Aires",
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
    const encabezado = [
      [`Reporte de ${nombre}`],
      [
        `Generado a las ${dia
          .toLocaleTimeString("es-AR", options)
          .slice(0, 5)}hs del ${dia.toLocaleDateString()}`,
      ],
      [""],
    ];
    const worksheet = XLSX.utils.aoa_to_sheet(encabezado);
    XLSX.utils.sheet_add_json(worksheet, datosEmpresa, { origin: -1 });
    XLSX.utils.book_append_sheet(workbook, worksheet, nombre);
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${nombre}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <HelperText
          message={
            "Trae todos las declaraciones jurada y pagos realizados por el beneficiario y su empleador"
          }
        />
      </Box>
      <Card
        sx={{
          padding: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <TextField
              type={type === "nombre" ? "text" : "number"}
              variant="outlined"
              label={[<CustomLabel>{type.toUpperCase()}</CustomLabel>]}
              onChange={(e) => {
                setDato(e.target.value);
              }}
            />
            <FormControl>
              <FormLabel
                id="demo-radio-buttons-group-label"
                sx={{ textAlign: "center" }}
              >
                Modo de búsqueda de beneficiario
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={type}
                onChange={(e) => {
                  setType(e.target.value);
                }}
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value="cuil"
                  control={<Radio />}
                  label="CUIL"
                />
                <FormControlLabel
                  value="documento"
                  control={<Radio />}
                  label="Número de documento"
                />
                <FormControlLabel
                  value="nombre"
                  control={<Radio />}
                  label="Nombre parci"
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <LoadingButton
            variant="contained"
            onClick={handleConsultar}
            loading={loading}
            sx={{ mb: 2 }}
          >
            Consultar
          </LoadingButton>
        </Box>
        <ModalBeneficiarios
          open={open}
          handleSearch={handleSearch}
          datos={rows}
          handleClose={() => setOpen(false)}
        />
        {loading && (
          <Box sx={{ display: "flex", justifyContent: "center", my: 10 }}>
            <CircularProgress size={75} />
          </Box>
        )}
        {datosEmpresa.length > 0 && (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: 2,
                gap: 5,
                mb: 2,
              }}
            >
              <Button
                variant="contained"
                color="error"
                onClick={generateAndDownloadPDF}
                startIcon={<DownloadIcon />}
              >
                PDF
              </Button>
              <LoadingButton
                variant="contained"
                color="success"
                onClick={generateAndDownloadExcel}
                startIcon={<DownloadIcon />}
              >
                EXCEL
              </LoadingButton>
            </Box>
            <DataGrid
              autoHeight
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}
              disableRowSelectionOnClick
              onPaginationModelChange={(row) => handlePaginationChange(row)}
              onPageChange={(newPage) => setCurrentPage(newPage)}
              paginationModel={{
                page: currentPage,
                pageSize: pageSize,
              }}
              rows={datosEmpresa}
              columns={columns}
              initialState={{
                pagination: { paginationModel: { page: 0, pageSize: 10 } },
              }}
              pageSizeOptions={[10, 25, 50, 100]}
            />
          </>
        )}
      </Card>
    </>
  );
}
