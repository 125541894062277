import * as Yup from "yup";
import { useState } from "react";
import { Form, FormikProvider, useFormik } from "formik";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import axios from "../../utils/axios";
import hosteo from "../../utils/hosteo";
import CustomLabel from "../../theme/CustomLabel";
// ------------------------------------

const validationSchemaCreate = Yup.object().shape({
  dcuit: Yup.number()
    .required("El CUIT es requerido")
    .test(
      "len",
      "El CUIT debe tener exactamente 11 dígitos",
      (val) => val.toString().length === 11
    ),
  acuit: Yup.number()
    .required("El CUIT es requerido")
    .test(
      "len",
      "El CUIT debe tener exactamente 11 dígitos",
      (val) => val.toString().length === 11
    ),
});

export default function MegacambioCuit({ open, close }) {
  const [loading, setLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    formik.resetForm();
    close();
  };

  const formik = useFormik({
    validationSchema: validationSchemaCreate,
    enableReinitialize: true,
    initialValues: {
      dcuit: "",
      acuit: "",
    },
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const response = await axios.post(
          `${hosteo}/api/empadronamiento/megacambioCuit`,
          {
            ...values,
          }
        );
        enqueueSnackbar(
          response.data.message || "Cuit modificado exitosamente",
          {
            variant: "success",
          }
        );
        setLoading(false);
        close();
        formik.resetForm();
      } catch (error) {
        setLoading(false);
        enqueueSnackbar(error.message || "No tienes conexion a internet", {
          variant: "error",
        });
      }
    },
  });
  const { getFieldProps, handleSubmit, setFieldValue, values } = formik;

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth={"sm"}
      keepMounted
      onClose={close}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Typography variant="h5" sx={{ p: 2, textAlign: "center" }}>
            Megacambio de CUIT
          </Typography>

          <DialogContent
            sx={{ display: "flex", flexDirection: "column", gap: 3 }}
          >
            <TextField
              {...getFieldProps("dcuit")}
              type="number"
              error={Boolean(formik.touched.dcuit && formik.errors.dcuit)}
              helperText={formik.touched.dcuit && formik.errors.dcuit}
              label={<CustomLabel>De cuit</CustomLabel>}
            />
            <TextField
              {...getFieldProps("acuit")}
              type="number"
              error={Boolean(formik.touched.acuit && formik.errors.acuit)}
              helperText={formik.touched.acuit && formik.errors.acuit}
              label={<CustomLabel>A cuil</CustomLabel>}
            />
          </DialogContent>
          <DialogActions>
            <Button color="inherit" onClick={handleClose}>
              Cerrar
            </Button>
            <LoadingButton loading={loading} variant="contained" type="submit">
              Guardar
            </LoadingButton>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  );
}
