import { Bar } from "react-chartjs-2";

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Parientes por nacionalidad",
    },
  },
};

export default function ParenExtranjerosGraph({ datos }) {
  const labels = datos?.map((element) => element.nacional);
  const data = {
    labels,
    datasets: [
      {
        label: "Cantidad",
        data: datos?.map((element) => element.parientesExtranjeros),
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  return <Bar options={options} data={data} />;
}
