import {
  Card,
  Grid,
  Typography,
  Divider as MuiDivider,
  Breadcrumbs as MuiBreadcrumbs,
  Link as MuiLink,
  CircularProgress,
  Box,
  Autocomplete,
  TextField,
  Container,
} from "@mui/material";
import styled from "@emotion/styled";
import DownloadIcon from "@mui/icons-material/Download.js";
import { spacing } from "@mui/system";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
import hosteo from "../../../utils/hosteo";
import axios from "../../../utils/axios.js";
import { LoadingButton } from "@mui/lab";
import { DataGrid, esES } from "@mui/x-data-grid";
import * as XLSX from "../../../../node_modules/xlsx/xlsx.js";
import CustomLabel from "../../../theme/CustomLabel";
import { enqueueSnackbar } from "notistack";

const Divider2 = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Cabecera = () => {
  return (
    <Grid justifyContent="space-between" container spacing={6}>
      <Grid item>
        <Typography variant="h3" gutterBottom>
          Afiliaciones
        </Typography>
        <Breadcrumbs aria-label="Breadcrumb" mt={2}>
          <MuiLink component={NavLink} to="/dashboard/home">
            Home
          </MuiLink>
          <MuiLink component={NavLink} to="/dashboard/empadronamiento">
            Empadronamiento
          </MuiLink>
          <Typography>Afiliaciones</Typography>
        </Breadcrumbs>
      </Grid>
    </Grid>
  );
};

export default function Afiliaciones({ tabControl }) {
  const [empresas, setEmpresas] = useState([]);
  const [provincias, setProvincias] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [provincia, setProvincia] = useState();

  useEffect(() => {
    const getProvincias = async () => {
      try {
        const response = await axios.get(`${hosteo}/api/generic/getProvincias`);
        setProvincias(response.data);
      } catch (error) {
        enqueueSnackbar(error.message || "No tienes conexión a internet", {
          variant: "error",
        });
      }
    };
    getProvincias();
  }, []);

  const getEmpresas = async (provincia) => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        `${hosteo}/api/dashboard/afiliaciones/getEmpresas`,
        { provincia: provincia.codigo }
      );
      setEmpresas(
        response.data.map((item, index) => ({
          ...item,
          id: item.index ? item.index : index + 1,
        }))
      );
      setProvincia(provincia.provincia);
    } catch (error) {
      enqueueSnackbar(error.message || "No tienes conexión a internet", {
        variant: "error",
      });
      setProvincia("");
      setEmpresas([]);
    } finally {
      setIsLoading(false);
    }
  };

  const columns = [
    {
      field: "razon",
      headerName: "Razón social",
      flex: 1,
      minWidth: 150,
      editable: false,
    },
    {
      field: "fantasia",
      headerName: "Fantasia",
      flex: 1,
      minWidth: 120,
      editable: false,
    },
    {
      field: "calle",
      headerName: "Dirección",
      flex: 1,
      minWidth: 120,
      editable: false,
    },
    {
      field: "localidad",
      headerName: "Localidad",
      flex: 1,
      minWidth: 120,
      editable: false,
    },
    {
      field: "telefono",
      headerName: "Teléfono",
      flex: 1,
      minWidth: 120,
      editable: false,
    },
    {
      field: "qBenef",
      headerName: "Afiliados",
      flex: 1,
      minWidth: 120,
      editable: false,
    },
  ];

  //-------------------------Descarga de Excel------------------------------
  const generateAndDownloadExcel = () => {
    setIsDownloading(true);
    let nombre = "Afiliaciones de empresas";

    const workbook = XLSX.utils.book_new();

    // Crea una nueva hoja de cálculo con datos
    const dia = new Date();
    const options = {
      timeZone: "America/Buenos_Aires",
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
    const encabezado = [
      [`Reporte de ${nombre} de ${provincia}`],
      [
        `Generado a las ${dia
          .toLocaleTimeString("es-AR", options)
          .slice(0, 5)}hs del ${dia.toLocaleDateString()}`,
      ],
      [""],
    ];

    // Títulos de las columnas
    const columnTitles = [
      "Razón social",
      "Fantasia",
      "Calle",
      "Localidad",
      "Teléfono",
      "Afiliados",
    ];

    // Unir encabezado original con nuevos títulos de columna
    const datosConTitulos = [...encabezado, columnTitles];

    // Agregar datos a la hoja de cálculo
    empresas.forEach((item) => {
      const rowData = [
        item.razon,
        item.fantasia,
        item.calle,
        item.localidad,
        item.telefono,
        item.qBenef,
      ];
      datosConTitulos.push(rowData);
    });

    const worksheet = XLSX.utils.aoa_to_sheet(datosConTitulos);
    XLSX.utils.book_append_sheet(workbook, worksheet, nombre);
    const colWidths = columnTitles?.map((column) => ({
      wch: column.includes("Razón") || column.includes("Calle") ? 40 : 20,
    }));
    worksheet["!cols"] = colWidths;
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${nombre} de ${provincia}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setIsDownloading(false);
  };

  //----------------------------------------------------------------------
  return (
    <>
      {!tabControl && (
        <>
          <Helmet title="Empadronamiento" />
          <Cabecera />
          <Divider2 my={2} />
        </>
      )}
      <Card sx={{ padding: 4, mb: 2 }}>
        <Grid container md={12} justifyContent="center">
          {tabControl && (
            <Grid container md={12} justifyContent="center">
              <Typography variant="h3" textAlign="center" mb={3}>
                Afiliaciones de empresas
              </Typography>
            </Grid>
          )}
          <Grid md={3}>
            <Autocomplete
              options={provincias}
              getOptionLabel={(option) => option.provincia}
              getOptionValue={(option) => option.codigo}
              disablePortal
              sx={{ width: "100%" }}
              autoHighlight
              autoSelect
              disabled={!provincias}
              onChange={(e, value) => getEmpresas(value)}
              renderInput={(params) => (
                <TextField
                  sx={{ p: 0 }}
                  {...params}
                  label={[<CustomLabel>Seleccione provincia</CustomLabel>]}
                />
              )}
            />
          </Grid>
        </Grid>
        {isLoading ? (
          <Box sx={{ display: "flex", justifyContent: "center", my: 10 }}>
            <CircularProgress size={75} />
          </Box>
        ) : (
          empresas.length > 0 && (
            <Grid container md={12}>
              <Grid
                container
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  my: 5,
                  gap: 2,
                }}
              >
                <LoadingButton
                  variant="contained"
                  color="success"
                  onClick={generateAndDownloadExcel}
                  loading={isDownloading}
                  disabled={isDownloading}
                  startIcon={<DownloadIcon />}
                >
                  EXCEL
                </LoadingButton>
              </Grid>
              <Container mt={3} maxWidth={"xl"}>
                <DataGrid
                  autoHeight
                  sx={{ padding: 1 }}
                  disableRowSelectionOnClick
                  localeText={
                    esES.components.MuiDataGrid.defaultProps.localeText
                  }
                  initialState={{
                    pagination: { paginationModel: { page: 0, pageSize: 10 } },
                  }}
                  rows={empresas || []}
                  columns={columns}
                  pageSizeOptions={[5, 10, 25]}
                />
              </Container>
            </Grid>
          )
        )}
      </Card>
    </>
  );
}
