import { useState } from "react";
import {
  Breadcrumbs,
  Grid,
  Typography,
  Link as MuiLink,
  Divider,
} from "@mui/material";
import CardReportes from "./CardsReportes/CardReportes";
import FolderSharedIcon from "@mui/icons-material/FolderShared";
import GroupIcon from "@mui/icons-material/Group";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import ContactsIcon from "@mui/icons-material/Contacts";
import FamilyRestroomIcon from "@mui/icons-material/FamilyRestroom";
import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import EditNoteIcon from "@mui/icons-material/EditNote";
import PublicIcon from "@mui/icons-material/Public";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import ElderlyWomanIcon from "@mui/icons-material/ElderlyWoman";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import { Helmet } from "react-helmet-async";
import { NavLink } from "react-router-dom";

export default function ReportesDeBeneficiarios() {
  const cards = [
    {
      titulo: "Padron",
      icono: <FolderSharedIcon sx={{ color: "#4782da", fontSize: "80px" }} />,
      link: "padron",
      description:
        "Descargara el padron para entregar Farmanexus, Emergencias o al Colegio de Farmaceuticos en los primeros días del mes.",
    },
    {
      titulo: "Consulta por familiares",
      icono: <GroupIcon sx={{ color: "#4782da", fontSize: "80px" }} />,
      description:
        "Mostrara la cantidad de tipo de familiar que existen en cada una de las zonas. - DEV: FALTA NOMBRE DE ZONA",
      link: "consultaPorFamiliares",
    },
    {
      titulo: "Total de titulares y familiares",
      icono: <GroupIcon sx={{ color: "#4782da", fontSize: "80px" }} />,
      link: "totalTitulares",
      description:
        "Cantidad total a este momento, de beneficiarios. Abarcando tanto titulares como familiares.",
    },
    {
      titulo: "Poblacion beneficiaria",
      icono: <Diversity3Icon sx={{ color: "#4782da", fontSize: "80px" }} />,
      link: "poblacion",
      description:
        "Genera archivo para analisis de población beneficiaria, brindando de forma anonima, fecha de nacimiento, sexo y zona.",
    },
    {
      titulo: "Beneficiarios entre numero y declaracion",
      icono: <ContactsIcon sx={{ color: "#4782da", fontSize: "80px" }} />,
      link: "benefEntreNumeroYDeclaracion",
      description: "Trae los afiliados con el rango de numero beneficiario ",
    },
    {
      titulo: "Monotributistas aporte y grupo familiar",
      icono: <FamilyRestroomIcon sx={{ color: "#4782da", fontSize: "80px" }} />,
      link: "monotributistas",
      description:
        "Busqueda por periodo, el listado de monotributistas, para chequear lo que aporta y la cantidad de familiares que tiene ",
    },
    // {
    //   titulo: "Reduccion de beneficiarios",
    //   icono: <LegendToggleIcon sx={{ color: "#4782da", fontSize: "80px" }} />,
    //   link: "benefEntreTransYReduc",
    //   description:
    //     "Trae todos las declaraciones jurada y pagos realizados por el beneficiario y su empleador",
    // },
    {
      titulo: "Transferencia de beneficiarios",
      icono: (
        <TransferWithinAStationIcon
          sx={{ color: "#4782da", fontSize: "80px" }}
        />
      ),
      link: "transferenciaBeneficiarios",
      description:
        "Trae todos las declaraciones jurada y pagos realizados por el beneficiario y su empleador",
    },
    {
      titulo: "Movimientos de beneficiarios",
      icono: <SyncAltIcon sx={{ color: "#4782da", fontSize: "80px" }} />,
      link: "movimientos",
      description: "Permite ver toda las novedades del beneficiario",
    },
    {
      titulo: "Nomina de Extranjeros por pais, zona y empresa",
      icono: <PublicIcon sx={{ color: "#4782da", fontSize: "80px" }} />,
      link: "nominaDeExtranjeros",
      description:
        "Permite ver el detalle de la nomina de extranjeros en las empresas, junto con su total",
    },
    {
      titulo: "Personal de baja/alta por cambio de obra social",
      icono: (
        <DisplaySettingsIcon sx={{ color: "#4782da", fontSize: "80px" }} />
      ),
      link: "personalDeBajaAlta",
      description:
        "Permite ver todos los empleados dados de alta o baja por cambio de obra social entre las fechas y zonas indicadas",
    },
    {
      titulo: "Resumen de titulares y familiares por zona",
      icono: <EditNoteIcon sx={{ color: "#4782da", fontSize: "80px" }} />,
      link: "resumenDeTitulares",
      description:
        "Permite ver un resumen de los titulares y familiares entre 2 periodos, por zona",
    },
    {
      titulo: "Totales propios adherentes y monotributistas",
      icono: <ManageSearchIcon sx={{ color: "#4782da", fontSize: "80px" }} />,
      link: "totales",
      description:
        "Permite ver un resumen de los titulares y familiares entre 2 periodos, por zona",
    },
    {
      titulo: "Monotributistas sin pagos",
      icono: <MoneyOffIcon sx={{ color: "#4782da", fontSize: "80px" }} />,
      link: "monotributistasSinPagos",
      description:
        "Permite ver un resumen con los monotributistas sociales que no tengan pagos pot 3 meses seguidos o por 5 meses intercalados",
    },
    {
      titulo: "Reporte de jubilados",
      icono: <ElderlyWomanIcon sx={{ color: "#4782da", fontSize: "80px" }} />,
      link: "jubilados",
      description:
        "Permite ver un resumen con los monotributistas sociales que no tengan pagos pot 3 meses seguidos o por 5 meses intercalados",
    },
  ];

  const Cabecera = () => {
    return (
      <Grid justifyContent="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h3" gutterBottom>
            Reportes de beneficiarios
          </Typography>
          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <MuiLink component={NavLink} to="/dashboard/home">
              Home
            </MuiLink>
            <MuiLink component={NavLink} to="/dashboard/empadronamiento">
              Empadronamiento
            </MuiLink>
            <Typography>Reportes de beneficiarios</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Helmet title="Empadronamiento" />
      <Cabecera />
      <Divider my={2} />
      <Grid container spacing={3} sx={{ mt: 2 }}>
        {cards.map((element, index) => (
          <Grid item xs={12} sm={3} key={index}>
            <CardReportes
              link={element.link}
              title={element.titulo}
              icon={element.icono}
              description={element.description}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
}
