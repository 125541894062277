import { useState } from "react";
import {
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Autocomplete,
} from "@mui/material";

const rango = [
  { label: "Empresas propias del gremio", key: 1 },
  { label: "Monotributistas", key: 2 },
  { label: "Monotributistas sociales", key: 3 },
  { label: "Empleados/Empresas tras. otro gremio", key: 4 },
];

export const DialogZona = ({ open, handleOpen, setKeyZona }) => {
  return (
    <Dialog
      open={open}
      onClose={() => handleOpen()}
      fullWidth
      maxWidth="sm"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Seleccionar Rango</DialogTitle>
      <DialogContent>
        <Grid container columnGap={2} marginY={2}>
          <Grid item xs={12}>
            <Autocomplete
              id="combo-box-demo"
              options={rango}
              sx={{ width: "100%" }}
              onChange={(e, value) => {
                setKeyZona(value.key);
                handleOpen();
              }}
              renderInput={(params) => <TextField {...params} label="Rango" />}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleOpen()} color="primary">
          Cerrar
        </Button>
        {/* <Button onClick={() => handleSend()} color="primary">
          Imprimir
        </Button> */}
      </DialogActions>
    </Dialog>
  );
};
