import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export const Confirmacion = ({
  handleClose,
  show,
  nombreBenef,
  cuilBenef,
  handleNext,
}) => {
  const handleContinuar = () => {
    handleNext(cuilBenef);
  };

  return (
    <>
      <Dialog
        open={show}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Nueva solicitud de admision
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Desea abrir una nueva solicitud para {nombreBenef}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleContinuar}>Si</Button>
          <Button onClick={handleClose} autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
