import { useState, useEffect } from "react";
import {
  TextField,
  Card,
  Box,
  Autocomplete,
  RadioGroup,
  FormControlLabel,
  Radio,
  CircularProgress,
} from "@mui/material";
import { HelperText } from "../HelperText.jsx";
import { Form, FormikProvider, useFormik } from "formik";
import { useSnackbar } from "notistack";
import LoadingButton from "@mui/lab/LoadingButton";
import { DataGrid, esES } from "@mui/x-data-grid";
import hosteo from "../../../utils/hosteo.js";
import axios from "../../../utils/axios.js";
import * as XLSX from "../../../../node_modules/xlsx/xlsx.js";
import DownloadIcon from "@mui/icons-material/Download";
import CustomLabel from "../../../theme/CustomLabel.js";
import { ConsultaFamiliaresPDF } from "./pdf/ConsultaFamiliaresPDF.jsx";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import useAuth from "../../../hooks/useAuth.js";
import { BuscadorTabla } from "../../BuscadorTabla.jsx";
import { ConsultaFamiliaresZonaPDF } from "./pdf/ConsultaFamiliaresZonaPDF.jsx";

export default function ConsultaFamiliaresCard() {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [familiares, setFamiliares] = useState(null);
  const [filtrado, setFiltrado] = useState([]);
  const [selectedOption, setSelectedOption] = useState("1");
  const [pariente, setPariente] = useState("");
  const [datos, setDatos] = useState([]);
  const { user } = useAuth();

  const fetchFamiliares = async () => {
    try {
      const response = await axios.get(
        `${hosteo}/api/empadronamiento/beneficiarios/getParentesco`
      );
      setFamiliares(response.data);
    } catch (error) {
      enqueueSnackbar(error.message || "No tienes conexión a internet", {
        variant: "error",
      });
    }
  };
  useEffect(() => {
    fetchFamiliares();
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      zona: "",
      familiar: "",
    },

    onSubmit: async (values) => {
      setDatos([]);
      try {
        setIsLoading(true);
        const response = await axios.post(
          `${hosteo}/api/empadronamiento/beneficiarios/getConsultaFamiliares`,
          {
            type: selectedOption,
            zona: values.zona,
            pariente: values.familiar,
          }
        );

        const rowsWithId = response.data
          ? response.data.map((item, index) => ({
              ...item,
              id: item.index || index + 1,
            }))
          : [];
        setDatos(rowsWithId);
        setFiltrado(rowsWithId);
        setPariente(
          familiares.find((element) => element.codigo === values.familiar).label
        );
      } catch (error) {
        enqueueSnackbar(error.message || "Algo salió mal", {
          variant: "error",
        });
      } finally {
        setIsLoading(false);
      }
    },
  });
  const { handleSubmit, setFieldValue, getFieldProps, touched, errors } =
    formik;

  const generateAndDownloadExcel = () => {
    let nombre = "Consulta por familiares";

    const workbook = XLSX.utils.book_new();

    // Crea una nueva hoja de cálculo con datos
    const dia = new Date();
    const options = {
      timeZone: "America/Buenos_Aires",
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };

    // Encabezado original
    const encabezado = [
      [`Reporte de ${nombre}`],
      [
        `Generado a las ${dia
          .toLocaleTimeString("es-AR", options)
          .slice(0, 5)}hs del ${dia.toLocaleDateString()}`,
      ],
      [""],
    ];

    // Títulos de las columnas
    const columnTitles = [
      "Beneficiario",
      "Codigo",
      "CUIL",
      "CUIL Fam",
      "CUIL Titu",
      "Doc.Fam",
      "Nombre",
      "Nombre Fam",
      "Nombre Titu",
      "Razon",
    ];

    // Unir encabezado original con nuevos títulos de columna
    const datosConTitulos = [...encabezado, columnTitles];

    // Agregar datos a la hoja de cálculo
    datos.forEach((item) => {
      const rowData = [
        item.benef,
        item.codigo,
        item.cuil,
        item.cuilFam,
        item.cuilTitu,
        item.docFam,
        item.nombre,
        item.nombreFam,
        item.nombreTitu,
        item.razon,
      ];
      datosConTitulos.push(rowData);
    });

    const worksheet = XLSX.utils.aoa_to_sheet(datosConTitulos);
    XLSX.utils.book_append_sheet(workbook, worksheet, nombre);
    const colWidths = columnTitles?.map((column) => ({
      wch: column.includes("Nombre") ? 40 : 15,
    }));
    worksheet["!cols"] = colWidths;
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${nombre}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const generateAndDownloadPDF = async () => {
    const blob = await pdf(
      selectedOption === "1" ? (
        <ConsultaFamiliaresPDF
          datosPDF={datos}
          pariente={pariente}
          user={user}
        />
      ) : (
        <ConsultaFamiliaresZonaPDF
          datosPDF={datos}
          pariente={pariente}
          user={user}
        />
      )
    ).toBlob();
    saveAs(blob, `Consulta de familiares - ${new Date().toLocaleDateString()}`);
  };

  const columns = [
    {
      field: "zona",
      headerName: "Zona",
      flex: 1,
      minWidth: 120,
      editable: false,
      headerAlign: "right",
      align: "right",
    },
    {
      field: "zona_nombre",
      headerName: "Zona nombre",
      flex: 1,
      minWidth: 120,
      editable: false,
    },
    {
      field: "cantidad",
      headerName: "Cantidad",
      flex: 1,
      minWidth: 120,
      headerAlign: "right",
      align: "right",
      editable: false,
    },
  ];

  const columns2 = [
    {
      field: "benef",
      headerName: "Beneficiario",
      flex: 1,
      minWidth: 120,
      editable: false,
    },
    {
      field: "codigo",
      headerName: "Código",
      flex: 1,
      minWidth: 120,
      editable: false,
    },
    {
      field: "cuil",
      headerName: "CUIL",
      flex: 1,
      minWidth: 120,
      headerAlign: "right",
      align: "right",
      editable: false,
    },
    {
      field: "cuilFam",
      headerName: "CUIL Fam.",
      flex: 1,
      minWidth: 120,
      headerAlign: "right",
      align: "right",
      editable: false,
    },
    {
      field: "cuilTitu",
      headerName: "CUIL Titu.",
      flex: 1,
      minWidth: 120,
      headerAlign: "right",
      align: "right",
      editable: false,
    },
    {
      field: "docFam",
      headerName: "Doc. Fam.",
      flex: 1,
      minWidth: 120,
      headerAlign: "right",
      align: "right",
      editable: false,
    },
    {
      field: "nombre",
      headerName: "Nombre",
      flex: 1,
      minWidth: 250,
      headerAlign: "right",
      align: "right",
      editable: false,
    },
    {
      field: "nombreFam",
      headerName: "Nombre Fam.",
      flex: 1,
      minWidth: 250,
      headerAlign: "right",
      align: "right",
      editable: false,
    },
    {
      field: "nombreTitu",
      headerName: "Nombre Titu.",
      flex: 1,
      minWidth: 250,
      headerAlign: "right",
      align: "right",
      editable: false,
    },
    {
      field: "razon",
      headerName: "Razón",
      flex: 1,
      minWidth: 130,
      headerAlign: "right",
      align: "right",
      editable: false,
    },
  ];

  const handleKeyDown = (event, nextInputId) => {
    if (event.key === "Enter") {
      if (nextInputId === 2) {
        console.log("Listo");
      } else if (selectedOption === "1") {
        console.log("listo");
      } else {
        document.getElementById(nextInputId + 1).focus();
      }
    }
  };

  return (
    <>
      <Card>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Box sx={{ mt: 2 }}>
              <HelperText
                message={
                  "Mostrará la cantidad de tipos de familiares que existen en cada una de las zonas."
                }
              />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <RadioGroup
                row
                aria-label="radio"
                name="radio"
                value={selectedOption}
                onChange={(event, value) => {
                  setSelectedOption(value);
                }}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <FormControlLabel
                  disabled={isLoading}
                  value="1"
                  control={<Radio />}
                  label="Global"
                />
                <FormControlLabel
                  disabled={isLoading}
                  value="2"
                  control={<Radio />}
                  label="Por zona"
                />
              </RadioGroup>
            </Box>
            <Box
              sx={{
                p: 3,
                display: "flex",
                gap: 3,
                justifyContent: "center",
                "@media (max-width: 600px)": {
                  flexDirection: "column",
                  alignItems: "center",
                },
              }}
            >
              <Autocomplete
                disabled={isLoading}
                key={"fam1"}
                sx={{
                  width: "400px",
                  "@media (max-width: 600px)": {
                    width: "250px",
                  },
                }}
                options={familiares}
                getOptionLabel={(option) => option.label}
                renderOption={(props, option) => (
                  <li {...props}>{option.label}</li>
                )}
                onChange={(event, value) => {
                  if (value) {
                    setFieldValue("familiar", value.codigo);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={<CustomLabel>Familiar</CustomLabel>}
                    error={Boolean(touched.familiar && errors.familiar)}
                    helperText={touched.familiar && errors.familiar}
                  />
                )}
                id={1}
                onKeyDown={(e) => handleKeyDown(e, 1)}
              />
              {selectedOption === "2" && (
                <TextField
                  disabled={isLoading}
                  sx={{
                    width: "400px",
                    "@media (max-width: 600px)": {
                      width: "250px",
                    },
                  }}
                  key="zona1"
                  label={<CustomLabel>Zona</CustomLabel>}
                  type="number"
                  {...getFieldProps("zona")}
                  error={Boolean(touched.zona && errors.zona)}
                  helperText={touched.zona && errors.zona}
                  id={2}
                  onKeyDown={(e) => handleKeyDown(e, 2)}
                />
              )}
              <Box sx={{ height: "52px", display: "flex", gap: 3 }}>
                <LoadingButton
                  sx={{ height: "100%" }}
                  loading={isLoading}
                  variant="contained"
                  onClick={formik.handleSubmit}
                >
                  Buscar
                </LoadingButton>
              </Box>
            </Box>
          </Form>
        </FormikProvider>
        {isLoading && (
          <Box sx={{ display: "flex", justifyContent: "center", my: 10 }}>
            <CircularProgress size={75} />
          </Box>
        )}
        {datos.length > 0 && (
          <Box
            sx={{
              p: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{ display: "flex", justifyContent: "center", mb: 5, gap: 2 }}
            >
              <LoadingButton
                variant="contained"
                color="success"
                onClick={generateAndDownloadExcel}
                startIcon={<DownloadIcon />}
              >
                EXCEL
              </LoadingButton>
              <LoadingButton
                variant="contained"
                color="error"
                onClick={generateAndDownloadPDF}
                startIcon={<DownloadIcon />}
              >
                PDF
              </LoadingButton>
            </Box>

            <BuscadorTabla datos={datos} setter={setFiltrado} />
            <Box
              sx={{
                width: "70%",
                "@media(max-width: 700px)": {
                  width: "100%",
                },
              }}
            >
              <DataGrid
                sx={{ padding: 1 }}
                autoHeight
                disableRowSelectionOnClick
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                rows={filtrado || []}
                columns={selectedOption === "1" ? columns : columns2}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 25,
                    },
                  },
                }}
                pageSizeOptions={[5, 10, 25]}
              />
            </Box>
          </Box>
        )}
      </Card>
    </>
  );
}
