import * as React from "react";
import { DataGrid, esES } from "@mui/x-data-grid";
import {
  Button,
  Grid,
  TextField,
  Typography,
  Divider as MuiDivider,
  Breadcrumbs as MuiBreadcrumbs,
  Link as MuiLink,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import axios from "../../../utils/axios";
import hosteo from "../../../utils/hosteo";
import { useState } from "react";
import { useRef } from "react";
import { enqueueSnackbar } from "notistack";
import { pdf } from "@react-pdf/renderer";
import useAuth from "../../../hooks/useAuth.js";
import { saveAs } from "file-saver";
import { RecibosPDF } from "../RecibosPDF.jsx";
import {
  columns,
  columnsComparativa,
  columnsConPagos,
  columnsEmpSinDDJJ,
  columnsXLSXComparativa,
  columnsXLSXEmpConDDJJ,
  columnsXLSXEmpSinDDJJ,
  columnsXSLXConPagos,
} from "./columnasDGrid.js";
import { generateAndDowloadXLSX } from "../../../utils/downloadXSLX.js";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { HelperText } from "../HelperText.jsx";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

export default function DataGridReporte({ reporte }) {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [periodo, setPeriodo] = useState();
  const fileInputRefs = useRef(null);
  const { user } = useAuth();
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [columnXSLX, setColumnXSLX] = useState(null);
  const [column, setColumn] = useState(null);
  const [helperText, setHelperText] = useState("");

  // console.log(data);
  const handlePaginationChange = (params) => {
    if (params.pageSize !== pageSize) {
      setPageSize(params.pageSize);
      setCurrentPage(0);
    } else {
      setCurrentPage(params.page);
    }
  };
  const apiRest = (reporte) => {
    switch (reporte) {
      case "Empresas que no presentaron declaración jurada":
        setColumnXSLX(columnsXLSXEmpSinDDJJ);
        setColumn(columnsEmpSinDDJJ);
        setHelperText(
          "Permite ver el total de empresas que no presentaron una delcaracion jurada en el periodo indicado"
        );
        return `${hosteo}/api/empadronamiento/empresas/getSinDDJJ`;
      case "Empresas que presentaron declaración jurada":
        setColumnXSLX(columnsXLSXEmpConDDJJ);
        setColumn(columns);
        setHelperText("Empresas que presentaron declaración jurada");
        return `${hosteo}/api/empadronamiento/empresas/getConDDJJ`;
      case "Empresas que presentaron pagos":
        setColumnXSLX(columnsXSLXConPagos);
        setColumn(columnsConPagos);
        setHelperText("Empresas que presentaron pagos");
        return `${hosteo}/api/empadronamiento/empresas/getPagos`;
      case "Comparativa entre declarado y pagado":
        setColumnXSLX(columnsXLSXComparativa);
        setColumn(columnsComparativa);
        setHelperText("Comparativa entre declarado y pagado");
        return `${hosteo}/api/empadronamiento/empresas/getComparativa`;
      default:
        // Declaraciones ejecutadas cuando ninguno de los valores coincide con el valor de la expresión
        break;
    }
  };

  const getReportesConDDJJ = async () => {
    try {
      setLoading(true);
      const response = await axios.post(apiRest(reporte), {
        periodo: periodo,
      });
      setLoading(false);
      setData(response);
      setCurrentPage(0);
    } catch (err) {
      setLoading(false);
      enqueueSnackbar(err.message || "En desarrollo", {
        variant: "error",
      });
    } finally {
      setPeriodo("");
    }
  };

  const generateAndDownloadPDF = async () => {
    const datosPDF = data.data.map((element) => ({
      ...element,
      // banco: data.data.find((banco) => banco.codigo === element.banco)?.nombre,
    }));
    const blob = await pdf(
      <RecibosPDF
        datosPDF={datosPDF}
        user={user}
        reporte={reporte}
        periodo={periodo}
      />
    ).toBlob();
    saveAs(blob, `${reporte} - ${new Date().toLocaleDateString()}`);
  };

  const handleInputChange = (event) => {
    setPeriodo(event.target.value);
  };

  const helperTextInfo = (reporte) => {
    switch (reporte) {
      case "Empresas que no presentaron declaración jurada":
        return "Permite ver el total de empresas que no presentaron una declaracion jurada en el periodo indicado";
      case "Empresas que presentaron declaración jurada":
        return "Permite ver las empresas que realizaron declaraciones juradas en el periodo que se indique";
      case "Empresas que presentaron pagos":
        return "Permite ver el total de empresas que presentaron pagos en el periodo indicado";
      case "Comparativa entre declarado y pagado":
        return "Permite ver la comparativa en el periodo que se indique, la comparativa de declarado y pagado";
    }
  };

  const Cabecera = () => {
    return (
      <>
        <Grid justifyContent="space-between" container spacing={6}>
          <Grid item>
            <Typography variant="h3" gutterBottom>
              {reporte}
            </Typography>
            <Breadcrumbs aria-label="Breadcrumb" mt={2}>
              <MuiLink component={NavLink} to="/dashboard/home">
                Home
              </MuiLink>
              <MuiLink component={NavLink} to="/dashboard/empadronamiento">
                Empadronamiento
              </MuiLink>
              <MuiLink
                component={NavLink}
                to="/dashboard/empadronamiento/empresas/reportes"
              >
                Reportes de empresas
              </MuiLink>
              <Typography>{reporte}</Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <>
      <Helmet title="Empadronamiento" />
      <Cabecera />
      <Divider my={2} />

      <HelperText message={helperTextInfo(reporte)} />

      <Grid
        container
        columnGap={2}
        marginY={3}
        sx={{ justifyContent: "center", display: "flex" }}
      >
        <TextField
          label="Ingrese periodo"
          inputRef={fileInputRefs}
          value={periodo}
          onChange={handleInputChange}
          autoFocus
        />
        <LoadingButton
          variant="contained"
          loading={loading}
          onClick={getReportesConDDJJ}
        >
          Buscar
        </LoadingButton>
        <Button
          variant="contained"
          color="error"
          disabled={data ? false : true}
          onClick={generateAndDownloadPDF}
        >
          PDF
        </Button>
        <Button
          variant="contained"
          color="success"
          onClick={() => generateAndDowloadXLSX({ data, columnXSLX, reporte })}
          disabled={data ? false : true}
        >
          EXCEL
        </Button>
      </Grid>
      <Grid container justifyContent={"center"}>
        {data?.data ? (
          <Grid md={10} sx={{ width: "0px" }}>
            <DataGrid
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}
              autoHeight
              rows={data?.data}
              columns={column}
              disableRowSelectionOnClick
              onPaginationModelChange={(row) => handlePaginationChange(row)}
              onPageChange={(newPage) => setCurrentPage(newPage)}
              paginationModel={{
                page: currentPage,
                pageSize: pageSize,
              }}
              initialState={{
                pagination: { paginationModel: { page: 0, pageSize: 5 } },
              }}
              pageSizeOptions={[5, 10, 15, 25, 100]}
            />
          </Grid>
        ) : (
          <Grid>
            <Typography variant="h4">Ingrese un periodo</Typography>
          </Grid>
        )}
      </Grid>
    </>
  );
}
