import React from "react";
import styled from "@emotion/styled";
import { Sun, Moon } from "react-feather";
import { IconButton as MuiIconButton } from "@mui/material";
import { THEMES } from "../../constants";
import useTheme from "../../hooks/useTheme";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const NavBarDarkMode = () => {
  const { theme, setTheme } = useTheme();

  return (
    <IconButton
      aria-haspopup="true"
      onClick={() => setTheme(theme === "DARK" ? THEMES.BLUE : THEMES.DARK)}
      color="inherit"
      size="large"
    >
      {theme === "DARK" ? <Sun /> : <Moon />}
    </IconButton>
  );
};
export default NavBarDarkMode;
