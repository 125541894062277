import { useState } from "react";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { ChevronRight } from "react-feather";
import { DataGrid, esES } from "@mui/x-data-grid";

export const BuscarAfiliado = ({
  handleClose,
  show,
  formatterDni,
  datos,
  handleBuscar,
}) => {
  // -------------Columnas datagrid-------------------------

  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);

  const columns = [
    {
      field: "Beneficiario",
      headerName: "Beneficiario",
      editable: false,
      flex: 0.5,
    },
    {
      field: "Parentezco",
      headerName: "Parentezco",
      editable: false,
      flex: 0.5,
    },
    {
      field: "Documento",
      headerName: "Documento",
      editable: false,
      flex: 0.5,
      valueFormatter: (params) => formatterDni(params.value),
    },
    {
      field: "Nombre",
      headerName: "Nombre",
      editable: false,
      flex: 0.5,
      align: "left",
    },
    {
      field: "action",
      headerName: "",
      editable: false,
      flex: 0.3,
      align: "right",
      renderCell: (params) => (
        <IconButton aria-label="more" aria-controls="menu" aria-haspopup="true">
          <ChevronRight />
        </IconButton>
      ),
    },
  ];
  // ---------------------------------------------------------------------

  const rowsWithId =
    datos.length > 0
      ? datos.map((item, index) => ({
          ...item,
          id: index + 1,
        }))
      : [];

  const handlePaginationChange = (params) => {
    if (params.pageSize !== pageSize) {
      setPageSize(params.pageSize);
      setCurrentPage(0);
    } else {
      setCurrentPage(params.page);
    }
  };

  return (
    <>
      <Dialog
        open={show}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Buscando afiliados</DialogTitle>
        <DialogContent>
          <DataGrid
            autoHeight
            onPaginationModelChange={(row) => handlePaginationChange(row)}
            onPageChange={(newPage) => setCurrentPage(newPage)}
            paginationModel={{
              page: currentPage,
              pageSize: pageSize,
            }}
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            rows={rowsWithId}
            getRowId={(row) => row.id}
            columns={columns}
            initialState={{
              pagination: { paginationModel: { page: 0, pageSize: 5 } },
            }}
            onRowClick={(row) => {
              handleBuscar(row.row);
            }}
            pageSizeOptions={[5, 10, 15, 25, 100]}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
