import { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { NavLink } from "react-router-dom";
import {
  Card,
  Breadcrumbs,
  Link,
  Typography,
  styled,
  Table,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Divider as MuiDivider,
  IconButton,
  Grid,
  Menu,
  MenuItem,
  TextField,
  Button,
  Box,
  Dialog,
  DialogContent,
} from "@mui/material";
import { spacing } from "@mui/system";
import { enqueueSnackbar } from "notistack";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import axios from "../../utils/axios";
import hosteo from "../../utils/hosteo";
import CustomLabel from "../../theme/CustomLabel";
import CreateUserForm from "../../components/Administracion/crearUsuario/FormularioUsuario";

const Divider = styled(MuiDivider)(spacing);

const Cabecera = () => {
  return (
    <>
      <Helmet title="Administracion" />
      <Grid justifyContent="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h3" gutterBottom>
            Ver Usuarios
          </Typography>
          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/dashboard/home">
              Home
            </Link>
            <Link component={NavLink} to="/dashboard/administracion">
              Administracion
            </Link>
            <Typography>Ver usuarios</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>
    </>
  );
};

const VerUsuarios = () => {
  const [usuarios, setUsuarios] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogMenu, setOpenDialogMenu] = useState(false);
  const [DialogMenu, setDialogMenu] = useState("");
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [deleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] =
    useState(false);

  const getUsuarios = async () => {
    try {
      const response = await axios.post(
        `${hosteo}/api/administracion/getUsuarios`
      );
      setUsuarios(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getUsuarios();
  }, []);

  const handleMenuClick = (event, userID) => {
    setAnchorEl(event.currentTarget);
    setSelectedUserId(userID); // Set the selected user ID
  };

  const handleOpenMenuDialog = (set) => {
    setOpenDialogMenu(true);
    setDialogMenu(set);
  };

  const handleCloseDialogMenu = () => {
    setOpenDialogMenu(false);
    setDialogMenu("");
  };

  const handleMenuClose = async (menuItem, userId) => {
    setAnchorEl(null);

    if (menuItem === "blanquear") {
      try {
        const response = await axios.post(
          `${hosteo}/api/administracion/blanquearPass`,
          {
            userID: userId,
          }
        );
        enqueueSnackbar(response.data.message, { variant: "success" });
        handleOpenMenuDialog("blanquear");
        // getUsuarios();
        return;
      } catch (error) {
        enqueueSnackbar(error.message || "No tienes conexión a internet", {
          variant: "error",
        });
        return;
      }
    }

    if (menuItem === "desbloquear") {
      try {
        const response = await axios.post(
          `${hosteo}/api/administracion/desbloquearUsuario`,
          {
            userID: userId,
          }
        );
        enqueueSnackbar(response.data.message, { variant: "success" });
        handleOpenMenuDialog("desbloquear");
        getUsuarios();
        return;
      } catch (error) {
        enqueueSnackbar(error.message || "No tienes conexión a internet", {
          variant: "error",
        });
        return;
      }
    }

    if (menuItem === "bloquear") {
      try {
        const response = await axios.post(
          `${hosteo}/api/administracion/bloquearUsuario`,
          {
            userID: userId,
          }
        );
        enqueueSnackbar(response.data.message, { variant: "success" });
        handleOpenMenuDialog("bloquear");
        getUsuarios();
        return;
      } catch (error) {
        enqueueSnackbar(error.message || "No tienes conexión a internet", {
          variant: "error",
        });
        return;
      }
    }

    if (menuItem === "eliminar") {
      setDeleteConfirmationDialogOpen(true);
      // try {
      //   const response = await axios.post(
      //     `${hosteo}/api/administracion/eliminarUsuario`,
      //     {
      //       userID: userId,
      //     }
      //   );
      //   enqueueSnackbar(response.data.message, { variant: "success" });
      //   handleOpenMenuDialog("eliminar");
      //   getUsuarios();
      //   return;
      // } catch (error) {
      //   enqueueSnackbar(error.message || "No tienes conexión a internet", {
      //     variant: "error",
      //   });
      //   return;
      // }
    }
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await axios.post(
        `${hosteo}/api/administracion/eliminarUsuario`,
        {
          userID: selectedUserId,
        }
      );
      enqueueSnackbar(response.data.message, { variant: "success" });
      handleOpenMenuDialog("eliminar");
      getUsuarios();
    } catch (error) {
      enqueueSnackbar(error.message || "No tienes conexión a internet", {
        variant: "error",
      });
    } finally {
      setDeleteConfirmationDialogOpen(false);
    }
  };

  const handleCancelDelete = () => {
    // Close the confirmation dialog without executing the delete action
    setDeleteConfirmationDialogOpen(false);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    getUsuarios();
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  // Filter the usuarios array based on the search query
  const filteredUsuarios = usuarios.filter((usuario) =>
    Object.values(usuario).some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const TABLE_HEAD = [
    { id: "usuario", label: "Usuario", alignRight: false },
    { id: "nombre", label: "Nombre", alignRight: false },
    { id: "apelido", label: "Apelido", alignRight: false },
    { id: "posicion", label: "Posicion", alignRight: false },
    { id: "faltas", label: "Faltas", alignRight: false },
    { id: "moreMenu", label: "", alignRight: false },
  ];

  return (
    <>
      <Cabecera />
      <Divider my={3} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <TextField
          label={[<CustomLabel>Buscar</CustomLabel>]}
          variant="outlined"
          value={searchQuery}
          onChange={handleSearch}
          margin="normal"
        />

        <Button
          variant="contained"
          color="primary"
          sx={{ marginLeft: "10px" }}
          onClick={handleOpenDialog}
        >
          Crear Usuario
        </Button>
      </Box>
      <Card>
        <TableContainer sx={{ maxHeight: 500 }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {TABLE_HEAD.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.alignRight ? "right" : "left"}
                  >
                    <CustomLabel>{headCell.label}</CustomLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredUsuarios.map((row) => {
                const { usuario, nombre, apellido, posicion, faltas, id } = row;
                //console.log(faltas);
                return (
                  <TableRow hover key={id} tabIndex={-1}>
                    <TableCell align="left">{usuario}</TableCell>
                    <TableCell align="left">{nombre}</TableCell>
                    <TableCell align="left">{apellido}</TableCell>
                    <TableCell align="left">{posicion}</TableCell>
                    <TableCell
                      align="left"
                      style={{
                        color: faltas === "Bloqueado" ? "red" : "",
                      }}
                    >
                      {faltas}
                    </TableCell>
                    <TableCell align="left">
                      <IconButton
                        onClick={(event) => handleMenuClick(event, id)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        id={id}
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl && selectedUserId === id)}
                        onClose={handleMenuClose}
                      >
                        <MenuItem
                          onClick={() => handleMenuClose("blanquear", id)}
                        >
                          Blanquear Contraseña
                        </MenuItem>
                        <MenuItem
                          onClick={() => handleMenuClose("eliminar", id)}
                        >
                          Eliminar
                        </MenuItem>
                        {faltas !== "Activo" && (
                          <MenuItem
                            onClick={() => handleMenuClose("desbloquear", id)}
                          >
                            Desbloquear
                          </MenuItem>
                        )}
                        {faltas !== "Bloqueado" && (
                          <MenuItem
                            onClick={() => handleMenuClose("bloquear", id)}
                          >
                            Bloquear
                          </MenuItem>
                        )}
                      </Menu>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      <Dialog
        open={deleteConfirmationDialogOpen}
        onClose={handleCancelDelete}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <Typography variant="h3" gutterBottom>
            ¿Está seguro de que desea eliminar este usuario?
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleCancelDelete}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleConfirmDelete}
            >
              Confirmar
            </Button>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="sm"
      >
        <CreateUserForm onCloseDialog={handleCloseDialog} />
      </Dialog>

      <Dialog
        open={openDialogMenu}
        onClose={handleCloseDialogMenu}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          {DialogMenu === "blanquear" ? (
            <>
              <Typography variant="h3" gutterBottom>
                Contraseña reestablecida con exito
              </Typography>
              <Typography variant="h3" gutterBottom>
                Envie la siguiente informacion al usuario:
              </Typography>
              <Typography variant="h5" gutterBottom>
                Contraseña: 1234
              </Typography>
              <Typography variant="h5" gutterBottom>
                Al ingresar debe cambiar la contraseña
              </Typography>
            </>
          ) : (
            <></>
          )}

          {DialogMenu === "desbloquear" ? (
            <>
              <Typography variant="h3" gutterBottom>
                El usuario se desbloqueo con exito
              </Typography>
              <Typography variant="h3" gutterBottom>
                La contraseña se reestablecio, Envie la siguiente informacion al
                usuario:
              </Typography>
              <Typography variant="h5" gutterBottom>
                Contraseña: 1234
              </Typography>
              <Typography variant="h5" gutterBottom>
                Al ingresar debe cambiar la contraseña
              </Typography>
            </>
          ) : (
            <></>
          )}

          {DialogMenu === "bloquear" ? (
            <>
              <Typography variant="h3" gutterBottom>
                El usuario se bloqueo con exito
              </Typography>
              <Typography variant="h5" gutterBottom>
                El usuario no va a poder ingrear al aplicativo hasta que se
                desbloquee
              </Typography>
            </>
          ) : (
            <></>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default VerUsuarios;
