import { createSlice } from "@reduxjs/toolkit";
// ----------------------------------------------------------------------

const initialState = {
  beneficiario: {
    Beneficiario: 0,
    Parentezco: 0,
    Documento: 0,
    Nombre: "",
    Cuil: 0,
    Fnacim: null,
    Fecha_hasta_Discap: null,
    Observacion_discap: null,
  },
  fechaAdmision: null,
  urgencia: false,
  observacion: "",
  lista: [],
};

const slice = createSlice({
  name: "ingreso",
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },
    // GET GENERAL
    setNumeroBenef(state, action) {
      state.beneficiario.Beneficiario = action.payload;
    },
    setNumeroBenefParentezco(state, action) {
      state.beneficiario.Parentezco = action.payload;
    },
    setDocumento(state, action) {
      state.beneficiario.Documento = action.payload;
    },
    setNombre(state, action) {
      state.beneficiario.Nombre = action.payload;
    },
    setCuil(state, action) {
      state.beneficiario.Cuil = action.payload;
    },
    setFechaNacimiento(state, action) {
      state.beneficiario.Fnacim = action.payload;
    },
    setFechaDiscap(state, action) {
      state.beneficiario.Fecha_hasta_Discap = action.payload;
    },
    setDiscap(state, action) {
      state.beneficiario.Observacion_discap = action.payload;
    },
    setUrgencia(state, action) {
      state.urgencia = action.payload;
    },
    setFechaAdmision(state, action) {
      state.fechaAdmision = action.payload;
    },
    setObs(state, action) {
      state.observacion = action.payload;
    },
    setLista(state, action) {
      state.lista.push(action.payload);
    },
    deleteItemLista(state, action) {
      state.lista = state.lista.filter(
        (item, index) => index !== action.payload
      );
    },
    deleteSolicitud(state) {
      state.beneficiario = {
        Beneficiario: 0,
        Parentezco: 0,
        Documento: 0,
        Nombre: "",
        Cuil: 0,
        Fnacim: null,
        Fecha_hasta_Discap: null,
        Observacion_discap: null,
      };
      state.fechaAdmision = null;
      state.urgencia = false;
      state.observacion = "";
      state.lista = [];
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function abrirSolicitud(beneficiario) {
  return async (dispatch) => {
    dispatch(slice.actions.setNumeroBenef(beneficiario.Beneficiario));
    dispatch(slice.actions.setNumeroBenefParentezco(beneficiario.Parentezco));
    dispatch(slice.actions.setDocumento(beneficiario.Documento));
    dispatch(slice.actions.setNombre(beneficiario.Nombre));
    dispatch(slice.actions.setCuil(beneficiario.Cuil));
    dispatch(slice.actions.setFechaNacimiento(beneficiario.Fnacim));
    dispatch(slice.actions.setFechaDiscap(beneficiario.Fecha_hasta_Discap));
    dispatch(slice.actions.setDiscap(beneficiario.Observacion_discap));
    dispatch(slice.actions.setFechaAdmision(new Date()));
  };
}

export function guardarLista(elementos) {
  return async (dispatch) => {
    dispatch(slice.actions.setLista(elementos));
  };
}
export function getLista(state) {
  return state.lista;
}

export function guardarObs(Obs) {
  return async (dispatch) => {
    dispatch(slice.actions.setObs(Obs));
  };
}

export function guardarUrgencia(urgencia) {
  return async (dispatch) => {
    dispatch(slice.actions.setUrgencia(urgencia));
  };
}
export function guardarFechaIngreso(fecha) {
  return async (dispatch) => {
    dispatch(slice.actions.setFechaAdmision(fecha));
  };
}
export function eliminarItemLista(index) {
  return async (dispatch) => {
    dispatch(slice.actions.deleteItemLista(index));
  };
}

export function clearSolicitud() {
  return async (dispatch) => {
    dispatch(slice.actions.deleteSolicitud());
  };
}
